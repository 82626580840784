import { ExpandableText } from '@/components/ui/ExpandableText';
import { CenteredSpinner } from '@/components/ui/spinner';
import {
    EVENT_BANNER_PLACEHOLDER_SRC,
    ORGANIZATION_BANNER_ASPECT_RATIO,
    ORGANIZATION_BANNER_PLACEHOLDER_BLURHASH,
    ORGANIZATION_BANNER_PLACEHOLDER_SRC,
    ORGANIZATION_PICTURE_ASPECT_RATIO,
    ORGANIZATION_PICTURE_PLACEHOLDER_BLURHASH,
    ORGANIZATION_PICTURE_PLACEHOLDER_SRC,
    getEventBannerSrc,
    getOrganizationBannerSrc,
    getOrganizationPictureSrc,
} from '@/lib/utils';
import { useLocation, useParams } from 'react-router-dom';
import { PreviewImage } from '@/components/PreviewImage';
import { MapPinIcon } from 'lucide-react';
import { useEffect } from 'react';
import { useMetaTags } from '@/hooks/metaDataHooks';
import {
    useFollowOrganizationMutation,
    useGetOrganizationByIdQuery,
    useUnfollowOrganizationMutation,
} from '@/services/organizationApi';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/apiHooks';

export const Organization = (): JSX.Element => {
    const location = useLocation();
    const { isAuthenticated } = useAuth();
    const { organizationId } = useParams<{ organizationId: string }>();
    const { data, isLoading } = useGetOrganizationByIdQuery(organizationId ?? '');
    const [follow] = useFollowOrganizationMutation();
    const [unfollow] = useUnfollowOrganizationMutation();
    useMetaTags({
        title: data?.name,
        description: data?.description,
        image: data?.bannerBlurhash ? getEventBannerSrc(data.id) : EVENT_BANNER_PLACEHOLDER_SRC,
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const ref = queryParams.get('ref');
        if (ref) {
            localStorage.setItem('eventReferral', ref);
        }
    }, [location]);

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!data) {
        return <div>Error fetching event</div>;
    }

    return (
        <div className="w-full md:w-3/5 lg:w-1/2 flex flex-col space-y-4 justify-center p-4 space md:py-4 mx-auto">
            <PreviewImage
                url={data.bannerBlurhash ? getOrganizationBannerSrc(data.id) : ORGANIZATION_BANNER_PLACEHOLDER_SRC}
                blurHash={data.bannerBlurhash ?? ORGANIZATION_BANNER_PLACEHOLDER_BLURHASH}
                className="w-full rounded-lg"
                aspectRatio={ORGANIZATION_BANNER_ASPECT_RATIO}
            />
            <div className="flex flex-row gap-4 items-start md:items-center relative">
                <PreviewImage
                    url={
                        data.pictureBlurhash ? getOrganizationPictureSrc(data.id) : ORGANIZATION_PICTURE_PLACEHOLDER_SRC
                    }
                    blurHash={data.bannerBlurhash ?? ORGANIZATION_PICTURE_PLACEHOLDER_BLURHASH}
                    className="h-24 w-24 rounded-lg"
                    aspectRatio={ORGANIZATION_PICTURE_ASPECT_RATIO}
                />
                <div>
                    <h1 className="text-xl md:text-3xl">{data.name}</h1>
                    <p className="flex gap-1 items-center">
                        <MapPinIcon className="h-5 w-5" />
                        {data.address}, {data.zipCode} {data.city}
                    </p>
                </div>
            </div>
            {isAuthenticated ? (
                data.isFollowing ? (
                    <Button onClick={() => unfollow(data.id)}>Unfollow</Button>
                ) : (
                    <Button onClick={() => follow(data.id)}>Follow</Button>
                )
            ) : null}
            <div className="flex justify-between items-start">
                <ExpandableText text={data.description} lines={5} />
            </div>
        </div>
    );
};
