import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useGetCartByUserIdQuery, useRemoveTicketFromCartMutation } from '@/services/cartApi';
import { useEffect, useMemo, useState } from 'react';
import type { Ticket } from '@/types/Ticket';
import { errorToast, errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { TrashIcon } from 'lucide-react';
import { formatPrice } from '@/lib/utils';
import { loadStripe, type StripeElementsOptions } from '@stripe/stripe-js';
import { useTheme } from '../ThemeProvider';
import { useCreateAdminPaymentIntentMutation } from '@/services/paymentApi';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

interface AdminSeatMapCheckoutDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onSuccess: () => void;
    userId: string;
}

type RemovingRecord = Record<string, boolean>;

export const AdminSeatMapCheckoutDialog = ({
    onOpenChange,
    open,
    onSuccess,
    userId,
}: AdminSeatMapCheckoutDialogProps): JSX.Element => {
    const [fetchCart] = useGetCartByUserIdQuery();
    const [removeItem] = useRemoveTicketFromCartMutation();
    const [data, setData] = useState<Ticket[]>();
    const [showCheckout, setShowCheckout] = useState(false);
    const [createPaymentIntent, response] = useCreateAdminPaymentIntentMutation();

    useEffect(() => {
        if (userId) {
            fetchCart(userId)
                .unwrap()
                .then((cart) => {
                    setData(cart);
                })
                .catch(errorToastFromCatch);
        }
    }, [open, userId]);

    const [isRemoving, setIsRemoving] = useState<RemovingRecord>(
        data?.reduce((obj: RemovingRecord, ticket) => {
            obj[ticket.id] = false;
            return obj;
        }, {}) ?? {},
    );

    const total = useMemo(() => {
        return data?.reduce((acc, item) => acc + item.eventItem.price, 0) ?? 0;
    }, [data]);

    const deleteTicket = async (id: string) =>
        removeItem(id)
            .unwrap()
            .then(() => {
                if (userId) {
                    return fetchCart(userId).unwrap();
                }
                return undefined;
            })
            .then((cart) => {
                setData(cart);
            })
            .catch(errorToastFromCatch);

    function handleRemoveItem(id: string) {
        setIsRemoving((prev) => ({ ...prev, [id]: true }));
        deleteTicket(id)
            .then(() => {
                setIsRemoving((prev) => ({ ...prev, [id]: false }));
            })
            .catch(errorToastFromCatch);
    }

    function handleCheckout() {
        if (data && userId) {
            if (total > 0) {
                setShowCheckout(true);
                return;
            }
            createPaymentIntent({
                currency: 'sek',
                data: data.map((ticket) => ({
                    foodPreferences: [],
                    ticketId: ticket.id,
                })),
                stripeVersion: '2023-10-16',
                userId: userId,
            })
                .unwrap()
                .then(() => {
                    onSuccess();
                    successToast('Payment successful');
                })
                .catch(errorToastFromCatch);
        } else {
            errorToast('Please add tickets to cart before checking out');
        }
    }

    return (
        <Dialog onOpenChange={onOpenChange} open={open}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Checkout</DialogTitle>
                </DialogHeader>
                {showCheckout && total > 0 ? (
                    <CheckoutElements tickets={data ?? []} userId={userId} total={total} onSuccess={onSuccess} />
                ) : (
                    <>
                        <CartContent tickets={data ?? []} isRemoving={isRemoving} handleRemoveItem={handleRemoveItem} />
                        <div className="flex flex-col space-y-2">
                            <p className="text-xl text-right">{formatPrice(total)} SEK</p>
                        </div>
                    </>
                )}

                <DialogFooter>
                    {showCheckout && total > 0 ? (
                        <Button onClick={() => setShowCheckout(false)}>Cancel</Button>
                    ) : (
                        <Button onClick={handleCheckout} disabled={!data?.length} isLoading={response.isLoading}>
                            Checkout
                        </Button>
                    )}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

interface CartContentProps {
    tickets: Ticket[];
    isRemoving: RemovingRecord;
    handleRemoveItem: (id: string) => void;
}

const CartContent = ({ tickets, isRemoving, handleRemoveItem }: CartContentProps): JSX.Element => {
    return (
        <>
            {tickets.map((item) => (
                <div className="flex w-full space-x-2 mb-2 items-center" key={item.id}>
                    <div className="flex justify-between flex-1 items-center">
                        <div>
                            <p className="font-semibold">{item.event.title}</p>
                            <p>{item.eventItem.name}</p>
                        </div>
                        <p>{formatPrice(item.eventItem.price)} SEK</p>
                    </div>
                    <Button
                        size="icon"
                        variant="destructive"
                        isLoading={isRemoving[item.id]}
                        onClick={() => handleRemoveItem(item.id)}
                    >
                        <TrashIcon className="h-5 w-5" />
                    </Button>
                </div>
            ))}
        </>
    );
};

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY as string);

interface StripeCheckoutProps {
    tickets: Ticket[];
    userId: string;
    total: number;
    onSuccess: () => void;
}

const CheckoutElements = ({ tickets, userId, total, onSuccess }: StripeCheckoutProps): JSX.Element => {
    const { theme } = useTheme();
    const options: StripeElementsOptions = {
        mode: 'payment',
        amount: total,
        currency: 'sek',
        paymentMethodTypes: ['card', 'swish'],
        paymentMethodCreation: 'manual',
        appearance: {
            theme: theme === 'dark' ? 'night' : 'stripe',
            variables: {
                colorPrimary: '#000000',
            },
        },
    };
    return (
        <Elements stripe={stripePromise} options={options}>
            <StripeCheckout tickets={tickets} userId={userId} total={total} onSuccess={onSuccess} />
        </Elements>
    );
};

const StripeCheckout = ({ tickets, userId, total, onSuccess }: StripeCheckoutProps): JSX.Element => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [createPaymentIntent, response] = useCreateAdminPaymentIntentMutation();

    async function handleSubmit() {
        setIsLoading(true);
        if (!stripe || !elements) {
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError?.message) {
            errorToast(submitError.message);
            return;
        }

        const { error: confirmationError, confirmationToken } = await stripe.createConfirmationToken({
            elements,
            params: {
                return_url: window.location.href,
            },
        });

        if (confirmationError?.message) {
            errorToast(confirmationError.message);
            return;
        }

        if (!confirmationToken) {
            errorToast('Failed, please try again');
            return;
        }

        createPaymentIntent({
            confirmationTokenId: confirmationToken.id,
            currency: 'sek',
            data: tickets.map((ticket) => ({
                foodPreferences: [],
                ticketId: ticket.id,
            })),
            stripeVersion: '2023-10-16',
            userId: userId,
        })
            .unwrap()
            .then(() => {
                onSuccess();
                successToast('Payment successful');
            })
            .catch(errorToastFromCatch);
        setIsLoading(false);
    }

    return (
        <div className="space-y-4">
            <PaymentElement className="outline-none ring-0 focus-visible:outline-none" />
            <Button
                className="w-full"
                size="lg"
                onClick={handleSubmit}
                disabled={!stripe || !elements}
                isLoading={isLoading || response.isLoading}
            >
                Pay {formatPrice(total)} SEK
            </Button>
        </div>
    );
};
