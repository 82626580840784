import SeatMap from '@/components/Seats/SeatMap';
import { useEffect, useState, useRef, type LegacyRef } from 'react';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { errorToast, errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useGetEventByIdQuery, useGetEventSeatMapByIdQuery } from '@/services/eventsApi';
import { CenteredSpinner } from '@/components/ui/spinner';
import type { SeatMap as SeatMapType, SeatMapSeat } from '@/types/SeatMap';
import { formatPrice } from '@/lib/utils';
import { useAddTicketsToCartMutation } from '@/services/cartApi';
import { useAuth } from '@/hooks/apiHooks';
import { ArrowLeftIcon, TrashIcon } from 'lucide-react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form } from '@/components/ui/form';
import { FormSelect } from '@/components/ui/FormHelpers';
import { BasicTooltip } from '@/components/ui/tooltip';
import { Input } from '@/components/ui/input';
import type { SeatMapFormValues } from '@/types/Cart';
import { Separator } from '@/components/ui/separator';

export default function SeatMapRoute(): JSX.Element {
    const { user } = useAuth();
    const { eventId } = useParams<{ eventId: string }>();
    const navigate = useNavigate();
    const [fetchSeatMap, fetchResponse] = useGetEventSeatMapByIdQuery();
    const [seatMap, setSeatMap] = useState<SeatMapType>();
    const { data: event, isLoading: isEventLoading } = useGetEventByIdQuery(eventId ?? '');
    const [eventItems, setEventItems] = useState<Record<string, string>>();
    const [addToCart, response] = useAddTicketsToCartMutation();
    const bottomRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (eventId) {
            fetchSeatMap(eventId)
                .unwrap()
                .then((data) => {
                    setSeatMap(data);
                })
                .catch(errorToastFromCatch);
        }
    }, [eventId, fetchSeatMap]);

    const form = useForm<SeatMapFormValues>();
    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'seats',
    });

    const toggleSelection = (seat: SeatMapSeat) => {
        if (seat.status === 'AVAILABLE' && !fields.some((field) => field.seat.seatId === seat.seatId)) {
            append({ seat, eventItemId: undefined, price: 0 });
        } else {
            remove(fields.findIndex((field) => field.seat.seatId === seat.seatId));
        }
    };

    const handleAddToCart = (data: SeatMapFormValues) => {
        if (!user) {
            errorToast('You need to be signed in to add tickets to cart');
            return;
        }

        const formattedData = data.seats.map((seat) => {
            return {
                eventItemId: seat.eventItemId ?? '',
                seatId: seat.seat.seatId,
                actualPrice: seat.price * 100,
            };
        });

        addToCart({
            userId: user.id,
            cartItems: formattedData,
        })
            .unwrap()
            .then(() => {
                successToast('Tickets added to cart');
                navigate('/cart');
                form.reset({
                    seats: [],
                });
            })
            .catch(errorToastFromCatch);
    };

    if (isEventLoading) {
        return <CenteredSpinner />;
    }

    if (!event) {
        return <div>Error fetching seat map</div>;
    }

    return (
        <div className="h-full p-4">
            <div className="w-full flex justify-between">
                <Button variant="ghost" onClick={() => navigate(`/events/${eventId}`)} className="gap-2">
                    <ArrowLeftIcon className="h-5 w-5" /> <p>Back to event</p>
                </Button>
            </div>
            <div className="flex flex-col lg:flex-row gap-4 h-[calc(100%-24px)] pt-4">
                {fetchResponse.isLoading ? (
                    <CenteredSpinner />
                ) : (
                    seatMap && (
                        <SeatMap
                            seatMap={seatMap}
                            selectedSeats={fields.map((f) => f.seat)}
                            toggleSelection={toggleSelection}
                        />
                    )
                )}

                <Card className="h-fit lg:min-w-[600px]">
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handleAddToCart)}>
                            <CardHeader className="flex flex-row items-center justify-between">
                                <h2 className="text-xl">Order Summary</h2>
                                <Button
                                    size="icon"
                                    variant="destructive"
                                    type="reset"
                                    onClick={() =>
                                        form.reset({
                                            seats: [],
                                        })
                                    }
                                    disabled={fields.length === 0 || response.isLoading}
                                >
                                    <TrashIcon className="h-5 w-5" />
                                </Button>
                            </CardHeader>
                            <CardContent>
                                {fields.length === 0 ? (
                                    <p className="text-md py-4">No seat selected</p>
                                ) : (
                                    <div>
                                        {fields.map((field, index) => {
                                            const selectedItem = event.eventItems.find(
                                                (eventItem) => eventItem.id === eventItems?.[field.id],
                                            );
                                            const hasMinPrice = selectedItem?.hasMinimumPrice;
                                            return (
                                                <div key={field.id} className="flex flex-col gap-2 my-2">
                                                    <p className="font-bold text-lg">{field.seat.category.name}</p>
                                                    <p>
                                                        Row {field.seat.row} - Seat {field.seat.seatNumber}
                                                    </p>
                                                    <div className="flex flex-row justify-between">
                                                        <FormSelect
                                                            className="w-36"
                                                            control={form.control}
                                                            placeholder="Select type"
                                                            id={`seats.${index}.eventItemId` as const}
                                                            onChange={(value) => {
                                                                setEventItems((prev) => ({
                                                                    ...prev,
                                                                    [field.id]: value,
                                                                }));
                                                                const ei = event.eventItems.find(
                                                                    (eventItem) => eventItem.id === value,
                                                                );
                                                                if (ei) {
                                                                    form.setValue(
                                                                        `seats.${index}.price`,
                                                                        ei.price / 100,
                                                                    );
                                                                }
                                                            }}
                                                            error={
                                                                form.formState.errors.seats?.[index]?.eventItemId
                                                                    ?.type === 'required'
                                                                    ? 'Ticket type is required'
                                                                    : undefined
                                                            }
                                                            valuesAndNames={event.eventItems.map((eventItem) => [
                                                                eventItem.id,
                                                                eventItem.name,
                                                            ])}
                                                        />
                                                        <div className="flex flex-row items-center gap-2">
                                                            {selectedItem && (
                                                                <>
                                                                    {hasMinPrice ? (
                                                                        <BasicTooltip
                                                                            tooltip={`Minimum price is ${formatPrice(
                                                                                selectedItem.price,
                                                                            )} SEK, but feel free to pay more if you want to show your support`}
                                                                        >
                                                                            <div className="flex flex-row items-center ml-1 gap-2">
                                                                                <Input
                                                                                    type="number"
                                                                                    step={10}
                                                                                    min={formatPrice(
                                                                                        selectedItem.price,
                                                                                    )}
                                                                                    className="w-20"
                                                                                    {...form.register(
                                                                                        `seats.${index}.price` as const,
                                                                                    )}
                                                                                />
                                                                                SEK
                                                                            </div>
                                                                        </BasicTooltip>
                                                                    ) : (
                                                                        <p className="w-max">
                                                                            {formatPrice(selectedItem.price)} SEK
                                                                        </p>
                                                                    )}
                                                                </>
                                                            )}
                                                            <Button
                                                                size="icon"
                                                                variant="destructive"
                                                                onClick={() => {
                                                                    remove(index);
                                                                }}
                                                            >
                                                                <TrashIcon className="h-5 w-5" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {index !== fields.length - 1 && <Separator />}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </CardContent>
                            <CardFooter className="flex flex-row w-full justify-end p-4">
                                <Button type="submit" isLoading={response.isLoading} disabled={fields.length === 0}>
                                    Add to cart
                                </Button>
                                <div className="bottomContainerElement" ref={bottomRef as LegacyRef<HTMLDivElement>} />
                            </CardFooter>
                        </form>
                    </Form>
                </Card>
            </div>
        </div>
    );
}
