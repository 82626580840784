import { cn } from '@/lib/utils';
import { NavLink } from 'react-router-dom';
export const Footer = (): JSX.Element => {
    return (
        <footer className="w-full bg-background md:h-12 border-t flex flex-col md:flex-row justify-between items-center px-4 text-center">
            <p className="whitespace-nowrap">Lunds Akademiska Biljettbyrå</p>
            <div className="flex flex-col sm:flex-row">
                <div className="hidden sm:flex flex-col sm:flex-row">
                    <NavLink
                        to="/organizations"
                        end
                        className={({ isActive }) =>
                            cn('hover:underline p-1 whitespace-nowrap', isActive && 'underline')
                        }
                    >
                        Organizations
                    </NavLink>
                    <NavLink
                        to="/privacy-policy"
                        className={({ isActive }) =>
                            cn('hover:underline p-1 whitespace-nowrap', isActive && 'underline')
                        }
                    >
                        Privacy Policy
                    </NavLink>
                    <NavLink
                        to="/contact"
                        className={({ isActive }) =>
                            cn('hover:underline p-1 whitespace-nowrap', isActive && 'underline')
                        }
                    >
                        Contact Us
                    </NavLink>
                    <NavLink
                        to="/work-with-us"
                        className={({ isActive }) =>
                            cn('hover:underline p-1 whitespace-nowrap', isActive && 'underline')
                        }
                    >
                        Work With Us
                    </NavLink>
                </div>
            </div>
        </footer>
    );
};
