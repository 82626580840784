import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger, DialogTitle, DialogHeader } from '@/components/ui/dialog';
import { errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { useCreateUserMutation } from '@/services/userApi';
import { Form } from '@/components/ui/form';
import type { CreateUser as CreateUserType } from '@/types/User';
import { useForm } from 'react-hook-form';
import { FormInput } from '@/components/ui/FormHelpers';
import { useState, type PropsWithChildren } from 'react';

export const CreateUser: React.FC<PropsWithChildren> = ({ children }) => {
    const [createUser, { isLoading: responseLoading }] = useCreateUserMutation();
    const [open, setOpen] = useState(false);

    const form = useForm<CreateUserType>();

    const handleSubmit = form.handleSubmit;
    const errors = form.formState.errors;
    const isLoading = form.formState.isLoading || responseLoading;
    const control = form.control;
    const reset = form.reset;

    const submit = handleSubmit((data) => {
        createUser(data)
            .unwrap()
            .then(() => {
                successToast(`User ${data.firstName} ${data.lastName} created!`);
                reset();
                setOpen(false);
            })
            .catch(errorToastFromCatch);
    });

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create User</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={submit}>
                        <div className="grid gap-4 py-4">
                            <FormInput
                                control={control}
                                id="email"
                                label="Email"
                                error={errors.email?.type === 'required' ? 'Email is required' : undefined}
                            />
                            <FormInput
                                control={control}
                                id="firstName"
                                label="First Name"
                                error={errors.firstName?.type === 'required' ? 'First name is required' : undefined}
                            />
                            <FormInput
                                control={control}
                                id="lastName"
                                label="Last Name"
                                error={errors.lastName?.type === 'required' ? 'Last name is required' : undefined}
                            />
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button
                                variant="secondary"
                                type="button"
                                onClick={() => {
                                    reset();
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" isLoading={isLoading}>
                                Create
                            </Button>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};
