import { Check, ChevronsUpDown, X } from 'lucide-react';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { forwardRef, useEffect, useState } from 'react';

export type OptionType = Record<'value' | 'label', string>;

interface MultiSelectProps {
    options: OptionType[];
    selected: OptionType[];
    onChange: (selected: OptionType[]) => void;
    className?: string;
    placeholder?: string;
    outerQueryState?: [string, React.Dispatch<React.SetStateAction<string>>];
}

const MultiSelect = forwardRef<HTMLButtonElement, MultiSelectProps>((props, ref) => {
    const { options, selected, onChange, className, outerQueryState } = props;
    const [open, setOpen] = useState(false);
    const innerQueryState = useState('');
    const [query, setQuery] = outerQueryState ?? innerQueryState;

    const handleUnselect = (item: OptionType) => {
        onChange(selected.filter((i) => i.value !== item.value));
    };

    // on delete key press, remove last selected item
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setOpen(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onChange, query, selected]);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild className={className}>
                <Button
                    ref={ref}
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="group w-full justify-between min-h-[2.5rem] h-fit"
                    onClick={() => setOpen(!open)}
                >
                    <div className="flex flex-wrap items-center gap-1">
                        {selected.map((item) => (
                            <Badge
                                variant="outline"
                                key={item.value}
                                className="flex items-center gap-1 bg-background hover:bg-primary/10 hover:border-primary/10"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleUnselect(item);
                                    }
                                }}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleUnselect(item);
                                }}
                            >
                                {item.label}
                                <X className="h-3 w-3 text-muted-foreground border-none duration-300" />
                            </Badge>
                        ))}
                        {selected.length === 0 && <span>{props.placeholder ?? 'Select ...'}</span>}
                    </div>
                    <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-full min-w-[128x] p-0" align="start">
                <Command className={className} shouldFilter={!outerQueryState}>
                    <CommandInput
                        onValueChange={(item) => {
                            setQuery(item);
                        }}
                        placeholder="Search..."
                    />
                    <CommandEmpty>No item found.</CommandEmpty>
                    <CommandGroup className="max-h-64 overflow-auto">
                        {options.map((option) => (
                            <CommandItem
                                key={option.value}
                                onSelect={() => {
                                    onChange(
                                        selected.some((item) => item.value === option.value)
                                            ? selected.filter((item) => item.value !== option.value)
                                            : [...selected, option],
                                    );
                                    setOpen(true);
                                }}
                            >
                                <Check
                                    className={cn(
                                        'mr-2 h-4 w-4',
                                        selected.some((item) => item.value === option.value)
                                            ? 'opacity-100'
                                            : 'opacity-0',
                                    )}
                                />
                                {option.label}
                            </CommandItem>
                        ))}
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    );
});

MultiSelect.displayName = 'MultiSelect';

export { MultiSelect };
