import { useEffect, useState } from 'react';
import { useEditTicketMutation } from '@/services/ticketsApi';
import { Button } from '@/components/ui/button';
import type { TicketSmall } from '@/types/Ticket';
import { statusOptions, type Status } from '@/types/Status';
import { errorToastFromCatch, informativeToast, successToast } from '@/components/ui/use-toast';
import { UserSelect } from '../UserSelect';
import type { UserResponse } from '@/types/User';
import { useGetUserByIdQuery } from '@/services/userApi';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import FoodPreferences from '../ui/food-preferences';

interface EditTicketFormProps {
    onConfirm: () => void;
    onCancel: () => void;
    ticket: TicketSmall;
}

export function EditTicketForm({ onConfirm, onCancel, ticket }: EditTicketFormProps): JSX.Element {
    const [editTicket, { isLoading }] = useEditTicketMutation();
    const [user, setUser] = useState<UserResponse | undefined>(undefined);
    const { data: userData, isLoading: isLoadingUser } = useGetUserByIdQuery(ticket.userId);
    const [foodPreferences, setFoodPreferences] = useState<string[]>(ticket.foodPreferences || []);
    const [selectedStatus, setSelectedStatus] = useState(ticket.status);

    useEffect(() => {
        if (userData) {
            setUser(userData);
        }
    }, [userData]);

    const onSubmit = () => {
        if (!user) {
            informativeToast('No user selected');
            return;
        }
        editTicket({
            id: ticket.id,
            status: selectedStatus,
            userId: user.id,
            foodPreferences: foodPreferences,
        })
            .unwrap()
            .then(() => {
                successToast('Ticket updated');
                onConfirm();
            })
            .catch(errorToastFromCatch);
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-background/80 backdrop-blur-sm bg-opacity-40 flex justify-center items-center z-50">
            <div
                className="w-[460px] p-4 rounded-lg space-y-4 bg-primary-foreground shadow-lg border"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex flex-col space-y-2">
                    <div className="flex flex-col space-y-2">
                        <span className="text-lg font-bold">Status</span>
                        <Select
                            value={selectedStatus}
                            onValueChange={(e) => {
                                setSelectedStatus(e as Status);
                            }}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select status" />
                            </SelectTrigger>
                            <SelectContent>
                                {statusOptions.map((status, index) => (
                                    <SelectItem key={index} value={status.value}>
                                        {status.label}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <span className="text-lg font-bold">User</span>
                        <UserSelect user={user} setUser={setUser} className="w-full" isLoading={isLoadingUser} />
                    </div>
                    <div className="w-full max-w-md mx-auto">
                        <div className="space-y-4">
                            <FoodPreferences
                                foodPreferences={foodPreferences}
                                setFoodPreferences={setFoodPreferences}
                            />
                            <div className="flex justify-end space-x-2">
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onCancel();
                                    }}
                                    variant="secondary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="default"
                                    isLoading={isLoading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onSubmit();
                                    }}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditTicketForm;
