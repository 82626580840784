import TicketCard from '@/components/Tickets/TicketCard';
import { CenteredSpinner } from '@/components/ui/spinner';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAuth } from '@/hooks/apiHooks';
import { useGetUserTicketsQuery } from '@/services/ticketsApi';

export default function Tickets(): JSX.Element {
    const { user } = useAuth();
    const { data, isLoading } = useGetUserTicketsQuery(user?.id ?? '');

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!data) {
        return <div>Error fetching tickets</div>;
    }

    return (
        <div className="flex justify-center mt-4 md:mt-8 mx-4">
            <div className="w-full lg:max-w-5xl">
                <h1 className="text-3xl font-semibold mb-4">My Tickets</h1>
                <Tabs defaultValue="active">
                    <TabsList>
                        <TabsTrigger value="active">Unused Tickets</TabsTrigger>
                        <TabsTrigger value="consumed">Used Tickets</TabsTrigger>
                    </TabsList>
                    <TabsContent value="active">
                        <div className="space-y-2">
                            {data
                                .filter((ticket) => ticket.status === 'TAKEN')
                                .map((ticket) => (
                                    <div key={ticket.id}>
                                        <TicketCard ticket={ticket} />
                                    </div>
                                ))}
                        </div>
                    </TabsContent>

                    <TabsContent value="consumed">
                        <div className="space-y-2">
                            {data
                                .filter((ticket) => ticket.status === 'CONSUMED')
                                .map((ticket) => (
                                    <div key={ticket.id}>
                                        <TicketCard ticket={ticket} />
                                    </div>
                                ))}
                        </div>
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    );
}
