import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { useSignUpUserMutation, useVerifyOtpMutation } from '@/services/authApi';
import { isLabbError } from '@/types/Error';
import type { CreateUser } from '@/types/User';
import VerifyOtpForm from '@/components/VerifyOtpForm';
import { FormInput } from '@/components/ui/FormHelpers';
import { Form } from '@/components/ui/form';

export default function SignUp(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const [signUp, signUpResponse] = useSignUpUserMutation();
    const [verify, verifyResponse] = useVerifyOtpMutation();

    const from = location.state?.from?.pathname || '/';

    useEffect(() => {
        if (verifyResponse.isSuccess) {
            navigate(from, { replace: true });
        }
    }, [verifyResponse.isSuccess]);

    return (
        <div className="flex justify-center items-center h-full">
            <div className="w-80">
                {signUpResponse.isSuccess ? (
                    <VerifyOtpForm
                        verify={verify}
                        isLoading={verifyResponse.isLoading}
                        error={isLabbError(verifyResponse.error) ? verifyResponse.error.data.message : undefined}
                    />
                ) : (
                    <>
                        <SignUpForm
                            signUp={signUp}
                            isLoading={signUpResponse.isLoading}
                            error={isLabbError(signUpResponse.error) ? signUpResponse.error.data.message : undefined}
                        />
                        <p className="text-center mt-4">
                            Already have an account?{' '}
                            <Link to="/sign-in" className="underline">
                                Sign in
                            </Link>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
}
interface SignUpFormProps {
    signUp: (data: CreateUser) => void;
    error?: string;
    isLoading?: boolean;
}

function SignUpForm({ signUp, error, isLoading }: SignUpFormProps) {
    const form = useForm<CreateUser>();
    const errors = form.formState.errors;
    const loading = form.formState.isLoading || isLoading;

    useEffect(() => {
        if (error) {
            form.setError('email', {
                type: 'server',
                message: error,
            });
        }
    }, [error]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(signUp)} className="space-y-2 w-full">
                <FormInput
                    control={form.control}
                    id="email"
                    label="Email"
                    type="email"
                    error={errors.email?.type === 'required' ? 'Email is required' : undefined}
                />
                <FormInput
                    control={form.control}
                    id="firstName"
                    label="First Name"
                    error={errors.firstName?.type === 'required' ? 'First name is required' : undefined}
                />
                <FormInput
                    control={form.control}
                    id="lastName"
                    label="Last Name"
                    error={errors.lastName?.type === 'required' ? 'Last name is required' : undefined}
                />
                <Button isLoading={loading} type="submit" className="w-full">
                    Sign Up
                </Button>
            </form>
        </Form>
    );
}
