import { pageSizes, type Pagination } from '@/types/Pagination';
import type { EditTicket, TicketSmall, TicketFilter, Ticket } from '@/types/Ticket';

import { api } from './rootApi';

export const ticketsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTickets: builder.query<Pagination<TicketSmall>, TicketFilter>({
            query: ({ pageNumber = 0, pageSize = pageSizes.small, status, organizationId }) =>
                `tickets?pageNumber=${pageNumber}&pageSize=${pageSize}${
                    status && status.length > 0 ? `&status=${status.join(',')}` : ''
                }${organizationId ? `&organizationId=${organizationId}` : ''}`,
            providesTags: ['Ticket'],
        }),
        editTicket: builder.mutation<void, EditTicket>({
            query: ({ id, ...body }) => ({
                url: `tickets/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Ticket'],
        }),
        deleteTicket: builder.mutation<void, string>({
            query: (id) => ({
                url: `tickets/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Ticket'],
        }),
        getUserTickets: builder.query<Ticket[], string>({
            query: (userId) => ({
                url: `tickets/user/${userId}`,
                method: 'GET',
            }),
            providesTags: ['UserTicket'],
        }),
    }),
});

export const { useGetTicketsQuery, useEditTicketMutation, useGetUserTicketsQuery, useDeleteTicketMutation } =
    ticketsApi;
