import { useCurrentOrganization } from '@/hooks/apiHooks';
import { errorToast } from '../ui/use-toast';
import { ReferralSheet } from './ReferralSheet';
import { useCreateReferralCampaignMutation } from '@/services/referralApi';
import type { ReferralCampaignFormInput } from '@/types/Referral';

interface AddReferralProps {
    children: React.ReactNode;
}

export const AddReferral = (props: AddReferralProps): JSX.Element => {
    const organizationId = useCurrentOrganization()?.id;
    const [postReferral, { isLoading: isPosting }] = useCreateReferralCampaignMutation();

    const onSubmit = async (data: ReferralCampaignFormInput) => {
        if (!organizationId) {
            errorToast('OrganizationId is undefined');
            return;
        }
        return postReferral(data).unwrap();
    };
    return (
        <ReferralSheet onSubmit={onSubmit} isLoading={isPosting}>
            {props.children}
        </ReferralSheet>
    );
};
