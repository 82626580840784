import { Outlet } from 'react-router-dom';

import AdminHeader from '@/components/AdminHeader';
import Navigation from '@/components/Navigation';
import { Toaster } from '@/components/ui/toaster';
import { useMetaTags } from '@/hooks/metaDataHooks';

export default function AdminRoot(): JSX.Element {
    useMetaTags({ title: 'Admin' });
    return (
        <div className="w-screen h-screen flex flex-col">
            <div className="flex-shrink-0">
                <AdminHeader />
            </div>
            <div className="flex flex-1 overflow-hidden">
                <div className="flex-shrink-0">
                    <Navigation />
                </div>
                <div
                    className="flex-1 overflow-auto"
                    style={{
                        // not supported in tailwindcss yet see https://github.com/tailwindlabs/tailwindcss/discussions/11129
                        scrollbarGutter: 'stable',
                    }}
                >
                    <Outlet />
                </div>
                <Toaster />
            </div>
        </div>
    );
}
