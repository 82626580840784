import type { Status } from './Status';

export interface SeatMapSmall {
    id: string;
    name: string;
    description: string;
}

export interface SeatMap {
    id: string;
    name: string;
    description: string;
    categories: SeatCategory[];
    grid: GridObject[][];
}

export interface SeatMapInput {
    name: string;
    description: string;
    categories: SeatCategory[];
    grid: GridObject[][];
}

export interface SeatCategory {
    name: string;
    color: string;
}

export type GridObject = SeatMapSeat | EmptySpace | ColorObject;

export interface SeatMapSeat {
    index: number;
    row: number;
    seatNumber: number;
    category: SeatCategory;
    status: Status;
    seatId: string;
}

export interface EmptySpace {
    blank: true;
}

export interface ColorObject {
    color: string;
    shape: Shape;
}

export function isSeat(object: GridObject): object is SeatMapSeat {
    return 'index' in object;
}

export function isEmptySpace(object: GridObject): object is EmptySpace {
    return 'blank' in object;
}

export function isColorObject(object: GridObject): object is ColorObject {
    return 'color' in object;
}

export type Shape = 'CIRCLE' | 'SQUARE' | 'SQUIRCLE';
