import { OrganizationInfo } from '@/components/Organizations/OrganizationInfo';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useCurrentOrganization } from '@/hooks/apiHooks';
import { useGetOrganizationByIdQuery } from '@/services/organizationApi';
import { useEffect, useState } from 'react';

export const Organization = (): JSX.Element => {
    const organization = useCurrentOrganization();
    const [bannerSrc, setBannerSrc] = useState(
        `${import.meta.env.VITE_IMAGE_STORAGE_URL}/organization-banners/${organization?.id}/large`,
    );
    const [pictureSrc, setPictureSrc] = useState(
        `${import.meta.env.VITE_IMAGE_STORAGE_URL}/organization-pictures/${organization?.id}/large`,
    );

    useEffect(() => {
        setBannerSrc(`${import.meta.env.VITE_IMAGE_STORAGE_URL}/organization-banners/${organization?.id}/large`);
        setPictureSrc(`${import.meta.env.VITE_IMAGE_STORAGE_URL}/organization-pictures/${organization?.id}/large`);
    }, [organization?.id]);

    const { data, isLoading } = useGetOrganizationByIdQuery(organization?.id ?? '', {
        skip: !organization,
    });

    if (isLoading || !data || !organization) {
        return <CenteredSpinner />;
    }

    return (
        <OrganizationInfo
            organization={data}
            banner={bannerSrc}
            picture={pictureSrc}
            setBannerSrc={setBannerSrc}
            setPictureSrc={setPictureSrc}
            isEditing={false}
        />
    );
};
