import { useState, useEffect } from 'react';
import { useCreateReferralLinkMutation } from '@/services/referralApi';
import type { ReferralCampaign } from '@/types/Referral';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

interface Props {
    campaign: ReferralCampaign;
}

export default function ReferralLinkButton({ campaign }: Props): JSX.Element {
    const [copied, setCopied] = useState(false);
    const [referralLink, setReferralLink] = useState('');
    const [createLink, { data: linkData, isLoading, isSuccess }] = useCreateReferralLinkMutation();

    useEffect(() => {
        if (campaign?.id && !referralLink) {
            createLink({ campaignId: campaign.id });
        }
    }, [campaign, createLink, referralLink]);

    useEffect(() => {
        if (isSuccess && linkData?.id) {
            const firstEventId = campaign?.events?.[0]?.id;
            const link = `events/${firstEventId}?ref=${linkData.id}`;
            setReferralLink(window.location.origin + '/' + link);
        }
    }, [linkData, isSuccess, campaign]);

    const copyToClipboard = () => {
        if (referralLink) {
            navigator.clipboard
                .writeText(referralLink)
                .then(() => {
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000);
                })
                .catch((err) => console.error('Failed to copy link:', err));
        }
    };

    return (
        <div className="flex flex-row gap-2 w-fit">
            <Input value={referralLink} readOnly />
            <Button className="min-w-fit" onClick={copyToClipboard} disabled={isLoading || !referralLink}>
                {isLoading ? 'Loading...' : copied ? 'Link Copied!' : 'Copy Referral Link'}
            </Button>
        </div>
    );
}
