import { CenteredSpinner } from '@/components/ui/spinner';
import RoleCard from '@/components/Roles/RoleCard';
import { useCurrentOrganizationRoles } from '@/hooks/apiHooks';
import { Plus } from 'lucide-react';
import { AddRole } from '@/components/Roles/AddRole';
import { Button } from '@/components/ui/button';
import { PageHeader } from '@/components/PageHeader';

export default function Roles(): JSX.Element {
    const { roles, isLoading } = useCurrentOrganizationRoles();

    if (isLoading) {
        return <CenteredSpinner />;
    }

    return (
        <div className="flex flex-col items-center">
            <PageHeader>
                <AddRole>
                    <Button>
                        <Plus className="h-5 w-5 mr-2" />
                        <p>Create New Role</p>
                    </Button>
                </AddRole>
            </PageHeader>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 lg:max-w-7xl mx-auto">
                {roles.map((role) => (
                    <RoleCard key={role.id} role={role} />
                ))}
            </div>
        </div>
    );
}
