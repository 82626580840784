import { MeiliSearch } from 'meilisearch';

import { useEffect, useState } from 'react';
import type { UserResponse } from '@/types/User';
import { MultiSelect, type OptionType } from './ui/multi-select';

const client = new MeiliSearch({
    host: import.meta.env.VITE_MEILI_HOST as string,
    apiKey: import.meta.env.VITE_MEILI_API_KEY as string,
});

const searchIndex = client.index('users');

interface Props {
    selectedUsers: OptionType[];
    setSelectedUsers: (users: OptionType[]) => void;
}

export const UserMultiSelect: React.FC<Props> = (props) => {
    const { selectedUsers, setSelectedUsers } = props;
    const quarryState = useState('');
    const [query] = quarryState;
    const [users, setUsers] = useState<UserResponse[]>([]);

    useEffect(() => {
        if (query) {
            searchIndex.search<UserResponse>(query, { limit: 10 }).then((res) => {
                setUsers(res.hits);
            });
        } else {
            searchIndex.search<UserResponse>('', { limit: 10 }).then((res) => {
                setUsers(res.hits);
            });
        }
    }, [query]);

    const options = users.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
    }));

    return (
        <MultiSelect
            options={options}
            selected={selectedUsers}
            onChange={setSelectedUsers}
            outerQueryState={quarryState}
        />
    );
};
