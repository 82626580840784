import { pageSizes, type Pagination } from '@/types/Pagination';

import { api } from './rootApi';
import type { Role, RoleInput, RoleFilter, RoleResponse, EditRole, InviteId, InviteMessage } from '@/types/Role';

export const rolesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getRoles: builder.query<Pagination<Role>, RoleFilter>({
            query: ({ pageNumber = 0, pageSize = pageSizes.enormous, organizationId }) =>
                `roles?pageNumber=${pageNumber}&pageSize=${pageSize}${
                    organizationId ? `&organizationId=${organizationId}` : ''
                }`,
        }),
        createRole: builder.mutation<RoleResponse, RoleInput>({
            query: (body) => ({
                url: 'roles',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Role'],
        }),
        editRole: builder.mutation<RoleResponse, EditRole>({
            query: ({ id, description, title }) => ({
                url: `roles/${id}/edit`,
                method: 'PUT',
                body: {
                    description,
                    title,
                },
            }),
            invalidatesTags: ['Role'],
        }),
        deleteRole: builder.mutation<void, { roleId: string }>({
            query: ({ roleId }) => ({
                url: `roles/${roleId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Role'],
        }),
        getRoleUsers: builder.query({
            query: (roleId) => `roles/${roleId}/users`,
            providesTags: ['Role'],
        }),
        addUserToRole: builder.mutation<void, { roleId: string; userId: string }>({
            query: ({ roleId, userId }) => ({
                url: `roles/${roleId}/users`,
                method: 'POST',
                body: {
                    userId,
                },
            }),
            invalidatesTags: ['Role'],
        }),
        createInviteLink: builder.mutation<InviteId, { roleId: string; experationTime: string }>({
            query: ({ roleId, experationTime }) => ({
                url: `roles/invite`,
                method: 'POST',
                body: {
                    roleId,
                    experationTime,
                },
            }),
            invalidatesTags: ['Role'],
        }),
        getInviteLink: builder.mutation<InviteId, { roleId: string }>({
            query: ({ roleId }) => ({
                url: `roles/invite/${roleId}`,
                method: 'GET',
            }),
            invalidatesTags: ['Role'],
        }),
        acceptInviteLink: builder.mutation<InviteMessage, string>({
            query: (inviteId) => ({
                url: `roles/invite/accept`,
                method: 'POST',
                body: {
                    inviteId,
                },
            }),
        }),
        getRoleFromInvite: builder.query<RoleResponse, string>({
            query: (inviteId) => ({
                url: `roles/invite/accept/${inviteId}`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetRolesQuery,
    useGetRoleUsersQuery,
    useAddUserToRoleMutation,
    useCreateRoleMutation,
    useDeleteRoleMutation,
    useEditRoleMutation,
    useCreateInviteLinkMutation,
    useAcceptInviteLinkMutation,
    useGetInviteLinkMutation,
    useGetRoleFromInviteQuery,
} = rolesApi;
