import type { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '@/hooks/apiHooks';

export default function RequireAuth({ children }: PropsWithChildren): React.ReactNode {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    if (isAuthenticated) {
        return <Navigate to={location.state.from} replace />;
    }

    return children;
}
