import type { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useIsAdmin, useIsRepresentative } from '@/hooks/apiHooks';

export const RequireNotRepresentative = ({ children }: PropsWithChildren): React.ReactNode => {
    const isAdmin = useIsAdmin();
    const isRepresentative = useIsRepresentative();

    if (isAdmin || isRepresentative) {
        return <Navigate to="/admin" replace />;
    }

    return children;
};
