import { cn } from '@/lib/utils';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import type { SeatMapSeat } from '@/types/SeatMap';

interface SeatCommonProps {
    seat: SeatMapSeat;
    admin?: boolean;
}

type SeatConditionalProps =
    | {
          selected: boolean;
          toggleSelection: (seat: SeatMapSeat) => void;
          readOnly?: false;
      }
    | {
          selected?: never;
          toggleSelection?: never;
          readOnly: true;
      };

type SeatProps = SeatCommonProps & SeatConditionalProps;

export const Seat = ({ seat, readOnly, selected, toggleSelection, admin }: SeatProps): JSX.Element => {
    return (
        <div key={`${seat.index}-${seat.seatNumber}`} className="flex">
            <TooltipProvider>
                <Tooltip delayDuration={30} disableHoverableContent>
                    <TooltipTrigger asChild>
                        <div
                            onClick={() => !readOnly && toggleSelection(seat)}
                            style={{
                                // Setting styles in cs because the color comes from the backend
                                backgroundColor: seat.status === 'AVAILABLE' ? seat.category.color : undefined,
                            }}
                            className={cn(
                                'flex h-2 w-2 rounded-b-full bg-primary/60 hover:bg-primary/80 justify-center items-center cursor-pointer',
                                selected && 'border-2 border-red-400 hover:border-red-400/80 selected',
                            )}
                        />
                    </TooltipTrigger>
                    <TooltipContent sideOffset={10}>
                        <p className="text-lg">{seat.category.name}</p>
                        <p>Row {seat.row}</p>
                        <p>Seat {seat.seatNumber}</p>
                        {admin ? <p>{seat.status}</p> : seat.status !== 'AVAILABLE' && <p>Seat is unavailabe</p>}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};
