export interface OrganizationSmall {
    id: string;
    name: string;
    shortName: string;
    pictureBlurhash: string | null;
    bannerBlurhash: string | null;
}

export interface Organization extends OrganizationSmall {
    address: string;
    city: string;
    country: string;
    description: string;
    followerCount: number;
    isFollowing: boolean;
    zipCode: string;
}

export const organizationToOrganizationInput = (organization: Organization): OrganizationInput => {
    return {
        address: organization.address,
        city: organization.city,
        country: organization.country,
        description: organization.description,
        name: organization.name,
        shortName: organization.shortName,
        zipCode: organization.zipCode,
    };
};

export interface OrganizationFilter {
    pageNumber?: number;
    pageSize?: number;
    city?: string;
}

export interface OrganizationInput {
    address: string;
    city: string;
    country: string;
    description: string;
    name: string;
    shortName: string;
    zipCode: string;
}

export interface EditOrganizationInput extends OrganizationInput {
    id: string;
}
