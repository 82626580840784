import { api } from './rootApi';
import type { Ticket } from '@/types/Ticket';
import type { AddToCart } from '@/types/Cart';

export const cartApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // This is a mutation because then it will be ran every time and not cashed
        getCartByUserId: builder.mutation<Ticket[], string>({
            query: (userId) => ({ url: `carts/${userId}`, method: 'GET' }),
        }),
        addTicketsToCart: builder.mutation<{ message: string; status: number }, AddToCart>({
            query: ({ userId, cartItems }) => ({
                url: `carts/${userId}`,
                method: 'POST',
                body: { cartItems },
            }),
        }),
        removeTicketFromCart: builder.mutation<{ message: string; status: number }, string>({
            query: (eventItemId) => ({
                url: `carts/${eventItemId}`,
                method: 'DELETE',
            }),
        }),
        clearCart: builder.mutation<{ message: string; status: number }, string>({
            query: (userId) => ({
                url: `carts/${userId}/clear`,
                method: 'DELETE',
            }),
        }),
    }),
});

/**
 * This is actually a query, but it's named as a mutation because it's ran every time and not cached
 */
export const useGetCartByUserIdQuery = cartApi.useGetCartByUserIdMutation;

export const { useAddTicketsToCartMutation, useRemoveTicketFromCartMutation, useClearCartMutation } = cartApi;
