import { useGetEventAttendeesQuery, useLazyGetEventAttendeesCSVQuery } from '@/services/eventsApi';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { UsersIcon } from 'lucide-react';
import { Spinner } from '@/components/ui/spinner';
import { formatPrice } from '@/lib/utils';

interface AttendeesDialogProps {
    eventId: string;
    eventTitle: string;
}

export const AttendeesDialog = ({ eventId, eventTitle }: AttendeesDialogProps): JSX.Element => {
    const { data, isLoading } = useGetEventAttendeesQuery(eventId);
    const [getCSV] = useLazyGetEventAttendeesCSVQuery();
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button className="gap-2" variant="outline">
                    <UsersIcon className="h-4 w-4" />
                    <p>Attendees</p>
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Attendees ({data?.length})</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col space-y-2 max-h-96 overflow-y-scroll">
                    {isLoading ? (
                        <div className="flex justify-center items-center">
                            <Spinner size="lg" />
                        </div>
                    ) : (
                        <>
                            {data?.map((ticket, index) => (
                                <div key={`${ticket.user.id}-${index}`} className="flex flex-col">
                                    <p className="font-bold">
                                        {ticket.user.firstName} {ticket.user.lastName}
                                    </p>
                                    <p>{ticket.user.email}</p>
                                    <p className="font-semibold">
                                        {ticket.eventItem.name} - {formatPrice(ticket.eventItem.price)} SEK
                                    </p>
                                </div>
                            ))}
                        </>
                    )}
                </div>
                <DialogFooter>
                    <Button onClick={() => getCSV({ eventId, title: eventTitle })}>Download as CSV</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
