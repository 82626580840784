import { UploadIcon } from 'lucide-react';
import { useCallback } from 'react';
import { useDropzone, type Accept, type FileRejection } from 'react-dropzone';
import { errorToast } from './use-toast';

interface DropzoneProps {
    text: string;
    maxFiles?: number;
    setFile?: (file: SmallFile) => void;
    accept?: Accept;
    maxSize?: number;
}

export interface SmallFile {
    name: string;
    data: string;
}

function readFile(file: File) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}

const acceptValues = (accept: Accept) => {
    const values = Object.keys(accept).map(function (key) {
        return accept[key];
    });
    return values;
};

export default function Dropzone({
    text,
    maxFiles = 1,
    setFile,
    accept,
    maxSize = 5 * 1024 * 1024,
}: DropzoneProps): JSX.Element {
    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
        const firstFile = acceptedFiles[0];
        if (!firstFile) {
            return;
        }
        if (fileRejections.length > 0) {
            errorToast('Invalid file type');
            return;
        }
        readFile(firstFile)
            .then((dataUrl) => {
                setFile?.({ name: firstFile.name, data: dataUrl as string });
            })
            .catch((err) => {
                console.warn(err);
            });
    }, []);
    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        maxFiles,
        maxSize,
        accept,
    });

    return (
        <div
            {...getRootProps()}
            className="border-2 border-dashed p-8 rounded-lg text-center bg-accent/90 hover:cursor-pointer transition-all hover:py-24 hover:border-primary"
        >
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <UploadIcon className="w-10 h-10 text-gray-400" />
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">{text}</p>
                </div>
            )}
            {accept && (
                <p className="text-sm text-gray-500 dark:text-gray-400">
                    Accepted file types: {acceptValues(accept).join(', ')}
                </p>
            )}
            {isDragReject && <p className="text-sm text-red-500 dark:text-red-400">Not allowed file type.</p>}
        </div>
    );
}
