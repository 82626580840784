import { api } from './rootApi';
import type { SeatMap, SeatMapInput, SeatMapSmall } from '@/types/SeatMap';

export const seatMapApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSeatMaps: builder.query<SeatMapSmall[], void>({
            query: () => 'seat-maps',
            providesTags: ['SeatMap'],
        }),
        getSeatMapById: builder.query<SeatMap, string>({
            query: (seatMapId) => `seat-maps/${seatMapId}`,
            providesTags: ['SeatMap'],
        }),
        deleteSeatMap: builder.mutation<void, string>({
            query: (seatMapId) => ({
                url: `seat-maps/${seatMapId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['SeatMap'],
        }),
        createSeatMap: builder.mutation<SeatMap, SeatMapInput>({
            query: (seatMap) => ({
                url: 'seat-maps',
                method: 'POST',
                body: seatMap,
            }),
            invalidatesTags: ['SeatMap'],
        }),
    }),
});

export const { useGetSeatMapsQuery, useGetSeatMapByIdQuery, useDeleteSeatMapMutation, useCreateSeatMapMutation } =
    seatMapApi;
