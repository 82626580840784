import { api } from './rootApi';
import type { JobApplicationInput } from '@/types/JobApplication';

export const jobApplicationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        submitJobApplication: builder.mutation<{ message: string; status: number }, JobApplicationInput>({
            query: (application) => ({
                url: `job-application`,
                method: 'POST',
                body: application,
            }),
        }),
    }),
});

export const { useSubmitJobApplicationMutation } = jobApplicationApi;
