import { Plus, Trash } from 'lucide-react';
import { Button } from './button';
import { useState } from 'react';
import { errorToast } from './use-toast';
import { Input } from './input';

interface FoodPreferencesProps {
    foodPreferences: string[];
    setFoodPreferences: (newFoodPrefrences: string[]) => void;
}

export default function FoodPreferences({ foodPreferences, setFoodPreferences }: FoodPreferencesProps): JSX.Element {
    const [newPreference, setNewPreference] = useState('');

    const handleAddPreference = () => {
        if (newPreference.trim() !== '') {
            setFoodPreferences([...foodPreferences, newPreference]);
            setNewPreference('');
        } else {
            errorToast('Please enter a food preference');
        }
    };

    const handleRemovePreference = (index: number) => {
        setFoodPreferences(foodPreferences.filter((_, i) => i !== index));
    };
    return (
        <div className="space-y-2">
            <span className="text-lg font-bold">Food Preferences</span>
            {foodPreferences.map((preference, index) => (
                <div key={index} className="flex items-center justify-between">
                    <span className="flex-1 mr-2 px-2 py-1">{preference}</span>
                    <Button
                        type="button"
                        variant="destructive"
                        onClick={() => handleRemovePreference(index)}
                        size="icon"
                    >
                        <Trash className="h-5 w-5" />
                    </Button>
                </div>
            ))}
            <div className="flex items-center justify-between rounded">
                <Input
                    className="flex-1 mr-2 px-2 py-1"
                    placeholder="Add new preference"
                    value={newPreference}
                    onChange={(e) => setNewPreference(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleAddPreference();
                        }
                    }}
                />
                <Button type="button" onClick={handleAddPreference} size="icon">
                    <Plus className="h-5 w-5" />
                </Button>
            </div>
        </div>
    );
}
