import { Card, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import type { OrganizationSmall } from '@/types/Organization';
import { useNavigate } from 'react-router-dom';
import { PreviewImage } from '@/components/PreviewImage';
import {
    ORGANIZATION_BANNER_ASPECT_RATIO,
    ORGANIZATION_BANNER_PLACEHOLDER_BLURHASH,
    ORGANIZATION_BANNER_PLACEHOLDER_SRC,
    getOrganizationBannerSrc,
} from '@/lib/utils';

interface Props {
    organization: OrganizationSmall;
}

export default function OrganizationCard({ organization }: Props): JSX.Element {
    const navigate = useNavigate();
    return (
        <Card
            className="flex h-full flex-col hover:bg-accent hover:cursor-pointer"
            onClick={() => navigate(`/organizations/${organization.id}`)}
        >
            <PreviewImage
                url={
                    organization.bannerBlurhash
                        ? getOrganizationBannerSrc(organization.id)
                        : ORGANIZATION_BANNER_PLACEHOLDER_SRC
                }
                blurHash={organization.bannerBlurhash ?? ORGANIZATION_BANNER_PLACEHOLDER_BLURHASH}
                className="rounded-t-lg w-full"
                aspectRatio={ORGANIZATION_BANNER_ASPECT_RATIO}
            />
            <CardHeader>
                <CardTitle>{organization.name}</CardTitle>
                <CardDescription>{organization.shortName}</CardDescription>
            </CardHeader>
        </Card>
    );
}
