import { useCurrentOrganization } from '@/hooks/apiHooks';
import { useCreateRoleMutation } from '@/services/rolesApi';
import { RoleType, type RoleFormInput, type RoleInput } from '@/types/Role';
import { errorToast } from '../ui/use-toast';
import { RoleSheet } from './RoleSheet';

interface AddRoleProps {
    children: React.ReactNode;
}

export const AddRole = (props: AddRoleProps): JSX.Element => {
    const organizationId = useCurrentOrganization()?.id;
    const [postRole, { isLoading: isPosting }] = useCreateRoleMutation();

    const onSubmit = async (data: RoleFormInput) => {
        if (!organizationId) {
            errorToast('OrganizationId is undefined');
            return;
        }
        const newRole: RoleInput = {
            ...data,
            organizationId: organizationId,
            roleType: RoleType.CUSTOM,
        };
        return postRole(newRole).unwrap();
    };
    return (
        <RoleSheet onSubmit={onSubmit} isLoading={isPosting}>
            {props.children}
        </RoleSheet>
    );
};
