import { MoreHorizontal } from 'lucide-react';
import type { TicketSmall } from '@/types/Ticket';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import EditTicketForm from '@/components/Tickets/EditTicketForm';
import { useState } from 'react';
import { successToast } from '@/components/ui/use-toast';
import { DeleteDialog } from '@/components/ui/DeleteDialog';
import { useDeleteTicketMutation } from '@/services/ticketsApi';

interface TicketActionsProps {
    ticket: TicketSmall;
}

export const TicketActions: React.FC<TicketActionsProps> = ({ ticket }) => {
    const [showEditForm, setShowEditForm] = useState(false);
    const closeEdit = () => setShowEditForm(false);
    const [deleteTicket, deleteTicketState] = useDeleteTicketMutation();
    return (
        <>
            {showEditForm && <EditTicketForm ticket={ticket} onConfirm={closeEdit} onCancel={closeEdit} />}
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="icon">
                        <span className="sr-only">Open menu</span>
                        <MoreHorizontal className="h-4 w-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuLabel>Actions</DropdownMenuLabel>

                    <DropdownMenuItem
                        onClick={() => {
                            navigator.clipboard.writeText(ticket.id);
                            successToast('Copied ticket ID to clipboard');
                        }}
                    >
                        Copy ticket ID
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => setShowEditForm(true)}>Edit</DropdownMenuItem>
                    <DeleteDialog
                        isLoading={deleteTicketState.isLoading}
                        deleteItem={() => deleteTicket(ticket.id).unwrap()}
                    >
                        <DropdownMenuItem onSelect={(e) => e.preventDefault()} destructive>
                            Delete
                        </DropdownMenuItem>
                    </DeleteDialog>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
};
