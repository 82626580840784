export const About = (): JSX.Element => {
    return (
        <div className="flex flex-col items-center w-full h-full text-center px-2 sm:px-4">
            <Description />
        </div>
    );
};

const Description = () => {
    return (
        <div className="pb-8">
            <div className="flex flex-col bg-secondary rounded-xl max-w-6xl sm:px-24 px-8 py-8 sm:py-16 m-4 gap-8">
                <h1 className="text-2xl font-bold">{headers[0]}</h1>
                <Paragraph1 text={paragraphs[0] ?? ''} />
            </div>
        </div>
    );
};

const Paragraph1 = ({ text }: { text: string }) => {
    return <p className="mt-2 text-left">{text}</p>;
};

const headers = ['LABB - Lunds Akademiska BiljettByrå'];
const paragraphs = [
    'Welcome to LABB. We are an "Ideell Studentförening", (which means we are a non-profit student organization) founded in the great student city of Lund, Sweden. We at LABB were not happy with the slow, buggy and unavailable ticket systems used for the many student events in the city. That is why we decided to create our own. Our goal is to create the best ticketing sales platform in Lund so that student events can be created and organized simply by anyone.',
];
