import { CenteredSpinner } from '@/components/ui/spinner';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { PageHeader } from '@/components/PageHeader';
import { useGetSeatMapsQuery } from '@/services/seatMapApi';
import SeatMapCard from '@/components/Seats/SeatMapCard';

export default function SeatMaps(): JSX.Element {
    const navigate = useNavigate();
    const { data, isLoading } = useGetSeatMapsQuery();

    if (isLoading || !data) {
        return <CenteredSpinner />;
    }

    return (
        <>
            <PageHeader>
                <Button onClick={() => navigate('/admin/seat-maps/create')}>
                    <Plus className="h-5 w-5 mr-2" />
                    <p>Create New Seat Map</p>
                </Button>
            </PageHeader>
            <div className="flex flex-col ">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 lg:max-w-7xl">
                    {data.map((seatMap) => (
                        <SeatMapCard seatMap={seatMap} key={seatMap.id} />
                    ))}
                </div>
            </div>
        </>
    );
}
