/**
 * This function narrows from an unknown value by asserting that:
 * - The given value is not undefined.
 * - There is a property for the given key name, and that the key is not undefined.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isKey = <K extends keyof any>(val: unknown, key: K): val is Record<K, unknown> => {
    // See this issue for future improvements in narrowing the unknown type:
    // https://github.com/microsoft/TypeScript/issues/25720
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (val !== undefined && val !== null && (val as any)[key] !== undefined) {
        return true;
    }
    return false;
};

/**
 * This function narrows from an unknown value to a string.
 */
export const isString = (val: unknown): val is string => {
    return typeof val === 'string';
};

/**
 * This function narrows from an unknown value to a number.
 */

export const isNumber = (val: unknown): val is number => {
    return typeof val === 'number';
};
