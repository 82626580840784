import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

import { Spinner } from './spinner';

const buttonVariants = cva(
    'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default: 'bg-primary text-primary-foreground hover:bg-primary/80',
                destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/80',
                outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
                secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                link: 'text-primary underline-offset-4 hover:underline',
                green: 'bg-labbGreen text-labbGreen-foreground hover:bg-labbGreen/80',
                lightBlue: 'bg-labbLightBlue text-labbLightBlue-foreground hover:bg-labbLightBlue/80',
                orange: 'bg-labbOrange text-labbOrange-foreground hover:bg-orange/80',
            },
            size: {
                default: 'h-10 px-4 py-2',
                sm: 'h-9 rounded-md px-3',
                xs: 'h-8 rounded-md px-2',
                lg: 'h-11 rounded-md px-8',
                xl: 'h-14 rounded-md px-11',
                icon: 'h-10 w-10',
                iconSm: 'h-8 w-8',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, isLoading = false, children, disabled, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button';
        return (
            <Comp
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                {...props}
                disabled={disabled || isLoading}
            >
                {size === 'icon' ? (
                    isLoading ? (
                        <Spinner size="sm" />
                    ) : (
                        children
                    )
                ) : (
                    <>
                        {isLoading && <Spinner size="sm" className="mr-2" />}
                        {children}
                    </>
                )}
            </Comp>
        );
    },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
