import type { OrganizationInput } from '@/types/Organization';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';

interface Props<T extends OrganizationInput> {
    organization: T;
    picture?: string;
    banner?: string;
    setBannerSrc: (src: string) => void;
    setPictureSrc: (src: string) => void;
    isEditing: boolean;
}

export const OrganizationInfo = <T extends OrganizationInput>({
    organization,
    picture,
    banner,
    isEditing,
    setBannerSrc,
    setPictureSrc,
}: Props<T>): JSX.Element => {
    return (
        <div className="flex flex-col items-center w-full h-full p-4">
            <div className="w-full flex justify-center">
                <img
                    src={banner}
                    alt={`${organization.name} banner`}
                    className="w-full max-w-5xl rounded-lg"
                    onError={() => setBannerSrc('/images/banner-placeholder.svg')}
                />
            </div>

            <div className="flex flex-col items-center p-8 w-full max-w-5xl gap-4 h-full">
                <div className="flex flex-col md:flex-row items-center gap-4">
                    <h1 className="text-4xl font-bold">{organization.shortName}</h1>
                    <h2 className="text-4xl text-center">{organization.name}</h2>
                    {!isEditing && (
                        <Link to={`/admin/organization/edit`}>
                            <Button>Edit</Button>
                        </Link>
                    )}
                </div>
                <p className="text-lg whitespace-pre-line">{organization.description}</p>
                <div className="flex flex-row items-center gap-4 mt-auto">
                    <address className="italic w-[50%]">
                        {organization.address}, {organization.zipCode}
                    </address>
                    <div className="bg-accent h-full w-1 rounded-full" />
                    <div className="flex flex-row items-center gap-4 w-[50%]">
                        <address className="italic">
                            {organization.city}, {organization.country}
                        </address>
                        <img
                            src={picture}
                            alt={`${organization.name} picture`}
                            className="w-16 h-16 p-2 rounded-md"
                            onError={() => setPictureSrc('/images/picture-placeholder.svg')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
