import { Button } from '../ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger, SheetFooter } from '@/components/ui/sheet';
import { useForm } from 'react-hook-form';
import { FormInput, FormTextArea } from '../ui/FormHelpers';
import { Form } from '../ui/form';
import type { PlaceFormInput, PlaceInput } from '@/types/Place';
import { useState } from 'react';
import { errorToastFromCatch, successToast } from '../ui/use-toast';
import { useNavigate } from 'react-router-dom';
import { useLazyGetCoordinatesQuery } from '@/services/mapApi';

interface PlaceSheetProps {
    onSubmit: (data: PlaceInput) => Promise<unknown>;
    isLoading: boolean;
    place?: PlaceFormInput;
    children: React.ReactNode;
}

export const PlaceSheet = (props: PlaceSheetProps): JSX.Element => {
    const [getCoordinates, coordinatesState] = useLazyGetCoordinatesQuery();
    const [open, setOpen] = useState(false);
    const { onSubmit, place } = props;
    const navigate = useNavigate();

    const form = useForm<PlaceFormInput>({
        defaultValues: {
            name: place?.name,
            description: place?.description,
            city: place?.city ?? 'Lund',
            country: place?.country ?? 'Sweden',
            zipCode: place?.zipCode,
            address: place?.address,
        },
    });
    const handleSubmit = form.handleSubmit;
    const errors = form.formState.errors;
    const isLoading = form.formState.isLoading || props.isLoading || coordinatesState.isLoading;
    const control = form.control;
    const reset = form.reset;

    const submit = handleSubmit((data) => {
        return getCoordinates(data).then((result) => {
            let lon = 0;
            let lat = 0;
            if (result.isSuccess) {
                lon = result.data[0]?.lon ?? 0;
                lat = result.data[0]?.lat ?? 0;
            }
            const placeInput: PlaceInput = {
                ...data,
                longitude: lon,
                latitude: lat,
            };
            return onSubmit(placeInput)
                .then(() => {
                    reset();
                    setOpen(false);
                    successToast('Place saved successfully');
                    navigate('/admin/places');
                })
                .catch(errorToastFromCatch);
        });
    });

    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger asChild>{props.children}</SheetTrigger>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Place Information</SheetTitle>
                </SheetHeader>
                <Form {...form}>
                    <form onSubmit={submit}>
                        <div className="grid gap-4 py-4">
                            <FormInput
                                control={control}
                                id="name"
                                label="Name"
                                error={errors.name?.type === 'required' ? 'Name is required' : undefined}
                            />
                            <FormInput
                                control={control}
                                id="address"
                                label="Address"
                                error={errors.address?.type === 'required' ? 'Address is required' : undefined}
                            />
                            <FormInput
                                control={control}
                                id="zipCode"
                                label="Zip code"
                                error={errors.zipCode?.type === 'required' ? 'Zip code is required' : undefined}
                            />
                            <FormInput
                                control={control}
                                id="city"
                                label="City"
                                error={errors.city?.type === 'required' ? 'City is required' : undefined}
                            />
                            <FormInput
                                control={control}
                                id="country"
                                label="Country"
                                error={errors.country?.type === 'required' ? 'Country is required' : undefined}
                            />
                            <FormTextArea
                                control={control}
                                id="description"
                                label="Description"
                                error={errors.description?.type === 'required' ? 'Description is required' : undefined}
                            />
                        </div>
                        <SheetFooter>
                            <div className="flex justify-end mt-4">
                                <Button type="submit" isLoading={isLoading}>
                                    Submit
                                </Button>
                            </div>
                        </SheetFooter>
                    </form>
                </Form>
            </SheetContent>
        </Sheet>
    );
};
