import { useNavigate, useParams } from 'react-router-dom';

import { CenteredSpinner } from '@/components/ui/spinner';
import { useGetEventByIdQuery, useGetEventSeatMapByIdQuery } from '@/services/eventsApi';
import type { SeatMapSeat, SeatMap as SeatMapType } from '@/types/SeatMap';
import { useEffect, useState } from 'react';
import { errorToast, errorToastFromCatch } from '@/components/ui/use-toast';
import SeatMap from '@/components/Seats/SeatMap';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { ArrowLeftIcon, SaveAllIcon, ShoppingBasketIcon, TrashIcon } from 'lucide-react';
import { FormSelect } from '@/components/ui/FormHelpers';
import { useAddTicketsToCartMutation, useClearCartMutation } from '@/services/cartApi';
import { useFieldArray, useForm } from 'react-hook-form';
import type { SeatMapFormValues } from '@/types/Cart';
import { formatPrice } from '@/lib/utils';
import { PageHeader } from '@/components/PageHeader';
import { AdminSeatMapCheckoutDialog } from '@/components/Events/AdminSeatMapCheckoutDialog';
import type { UserResponse } from '@/types/User';
import { UserSelect } from '@/components/UserSelect';
import { Separator } from '@/components/ui/separator';
import { BasicTooltip } from '@/components/ui/tooltip';
import { Input } from '@/components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { seatStatusOptions, type Status } from '@/types/Status';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useUpdateSeatsMutation } from '@/services/seatsApi';

export default function EventDetails(): JSX.Element {
    const navigate = useNavigate();
    const { eventId } = useParams() as { eventId: string };
    const { data: event, isLoading } = useGetEventByIdQuery(eventId);
    const [fetchSeatMapQuery, fetchResponse] = useGetEventSeatMapByIdQuery();
    const [seatMap, setSeatMap] = useState<SeatMapType>();
    const [openCheckout, setOpenCheckout] = useState(false);
    const [user, setUser] = useState<UserResponse | undefined>(undefined);
    const [selectedTab, setSelectedTab] = useState('buy');
    const [selectedSeats, setSelectedSeats] = useState<SeatMapSeat[]>([]);
    const [seatStatus, setSeatStatus] = useState<Status>('AVAILABLE');

    const [eventItems, setEventItems] = useState<Record<string, string>>();

    const [addToCart] = useAddTicketsToCartMutation();
    const [clearCart] = useClearCartMutation();

    const [updateSeats, updateSeatsResponse] = useUpdateSeatsMutation();

    useEffect(() => {
        if (eventId) {
            fetchSeatMap();
        }
    }, [eventId]);

    const form = useForm<SeatMapFormValues>();
    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'seats',
    });

    const fetchSeatMap = () => {
        return fetchSeatMapQuery(eventId)
            .unwrap()
            .then((seatMapData) => {
                setSeatMap(seatMapData);
            })
            .catch(errorToastFromCatch);
    };

    const toggleSelection = (seat: SeatMapSeat) => {
        if (selectedTab === 'buy') {
            if (seat.status === 'AVAILABLE' && !fields.some((field) => field.seat.seatId === seat.seatId)) {
                append({ seat, eventItemId: undefined, price: 0 });
            } else {
                remove(fields.findIndex((field) => field.seat.seatId === seat.seatId));
            }
        } else {
            if (
                seat.status !== 'TAKEN' &&
                seat.status !== 'CONSUMED' &&
                !selectedSeats.some((selectedSeat) => selectedSeat.seatId === seat.seatId)
            ) {
                setSelectedSeats([...selectedSeats, seat]);
            } else {
                setSelectedSeats(selectedSeats.filter((selectedSeat) => selectedSeat.seatId !== seat.seatId));
            }
        }
    };

    // Add selected items to cart
    const handleAddToCart = (data: SeatMapFormValues) => {
        if (!user) {
            errorToast('Please select who is making the purchase');
            return;
        }
        const formattedData = data.seats.map((seat) => {
            return {
                eventItemId: seat.eventItemId ?? '',
                seatId: seat.seat.seatId,
                actualPrice: seat.price * 100,
            };
        });

        addToCart({
            userId: user.id,
            cartItems: formattedData,
        })
            .unwrap()
            .then(() => {
                setOpenCheckout(true);
            })
            .catch(errorToastFromCatch);
    };

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!event) {
        return <div>Error fetching seat map</div>;
    }

    return (
        <div className="h-full">
            <PageHeader>
                <Button variant="ghost" onClick={() => navigate(`/admin/events/${eventId}`)} className="gap-2">
                    <ArrowLeftIcon className="h-5 w-5" /> <p>Back to event</p>
                </Button>
            </PageHeader>
            <div className="flex flex-col xl:flex-row gap-4 h-[calc(100%-56px)] p-4">
                {fetchResponse.isLoading ? (
                    <CenteredSpinner />
                ) : (
                    seatMap && (
                        <SeatMap
                            admin
                            seatMap={seatMap}
                            selectedSeats={selectedTab === 'buy' ? fields.map((f) => f.seat) : selectedSeats}
                            toggleSelection={toggleSelection}
                        />
                    )
                )}
                <Tabs defaultValue="buy" onValueChange={setSelectedTab}>
                    <TabsList className="mb-2">
                        <TabsTrigger value="buy">Buy</TabsTrigger>
                        <TabsTrigger value="administrate">Administrate</TabsTrigger>
                    </TabsList>
                    <Card className="h-fit min-w-[600px]">
                        <TabsContent value="buy">
                            <Form {...form}>
                                <form onSubmit={form.handleSubmit(handleAddToCart)}>
                                    <CardHeader>
                                        <h2 className="text-xl">Order Summary</h2>
                                    </CardHeader>
                                    <CardContent>
                                        {fields.length === 0 ? (
                                            <p className="text-md py-4">No seat selected</p>
                                        ) : (
                                            <div>
                                                {fields.map((field, index) => {
                                                    const selectedItem = event.eventItems.find(
                                                        (eventItem) => eventItem.id === eventItems?.[field.id],
                                                    );
                                                    const hasMinPrice = selectedItem?.hasMinimumPrice;
                                                    return (
                                                        <div key={field.id} className="flex flex-col gap-2 my-2">
                                                            <p className="font-bold text-lg">
                                                                {field.seat.category.name}
                                                            </p>
                                                            <p>
                                                                Row {field.seat.row} - Seat {field.seat.seatNumber}
                                                            </p>
                                                            <div className="flex flex-row justify-between">
                                                                <FormSelect
                                                                    className="w-36"
                                                                    control={form.control}
                                                                    placeholder="Select type"
                                                                    id={`seats.${index}.eventItemId` as const}
                                                                    onChange={(value) => {
                                                                        setEventItems((prev) => ({
                                                                            ...prev,
                                                                            [field.id]: value,
                                                                        }));
                                                                        const ei = event.eventItems.find(
                                                                            (eventItem) => eventItem.id === value,
                                                                        );
                                                                        if (ei) {
                                                                            form.setValue(
                                                                                `seats.${index}.price`,
                                                                                ei.price / 100,
                                                                            );
                                                                        }
                                                                    }}
                                                                    error={
                                                                        form.formState.errors.seats?.[index]
                                                                            ?.eventItemId?.type === 'required'
                                                                            ? 'Ticket type is required'
                                                                            : undefined
                                                                    }
                                                                    valuesAndNames={event.eventItems.map(
                                                                        (eventItem) => [eventItem.id, eventItem.name],
                                                                    )}
                                                                />
                                                                <div className="flex flex-row items-center gap-2">
                                                                    {selectedItem && (
                                                                        <>
                                                                            {hasMinPrice ? (
                                                                                <BasicTooltip
                                                                                    tooltip={`Minimum price is ${formatPrice(
                                                                                        selectedItem.price,
                                                                                    )} SEK, but feel free to pay more if you want to show your support`}
                                                                                >
                                                                                    <div className="flex flex-row items-center ml-1 gap-2">
                                                                                        <Input
                                                                                            type="number"
                                                                                            step={10}
                                                                                            min={formatPrice(
                                                                                                selectedItem.price,
                                                                                            )}
                                                                                            className="w-20"
                                                                                            {...form.register(
                                                                                                `seats.${index}.price` as const,
                                                                                            )}
                                                                                        />
                                                                                        SEK
                                                                                    </div>
                                                                                </BasicTooltip>
                                                                            ) : (
                                                                                <p className="w-max">
                                                                                    {formatPrice(selectedItem.price)}{' '}
                                                                                    SEK
                                                                                </p>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    <Button
                                                                        size="icon"
                                                                        variant="destructive"
                                                                        onClick={() => {
                                                                            remove(index);
                                                                        }}
                                                                    >
                                                                        <TrashIcon className="h-5 w-5" />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {index !== fields.length - 1 && <Separator />}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        <Separator className="my-2" />
                                        <UserSelect user={user} setUser={setUser} className="w-full" />
                                    </CardContent>
                                    <CardFooter className="flex flex-row w-full justify-between gap-2 p-4">
                                        <Button
                                            variant="destructive"
                                            type="reset"
                                            onClick={() =>
                                                form.reset({
                                                    seats: [],
                                                })
                                            }
                                            disabled={fields.length === 0}
                                        >
                                            Reset
                                        </Button>
                                        <AdminSeatMapCheckoutDialog
                                            onOpenChange={(open) => {
                                                setOpenCheckout(open);
                                                if (!open) {
                                                    fetchSeatMap();
                                                    if (user) {
                                                        clearCart(user.id);
                                                    }
                                                }
                                            }}
                                            open={openCheckout}
                                            onSuccess={() => {
                                                form.reset({
                                                    seats: [],
                                                });
                                                setUser(undefined);
                                                setOpenCheckout(false);
                                                fetchSeatMap();
                                            }}
                                            userId={user ? user.id : ''}
                                        />
                                        <Button type="submit" className="gap-2" disabled={fields.length === 0}>
                                            <ShoppingBasketIcon className="h-4 w-4" />
                                            <p>Checkout</p>
                                        </Button>
                                    </CardFooter>
                                </form>
                            </Form>
                        </TabsContent>
                        <TabsContent value="administrate">
                            <CardHeader>
                                <h2 className="text-xl">Administrate seats</h2>
                            </CardHeader>
                            <div className="px-4">
                                {selectedSeats.length === 0 ? (
                                    <p className="text-md">No seat selected</p>
                                ) : (
                                    <>
                                        <Table className="table-auto">
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead>Category</TableHead>
                                                    <TableHead>Row</TableHead>
                                                    <TableHead>Seat</TableHead>
                                                    <TableHead>
                                                        <></>
                                                    </TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {selectedSeats.map((seat) => (
                                                    <TableRow key={seat.seatId}>
                                                        <TableCell>{seat.category.name}</TableCell>
                                                        <TableCell>{seat.row}</TableCell>
                                                        <TableCell>{seat.seatNumber}</TableCell>
                                                        <TableCell>
                                                            <Button
                                                                size="icon"
                                                                variant="destructive"
                                                                onClick={() => {
                                                                    setSelectedSeats(
                                                                        selectedSeats.filter(
                                                                            (selectedSeat) =>
                                                                                selectedSeat.seatId !== seat.seatId,
                                                                        ),
                                                                    );
                                                                }}
                                                            >
                                                                <TrashIcon className="h-5 w-5" />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>

                                        <Select
                                            disabled={selectedSeats.length === 0}
                                            value={seatStatus}
                                            onValueChange={(value) => {
                                                setSeatStatus(value as Status);
                                            }}
                                        >
                                            <SelectTrigger className="w-full mt-2">
                                                <SelectValue placeholder="Select status" />
                                            </SelectTrigger>
                                            <SelectContent side="top">
                                                {seatStatusOptions.map((option) => (
                                                    <SelectItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </>
                                )}
                            </div>
                            <CardFooter className="flex flex-row w-full justify-between gap-2 p-4">
                                <Button
                                    variant="destructive"
                                    type="reset"
                                    onClick={() => setSelectedSeats([])}
                                    disabled={selectedSeats.length === 0 || updateSeatsResponse.isLoading}
                                >
                                    Reset
                                </Button>
                                <Button
                                    isLoading={updateSeatsResponse.isLoading}
                                    className="gap-2"
                                    disabled={selectedSeats.length === 0}
                                    onClick={() =>
                                        updateSeats({
                                            seats: selectedSeats.map((seat) => seat.seatId),
                                            status: seatStatus,
                                        })
                                            .unwrap()
                                            .then(() => {
                                                setSelectedSeats([]);
                                                fetchSeatMap();
                                            })
                                    }
                                >
                                    <SaveAllIcon className="h-4 w-4" />
                                    <p>Update</p>
                                </Button>
                            </CardFooter>
                        </TabsContent>
                    </Card>
                </Tabs>
            </div>
        </div>
    );
}
