import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { formatPrice } from '@/lib/utils';
import { Calendar, Coins, Ticket, User2 } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { BasicTooltip } from '@/components/ui/tooltip';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useCurrentOrganization } from '@/hooks/apiHooks';
import { useGetStatsQuery } from '@/services/statApi';
import { RevenueChart } from '@/components/RevenueChart';
import { useGetTicketsQuery } from '@/services/ticketsApi';

export const AdminHome = (): JSX.Element => {
    const currentOrganization = useCurrentOrganization();
    const { data: statData, isLoading: statIsLoading } = useGetStatsQuery({
        organizationId: currentOrganization?.id,
    });
    const { data: ticketsData, isLoading: ticketIsLoading } = useGetTicketsQuery({
        organizationId: currentOrganization?.id,
    });

    if (statIsLoading || ticketIsLoading) {
        return <CenteredSpinner />;
    }
    return (
        <>
            <div className="hidden flex-col md:flex">
                <div className="flex-1 space-y-4 p-8 pt-6">
                    <Tabs defaultValue="overview" className="space-y-4">
                        <TabsList>
                            <TabsTrigger value="overview">Overview</TabsTrigger>
                        </TabsList>
                        <TabsContent value="overview" className="space-y-4">
                            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                                <Card>
                                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                        <CardTitle className="text-sm font-medium">Total Revenue</CardTitle>
                                        <Coins className="h-6 w-6" />
                                    </CardHeader>
                                    <BasicTooltip tooltip="Total revenue from sales. Does not include fees.">
                                        <CardContent>
                                            <div className="text-2xl font-bold">
                                                {formatPrice(statData?.salesRevenue ?? 0)} kr
                                            </div>
                                        </CardContent>
                                    </BasicTooltip>
                                </Card>
                                <Card>
                                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                        <CardTitle className="text-sm font-medium">Sold Tickets</CardTitle>
                                        <Ticket className="h-5 w-5" />
                                    </CardHeader>
                                    <BasicTooltip tooltip="Total number of unique tickets sold.">
                                        <CardContent>
                                            <div className="text-2xl font-bold">{statData?.soldTickets ?? 0}</div>
                                        </CardContent>
                                    </BasicTooltip>
                                </Card>
                                <Card>
                                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                        <CardTitle className="text-sm font-medium">Users</CardTitle>
                                        <User2 className="h-5 w-5" />
                                    </CardHeader>
                                    <BasicTooltip tooltip="Total number of unique users that have bought tickets.">
                                        <CardContent>
                                            <div className="text-2xl font-bold">{statData?.users ?? 0}</div>
                                        </CardContent>
                                    </BasicTooltip>
                                </Card>
                                <Card>
                                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                        <CardTitle className="text-sm font-medium">Events</CardTitle>
                                        <Calendar className="h-5 w-5" />
                                    </CardHeader>
                                    <BasicTooltip tooltip="Total created unique events, with at least one ticket sold.">
                                        <CardContent>
                                            <div className="text-2xl font-bold">{statData?.events ?? 0}</div>
                                        </CardContent>
                                    </BasicTooltip>
                                </Card>
                            </div>
                            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
                                <div className="col-span-4">
                                    <RevenueChart salesTimeSeries={statData?.salesTimeSeries ?? []} />
                                </div>
                                <Card className="col-span-3">
                                    <CardHeader>
                                        <CardTitle>Recent Sales</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <div className="space-y-4">
                                            {ticketsData?.data
                                                .filter((ticket) => ticket.actualPrice > 0)
                                                .slice(0, 8)
                                                .map((ticket) => (
                                                    <div className="flex items-center">
                                                        <div className="ml-4 space-y-1">
                                                            <p className="text-sm font-medium leading-none">
                                                                {ticket.eventTitle}
                                                            </p>
                                                            <p className="text-sm text-muted-foreground">
                                                                {ticket.eventItemName}
                                                            </p>
                                                        </div>
                                                        <div className="ml-auto font-medium">
                                                            + {formatPrice(ticket.actualPrice)} kr
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </TabsContent>
                    </Tabs>
                </div>
            </div>
        </>
    );
};
