import { useState } from 'react';
import { Button } from './button';

interface ExpandableTextProps {
    text?: string;
    lines?: number;
}

export const ExpandableText: React.FC<ExpandableTextProps> = ({ text, lines = 5 }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const lineHeight = 1.5; // Assuming a line height of 1.5rem
    const truncatedHeight = `${lineHeight * lines}em`;

    return (
        <div className="space-y-2">
            <p
                className="whitespace-pre-line text-base overflow-hidden transition-[max-height] duration-500 ease-in-out"
                style={{
                    maxHeight: isExpanded ? '100vh' : truncatedHeight,
                }}
            >
                {text}
            </p>
            <Button variant="ghost" size="sm" onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? 'Read less' : 'Read more'}
            </Button>
        </div>
    );
};
