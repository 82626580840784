import { ExpandableText } from '@/components/ui/ExpandableText';
import { BuyDrawer } from '@/components/BuyDrawer';
import { CenteredSpinner } from '@/components/ui/spinner';
import {
    EVENT_BANNER_ASPECT_RATIO,
    EVENT_BANNER_PLACEHOLDER_BLURHASH,
    EVENT_BANNER_PLACEHOLDER_SRC,
    formatDate,
    getEventBannerSrc,
} from '@/lib/utils';
import { useGetEventByIdQuery } from '@/services/eventsApi';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@/hooks/apiHooks';
import { Button } from '@/components/ui/button';
import { BasicTooltip } from '@/components/ui/tooltip';
import { PreviewImage } from '@/components/PreviewImage';
import { MapPinIcon } from 'lucide-react';
import { informativeToast } from '@/components/ui/use-toast';
import { useEffect } from 'react';
import { RelatedEventsScroll } from '@/components/Events/RelatedEventsScroll';
import { useMetaTags } from '@/hooks/metaDataHooks';

export const Event = (): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAuth();
    const { eventId } = useParams<{ eventId: string }>();
    const { data, isLoading } = useGetEventByIdQuery(eventId ?? '');
    useMetaTags({
        title: data?.title,
        description: data?.description,
        image: data?.bannerBlurhash ? getEventBannerSrc(data.id) : EVENT_BANNER_PLACEHOLDER_SRC,
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const ref = queryParams.get('ref');
        if (ref) {
            localStorage.setItem('eventReferral', ref);
        }
    }, [location]);

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!data) {
        return <div>Error fetching event</div>;
    }

    return (
        <div className="w-full md:w-3/5 lg:w-1/2 flex flex-col space-y-4 justify-center p-4 space md:py-4 mx-auto">
            <PreviewImage
                url={data.bannerBlurhash ? getEventBannerSrc(data.id) : EVENT_BANNER_PLACEHOLDER_SRC}
                blurHash={data.bannerBlurhash ?? EVENT_BANNER_PLACEHOLDER_BLURHASH}
                className="w-full rounded-lg"
                aspectRatio={EVENT_BANNER_ASPECT_RATIO}
            />
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                <div>
                    <h1 className="text-3xl">{data.title}</h1>
                    <p className="flex gap-1 items-center">
                        <MapPinIcon className="h-5 w-5" />
                        {data.place.name}
                    </p>
                    <Link to={`/organizations/${data.organization.id}`}>
                        <p>{data.organization.name}</p>
                    </Link>
                </div>
                <div className="flex gap-2">
                    <p>{formatDate(data.startDate)}</p>
                    <p>-</p>
                    <p>{formatDate(data.endDate)}</p>
                </div>
            </div>

            {data.otherRecurring && <RelatedEventsScroll relatedEvents={data.otherRecurring} />}
            {data.soldOut ? (
                <Button className="w-full" disabled>
                    Tickets are sold out
                </Button>
            ) : !user ? (
                <BasicTooltip tooltip="Sign in to buy tickets" side="bottom">
                    <Button
                        className="w-full"
                        onClick={() => {
                            navigate('/sign-in', { state: { from: location.pathname } });
                            informativeToast('You need to sign in to buy tickets');
                        }}
                    >
                        Get Tickets
                    </Button>
                </BasicTooltip>
            ) : new Date(data.endDate) >= new Date() ? (
                data.seatMapId ? (
                    <Button className="w-full" onClick={() => navigate('seat-map')}>
                        Get Tickets
                    </Button>
                ) : (
                    <BuyDrawer eventItems={data.eventItems}>
                        <Button className="w-full">Get Tickets</Button>
                    </BuyDrawer>
                )
            ) : (
                <BasicTooltip tooltip="No availible tickets" side="bottom">
                    <Button className="w-full" disabled>
                        Get Tickets
                    </Button>
                </BasicTooltip>
            )}
            <div className="flex justify-between items-start">
                <ExpandableText text={data.description} lines={5} />
            </div>
        </div>
    );
};
