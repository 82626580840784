export interface Pagination<T> {
    data: T[];
    pageNumber: number;
    pageSize: number;
    totalElements: number;
}

export const pageSizes = {
    small: 20,
    medium: 50,
    large: 100,
    enormous: 1000,
};
