import { Button } from '@/components/ui/button';
import { FacebookIcon, Mail } from 'lucide-react';

export const Contact = (): JSX.Element => {
    return (
        <div className="flex flex-col items-center w-full text-center">
            <h1 className="text-7xl font-bold h-64 min-h-[16rem] bg-secondary w-full flex justify-center items-center">
                Contact Us
            </h1>
            <div className="flex flex-col max-w-3xl text-left gap-4 p-8 justify-center items-center">
                <p>
                    Don't doubt contacting us if you have any questions, if you would like to be a part of the
                    organization or if you're representing an organization who are interested in working with us.
                    <br />
                    <br />
                    Together we can create Lund's best ticketing system!
                </p>
                <div className="flex mt-4 gap-4">
                    <a href="mailto:info@labbs.se" target="_blank" rel="noreferrer">
                        <Button variant="outline">
                            <Mail className="h-5 w-5 mr-2" />
                            Mail
                        </Button>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100091051603614" target="_blank" rel="noreferrer">
                        <Button variant="outline">
                            <FacebookIcon className="h-5 w-5 mr-2" />
                            Facebook
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};
