import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { RootState } from '@/app/store';
import type { AuthResponse, User } from '@/types/User';

interface UserState {
    user: User | undefined;
    accessToken: string | undefined;
}

const persistConfig = {
    key: 'auth',
    storage,
};

const initialState: UserState = {
    user: undefined,
    accessToken: undefined,
};

const userSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, payloadAction: PayloadAction<User>) => {
            state.user = payloadAction.payload;
        },
        setAccessToken: (state, payloadAction: PayloadAction<AuthResponse>) => {
            state.accessToken = payloadAction.payload.token;
        },
        signOut: (state) => {
            state.user = undefined;
            state.accessToken = undefined;
        },
    },
});

export const { signOut, setUser, setAccessToken } = userSlice.actions;
export const selectCurrentUser = (state: RootState): User | undefined => state.auth.user;
export default persistReducer(persistConfig, userSlice.reducer);
