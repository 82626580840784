import { CenteredSpinner } from '@/components/ui/spinner';
import { pageSizes } from '@/types/Pagination';
import { useGetOrganizationsQuery } from '@/services/organizationApi';
import AdminOrganizationCard from '@/components/Organizations/AdminOrganizationCard';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { PageHeader } from '@/components/PageHeader';

export default function Organizations(): JSX.Element {
    const navigate = useNavigate();
    const { data, isLoading } = useGetOrganizationsQuery({
        pageNumber: 0,
        pageSize: pageSizes.enormous,
    });

    if (isLoading || !data) {
        return <CenteredSpinner />;
    }

    return (
        <>
            <PageHeader>
                <Button onClick={() => navigate('/admin/organizations/create')}>
                    <Plus className="h-5 w-5 mr-2" />
                    <p>Create New Organization</p>
                </Button>
            </PageHeader>
            <div className="flex flex-col ">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 lg:max-w-7xl mx-auto">
                    {data.data.map((organization) => (
                        <AdminOrganizationCard organization={organization} key={organization.id} />
                    ))}
                </div>
            </div>
        </>
    );
}
