import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { FormInput, FormTextArea } from '@/components/ui/FormHelpers';
import { XIcon } from 'lucide-react';
import { Form } from '@/components/ui/form';
import { useSubmitJobApplicationMutation } from '@/services/jobApplicationApi';
import { errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { useForm } from 'react-hook-form';
import type { JobApplicationForm } from '@/types/JobApplication';
import { Label } from '@radix-ui/react-dropdown-menu';
import Dropzone, { type SmallFile } from '@/components/ui/Dropzone';

export const JobApplication = (): JSX.Element => {
    const [submitJobApplication] = useSubmitJobApplicationMutation();
    const [submitted, setSubmitted] = useState(false);
    const [file, setFile] = useState<SmallFile | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const form = useForm<JobApplicationForm>({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            coverLetter: '',
        },
    });

    const errors = form.formState.errors;
    const control = form.control;

    const handleSubmit = form.handleSubmit;
    const submit = handleSubmit(async (data) => {
        setIsSubmitting(true);
        let resumeBase64 = file?.data;
        if (!resumeBase64) {
            setIsSubmitting(false);
            return;
        }

        if (resumeBase64.startsWith('data:application/pdf;base64,')) {
            resumeBase64 = resumeBase64.split(',')[1] as string;
        }

        try {
            await submitJobApplication({
                ...data,
                resume: resumeBase64,
            }).unwrap();

            successToast('Application submitted successfully');
            setSubmitted(true);
        } catch (error) {
            errorToastFromCatch(error);
        } finally {
            setIsSubmitting(false);
        }
    });

    const handleRemoveFile = () => {
        setFile(null);
    };

    if (submitted) {
        return (
            <main className="w-full max-w-3xl mx-auto px-4 md:px-6 py-12 md:py-24">
                <section className="text-center space-y-6">
                    <h1 className="text-3xl font-bold tracking-tight sm:text-4xl">Thank You for Applying</h1>
                    <p className="text-gray-500 dark:text-gray-400">
                        You will get a confirmation email and we'll get back to you shortly.
                    </p>
                </section>
            </main>
        );
    }

    return (
        <div className="w-full max-w-3xl mx-auto px-4 md:px-6 py-12 md:py-24">
            <Form {...form}>
                <form onSubmit={submit}>
                    <h1 className="text-3xl font-bold tracking-tight sm:text-4xl">Work Interest</h1>
                    <span className="block h-2 w-20 bg-primary-500 rounded-full mt-2 mb-2" />
                    <p className="text-gray-500 dark:text-gray-400">
                        We welcome students from all disciplines and interests who are eager to join our team. If you
                        believe you can contribute, we'd love to hear from you.
                    </p>
                    <p className="text-gray-500 dark:text-gray-400">
                        Our team is looking for individuals interested in various roles, including sales and customer
                        relations, developers (web, mobile, backend), accounting, marketing, design, and more. We value
                        a broad range of interests.
                    </p>
                    <p className="text-gray-500 dark:text-gray-400">
                        This page serves as an expression of interest. We welcome applications from everyone, regardless
                        of experience. A desire to gain experience can be just as compelling as experience itself.
                    </p>
                    <p className="text-gray-500 dark:text-gray-400">
                        Tell us why you want to join and attach your resume. We'll see what happens.
                    </p>
                    <FormInput
                        control={control}
                        id="name"
                        type="text"
                        label="Name"
                        error={errors.name?.type === 'required' ? 'Name is required' : undefined}
                    />
                    <FormInput
                        control={control}
                        id="email"
                        type="email"
                        label="Email"
                        error={errors.email?.type === 'required' ? 'Email is required' : undefined}
                    />
                    <FormInput
                        control={control}
                        id="phone"
                        type="tel"
                        label="Phone"
                        error={errors.phone?.type === 'required' ? 'Phone is required' : undefined}
                    />
                    <FormTextArea
                        control={control}
                        id="coverLetter"
                        label="Cover Letter"
                        error={errors.coverLetter?.type === 'required' ? 'Cover Letter is required' : undefined}
                    />
                    <Label>Resume/CV</Label>
                    <Dropzone
                        text="Drop your resume here or click to upload"
                        accept={{
                            'application/pdf': ['.pdf'],
                            'application/msword': ['.doc'],
                        }}
                        maxFiles={1}
                        setFile={(f) => {
                            setFile(f);
                        }}
                    />
                    {file?.name && (
                        <div className="flex items-center justify-between p-2 border border-gray-300 rounded-md">
                            <span className="text-sm text-gray-600 dark:text-gray-400">{file?.name}</span>
                            <button
                                type="button"
                                onClick={handleRemoveFile}
                                className="text-red-500 hover:text-red-700"
                            >
                                <XIcon className="w-5 h-5" />
                            </button>
                        </div>
                    )}
                    <div className="flex justify-end mt-4">
                        <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    );
};
