import { CenteredSpinner } from '@/components/ui/spinner';
import { PaginationSelect } from '@/components/Events/PaginationSelect';
import { useEffect, useState } from 'react';
import { pageSizes } from '@/types/Pagination';
import { useSearchParams } from 'react-router-dom';
import { useMetaTags } from '@/hooks/metaDataHooks';
import { useGetOrganizationsQuery } from '@/services/organizationApi';
import OrganizationCard from '@/components/Organizations/OrganizationCard';

export const Organizations = (): JSX.Element => {
    const [search, setSearch] = useSearchParams();
    const [pagination, setPagination] = useState({
        pageNumber: search.get('pageNumber') ? Number(search.get('pageNumber')) : 1,
        pageSize: search.get('pageSize') ? Number(search.get('pageSize')) : pageSizes.medium,
    });
    useMetaTags({ title: 'Organizations' });
    const { data, isLoading } = useGetOrganizationsQuery({
        pageNumber: pagination.pageNumber - 1,
        pageSize: pagination.pageSize,
    });

    useEffect(() => {
        setSearch(
            new URLSearchParams({
                pageNumber: pagination.pageNumber.toString(),
                pageSize: pagination.pageSize.toString(),
            }),
        );
    }, [pagination]);

    const handlePageChange = (newPage: number) => {
        setPagination({ pageNumber: newPage, pageSize: pagination.pageSize });
    };

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!data) {
        return <div>Error fetching events</div>;
    }

    return (
        <div className="flex flex-col h-full w-full items-center">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 lg:max-w-7xl">
                {data.data.map((organization) => (
                    <OrganizationCard key={organization.id} organization={organization} />
                ))}
            </div>
            <div className="m-4 mt-auto">
                <PaginationSelect
                    totalPageCount={data.totalElements / pagination.pageSize}
                    currentPage={pagination.pageNumber}
                    onPageChange={handlePageChange}
                    pageSize={pagination.pageSize}
                    setPageSize={(newPageSize) => setPagination({ pageNumber: 1, pageSize: newPageSize })}
                />
            </div>
        </div>
    );
};
