import { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Calendar } from './calendar';
import { Button } from './button';
import { CalendarIcon } from 'lucide-react';
import { cn, formatTime, formatDateWithoutTime } from '@/lib/utils';
import { Input } from './input';

interface Props {
    placeholder?: string;
    date: string | undefined;
    setDate: (date: string | undefined) => void;
    className?: string;
}

export const DatePicker = ({ date, setDate, className, placeholder = 'Pick a date' }: Props): JSX.Element => {
    const [open, setOpen] = useState(false);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    className={cn('justify-start text-left font-normal', !date && 'text-muted-foreground', className)}
                    onClick={() => setOpen(!open)}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date ? formatDateWithoutTime(date) : <span>{placeholder}</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={date ? new Date(date) : undefined}
                    onSelect={(value) => {
                        setDate(value?.toISOString());
                        setOpen(false);
                    }}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    );
};

export const DateTimePicker = ({ date, setDate, className, placeholder = 'Pick a date time' }: Props): JSX.Element => {
    const [open, setOpen] = useState(false);

    function setDateWithoutTime(d: Date): string {
        const oldDate = new Date(date ?? new Date());
        const newDate = new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            oldDate?.getHours() ?? 0,
            oldDate?.getMinutes() ?? 0,
        );
        return newDate.toISOString();
    }

    function setTime(time: string): string {
        const oldDate = new Date(date ?? new Date());
        const [hours, minutes] = time.split(':').map((str) => parseInt(str, 10));
        const newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate(), hours, minutes);
        return newDate.toISOString();
    }

    return (
        <div className={cn('flex gap-2', className)}>
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        className={cn(
                            'flex-1 justify-start text-left font-normal tabular-nums focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ring-offset-background',
                            !date && 'text-muted-foreground',
                        )}
                        onClick={() => setOpen(!open)}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date ? formatDateWithoutTime(date) : <span>{placeholder}</span>}
                    </Button>
                </PopoverTrigger>

                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        mode="single"
                        selected={date ? new Date(date) : undefined}
                        onSelect={(d) => {
                            setDate(setDateWithoutTime(d ?? new Date()));
                            setOpen(false);
                        }}
                        initialFocus
                    />
                </PopoverContent>
            </Popover>
            <Input
                className="outline-none h-10 px-4 max-w-fit"
                type="time"
                value={date ? formatTime(date) : '--:--'}
                onClick={(e) => e.preventDefault()}
                onChange={(e) => {
                    setDate(setTime(e.target.value));
                }}
            />
        </div>
    );
};
