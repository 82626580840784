import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from '@/components/ui/pagination';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { pageSizes } from '@/types/Pagination';

interface PaginationSelectProps {
    currentPage: number;
    totalPageCount: number;
    onPageChange: (newPage: number) => void;
    pageSize: number;
    setPageSize: (newPageSize: number) => void;
}

export function PaginationSelect({
    currentPage,
    onPageChange,
    totalPageCount,
    pageSize,
    setPageSize,
}: PaginationSelectProps): JSX.Element {
    const pages = Array.from({ length: totalPageCount < 1 ? 1 : totalPageCount }, (_, i) => i + 1);

    const handlePageChange = (page: number) => {
        if (page > 0 && page <= (totalPageCount ?? 0)) {
            onPageChange(page);
        }
    };

    return (
        <div className="flex items-center space-x-1">
            <Select
                value={`${pageSize}`}
                onValueChange={(value) => {
                    setPageSize(Number(value));
                }}
            >
                <SelectTrigger className="h-8 w-[70px]">
                    <SelectValue placeholder={pageSize} />
                </SelectTrigger>
                <SelectContent side="top">
                    {Object.values(pageSizes).map((ps) => (
                        <SelectItem key={ps} value={`${ps}`}>
                            {ps}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
            <Pagination>
                <PaginationContent>
                    <PaginationItem>
                        <PaginationPrevious onClick={() => handlePageChange(currentPage - 1)} />
                    </PaginationItem>
                    {pages.map((page) => (
                        <PaginationItem key={page}>
                            <PaginationLink isActive={page === currentPage} onClick={() => handlePageChange(page)}>
                                {page}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem>
                        <PaginationNext onClick={() => handlePageChange(currentPage + 1)} />
                    </PaginationItem>
                </PaginationContent>
            </Pagination>
        </div>
    );
}
