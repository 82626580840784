import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { type SmallReferralCampaign } from '@/types/Referral';
import { DeleteDialog } from '../ui/DeleteDialog';
import { Button } from '../ui/button';
import { ExpandableText } from '../ui/ExpandableText';
// import { ReferralSheet } from './ReferralSheet';
import { useDeleteReferralCampaignMutation } from '@/services/referralApi';

interface Props {
    campaign: SmallReferralCampaign;
}

export default function ReferralCard({ campaign }: Props): JSX.Element {
    const [deleteCampaign, { isLoading: isDeleting }] = useDeleteReferralCampaignMutation();
    const navigate = useNavigate();

    return (
        <Card
            className="flex flex-col hover:bg-accent hover:cursor-pointer h-full"
            onClick={() => navigate(`/referrals/${campaign.id}`)}
        >
            <CardHeader>
                <CardTitle>{campaign.title}</CardTitle>
            </CardHeader>
            <CardContent className="pb-4 h-full">
                <ExpandableText text={campaign.description || 'No description available.'} lines={50} />
            </CardContent>
            <CardFooter className="justify-end gap-4">
                <DeleteDialog deleteItem={() => deleteCampaign(campaign.id).unwrap()} isLoading={isDeleting}>
                    <Button variant="destructive">Delete</Button>
                </DeleteDialog>
            </CardFooter>
        </Card>
    );
}
