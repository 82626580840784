import { MoreHorizontal } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useState } from 'react';
import { successToast } from '@/components/ui/use-toast';
import type { UserResponse } from '@/types/User';
import EditUserForm from '@/components/Users/EditUserForm';

interface UserActionsProps {
    user: UserResponse;
}

const UserActions: React.FC<UserActionsProps> = ({ user }) => {
    const [showEditForm, setShowEditForm] = useState(false);
    const closeEdit = () => setShowEditForm(false);
    return (
        <>
            {showEditForm && <EditUserForm user={user} onConfirm={closeEdit} onCancel={closeEdit} />}
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="icon">
                        <span className="sr-only">Open menu</span>
                        <MoreHorizontal className="h-4 w-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuLabel>Actions</DropdownMenuLabel>

                    <DropdownMenuItem
                        onClick={() => {
                            navigator.clipboard.writeText(user.id);
                            successToast('Copied user ID to clipboard');
                        }}
                    >
                        Copy user ID
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => setShowEditForm(true)}>Edit</DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
};

export default UserActions;
