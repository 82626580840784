import { LocateFixedIcon } from 'lucide-react';

import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import type { PlaceEdit, PlaceInput, PlaceResponse } from '@/types/Place';
import { useDeletePlaceMutation, useEditPlaceMutation } from '@/services/placesApi';

import { Button } from '../ui/button';
import { DeleteDialog } from '../ui/DeleteDialog';
import { PlaceSheet } from './PlaceSheet';

interface Props {
    place: PlaceResponse;
    setCoordinates: (coordinates: [number, number]) => void;
}

export default function PlaceCard({ place, setCoordinates }: Props): JSX.Element {
    const [deletePlace, deletePlaceState] = useDeletePlaceMutation();
    const [putPlace, putPlaceState] = useEditPlaceMutation();

    const onSubmit = (data: PlaceInput) => {
        if (!place.id) {
            return Promise.reject();
        }
        const filteredData: PlaceEdit = {
            ...data,
            id: place.id,
        };
        return putPlace(filteredData).unwrap();
    };

    return (
        <Card key={place.id} className="flex flex-col justify-between">
            <CardHeader>
                <CardTitle>{place.name}</CardTitle>
                <CardDescription>{place.address}</CardDescription>
            </CardHeader>
            <CardFooter className="justify-between items-end space-x-2">
                <div className="flex flex-row gap-4">
                    <DeleteDialog
                        isLoading={deletePlaceState.isLoading}
                        deleteItem={() => deletePlace(place.id).unwrap()}
                        successMessage="Place deleted successfully"
                    >
                        <Button variant="destructive">Delete</Button>
                    </DeleteDialog>
                    <PlaceSheet place={place} isLoading={putPlaceState.isLoading} onSubmit={onSubmit}>
                        <Button variant="secondary">Edit</Button>
                    </PlaceSheet>
                </div>
                <Button variant="secondary" onClick={() => setCoordinates([place.latitude, place.longitude])}>
                    <LocateFixedIcon className="h-4 w-4 mr-2" />
                    Location
                </Button>
            </CardFooter>
        </Card>
    );
}
