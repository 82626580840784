import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useState } from 'react';
import { cropImage } from '@/lib/cropUtils';
import Cropper, { type Area, type Point } from 'react-easy-crop';
import { Button } from '@/components/ui/button';
import { EVENT_BANNER_ASPECT_RATIO } from '@/lib/utils';

interface CropperDialogProps {
    onCropped: (image: Promise<string>) => void;
    image: string | null;
    setImage: (image: string | null) => void;
    aspect?: number;
}

export default function CropperDialog({ onCropped, image, setImage, aspect }: CropperDialogProps): JSX.Element {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({ width: 0, height: 0, x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    if (image) {
        return (
            <Dialog open={image !== null} onOpenChange={() => setImage(null)}>
                <DialogContent className="max-h-[90vh] max-w-[90vw]">
                    <DialogHeader>
                        <DialogTitle>Crop banner</DialogTitle>
                    </DialogHeader>
                    <div className="w-full h-[70vh] relative flex justify-center">
                        <Cropper
                            image={image}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect ?? EVENT_BANNER_ASPECT_RATIO}
                            onCropChange={setCrop}
                            onCropComplete={(_, areaPixels) => setCroppedAreaPixels(areaPixels)}
                            onZoomChange={setZoom}
                        />
                    </div>
                    <DialogFooter>
                        <Button
                            className="w-full"
                            size="lg"
                            onClick={() => onCropped(cropImage(image, croppedAreaPixels))}
                        >
                            Crop
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        );
    } else {
        return <></>;
    }
}
