import type { CreatePlaceType, PlaceEdit, PlaceResponse } from '@/types/Place';

import { api } from './rootApi';

export const placesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPlaces: builder.query<PlaceResponse[], void>({
            query: () => 'places',
            providesTags: () => ['Place'],
        }),
        createPlace: builder.mutation<PlaceResponse, CreatePlaceType>({
            query: (body) => ({
                url: 'places',
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['Place'],
        }),
        editPlace: builder.mutation<PlaceResponse, PlaceEdit>({
            query: ({ id, ...body }) => ({
                url: `places/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: () => ['Place'],
        }),
        deletePlace: builder.mutation<void, string>({
            query: (id) => ({
                url: `places/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: () => ['Place', 'Event'],
        }),
    }),
});

export const { useGetPlacesQuery, useCreatePlaceMutation, useDeletePlaceMutation, useEditPlaceMutation } = placesApi;
