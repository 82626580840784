import { Card, CardFooter, CardHeader } from '@/components/ui/card';
import type { EventItemInput } from '@/types/EventItem';
import { Button } from '../ui/button';
import { PlusIcon, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { EventItemModal } from './EventItemModal';
import { formatPrice } from '@/lib/utils';

interface Props {
    eventItems: EventItemInput[];
    setEventItem: (EventItemInput: EventItemInput, index: number | undefined) => void;
    removeEventItem?: (index: number) => void;
    publishDate?: string;
    endDate?: string;
}

export const EventItems: React.FC<Props> = (props) => {
    const { eventItems, setEventItem, removeEventItem, publishDate, endDate } = props;
    const [modalEventItemIndex, setModalEventItemIndex] = useState<number | undefined | 'new'>(undefined);

    const openModal = (index: number | 'new') => {
        setModalEventItemIndex(index);
    };
    const closeModal = () => {
        setModalEventItemIndex(undefined);
    };

    return (
        <div className="flex gap-4 flex-wrap">
            {eventItems.map((item, i) => (
                <Card
                    key={item.expirationDate + '-' + item.price + '-' + item.name}
                    className="flex flex-col h-[300px] w-[190px]"
                >
                    <CardHeader className="flex-1 items-center justify-center text-center">
                        <p className="font-medium text-xl">{item.name}</p>
                        <p>
                            {item.price
                                ? item.hasMinimumPrice
                                    ? `Min Price: ${formatPrice(item.price)} SEK`
                                    : `Price: ${formatPrice(item.price)} SEK`
                                : 'Price: Free'}
                        </p>
                        <p>{'Buy limit: ' + (item.limit ? item.limit + 'st' : 'No limit')}</p>
                        {item.lastSwitchDate && <p>{'Can be switched'}</p>}
                    </CardHeader>
                    <CardFooter className="justify-end gap-4">
                        {removeEventItem && (
                            <Button size="sm" variant="destructive" onClick={() => removeEventItem(i)}>
                                <Trash2 size="1em" />
                            </Button>
                        )}
                        <Button size="sm" variant="secondary" onClick={() => openModal(i)}>
                            Edit
                        </Button>
                    </CardFooter>
                </Card>
            ))}
            <Card
                className="flex flex-col justify-start relative items-center hover:bg-accent cursor-pointer h-[300px] w-[200px] pt-12"
                onClick={() => openModal('new')}
            >
                <CardHeader className="items-center justify-center text-center">
                    <p className="font-medium text-xl">Add ticket type</p>
                </CardHeader>
                <PlusIcon
                    className="h-7 w-7 absolute 
                top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
            </Card>
            {modalEventItemIndex !== undefined && (
                <EventItemModal
                    onCancel={closeModal}
                    eventItem={modalEventItemIndex === 'new' ? undefined : eventItems[modalEventItemIndex]}
                    onConfirm={(eventItem) => {
                        setEventItem(eventItem, modalEventItemIndex === 'new' ? undefined : modalEventItemIndex);
                        closeModal();
                    }}
                    publishDate={publishDate}
                    endDate={endDate}
                />
            )}
        </div>
    );
};
