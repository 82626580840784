import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useNavigate } from 'react-router-dom';
import { DeleteDialog } from '../ui/DeleteDialog';
import { Button } from '../ui/button';
import type { SeatMapSmall } from '@/types/SeatMap';
import { useDeleteSeatMapMutation } from '@/services/seatMapApi';

interface Props {
    seatMap: SeatMapSmall;
}

export default function SeatMapCard({ seatMap }: Props): JSX.Element {
    const [deleteSeatMap, { isLoading }] = useDeleteSeatMapMutation();
    const navigate = useNavigate();
    return (
        <Card
            className="flex flex-col hover:bg-accent hover:cursor-pointer"
            onClick={() => {
                navigate(`/admin/seat-maps/${seatMap.id}`);
            }}
        >
            <CardHeader>
                <CardTitle>{seatMap.name}</CardTitle>
                <CardDescription>{seatMap.description}</CardDescription>
            </CardHeader>
            <CardFooter className="justify-end">
                <DeleteDialog isLoading={isLoading} deleteItem={() => deleteSeatMap(seatMap.id).unwrap()}>
                    <Button variant="destructive">Delete</Button>
                </DeleteDialog>
            </CardFooter>
        </Card>
    );
}
