import type { CellContext, ColumnDef, Row } from '@tanstack/react-table';

import { DataTableColumnHeader } from '@/components/dataTable/DataTableColumnHeader';
import type { TicketSmall } from '@/types/Ticket';

export const columns: ColumnDef<TicketSmall>[] = [
    {
        accessorKey: 'id',
        header: ({ column }) => <DataTableColumnHeader column={column} title="ID" />,
        enableSorting: false,
    },
    {
        accessorKey: 'eventTitle',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Event" />,
        cell: (cellContext: CellContext<TicketSmall, unknown>): JSX.Element => {
            return (
                <div className="flex space-x-2">
                    <span className="max-w-[220px] truncate font-medium">{cellContext.row.getValue('eventTitle')}</span>
                </div>
            );
        },
    },
    {
        accessorKey: 'status',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
        filterFn: (row: Row<TicketSmall>, id: string, value: TicketSmall[]): boolean => {
            return value.includes(row.getValue(id));
        },
    },
    {
        accessorKey: 'userId',
        header: ({ column }) => <DataTableColumnHeader column={column} title="User" />,
        enableSorting: false,
    },
];
