import type { Organization } from './Organization';

export interface Role {
    id: string;
    organizationId: string;
    organizationName: string;
    roleType: RoleType;
    title: string;
}

export interface RoleInput {
    description: string;
    organizationId: string;
    roleType: RoleType;
    title: string;
}

export interface RoleResponse {
    description: string;
    id: string;
    organization: Organization;
    roleType: RoleType;
    title: string;
}

export interface RoleFormInput {
    description: string;
    title: string;
}

export interface EditRole {
    id: string;
    description: string;
    title: string;
}

export interface InviteId {
    inviteId: string;
}

export interface InviteMessage {
    message: string;
}

export const roleResponseToRoleFormInput = (roleResponse: RoleResponse): RoleFormInput => ({
    description: roleResponse.description,
    title: roleResponse.title,
});

export enum RoleType {
    ADMIN = 'ADMIN',
    REPRESENTATIVE = 'REPRESENTATIVE',
    CUSTOM = 'CUSTOM',
}

export interface RoleFilter {
    pageNumber?: number;
    pageSize?: number;
    organizationId?: string;
}
