import type { ColumnFiltersState, PaginationState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { columns } from '@/components/dataTable/columns/TicketsColumns';
import { DataTable } from '@/components/dataTable/DataTable';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useGetTicketsQuery } from '@/services/ticketsApi';
import { pageSizes } from '@/types/Pagination';
import { TicketActions } from '@/components/dataTable/columns/TicketActions';
import type { Status } from '@/types/Status';
import type { TicketSmall } from '@/types/Ticket';
import { useSelector } from 'react-redux';
import { selectCurrentOrganization } from '@/features/currentOrganization/currentOrganizationSlice';

export default function AdminTickets(): JSX.Element {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: pageSizes.small,
    });
    const [pageCount, setPageCount] = useState(1);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const orgState = useSelector(selectCurrentOrganization);
    const { data, isLoading } = useGetTicketsQuery(
        {
            pageSize: pagination.pageSize,
            pageNumber: pagination.pageIndex,
            status: columnFilters.find((filter) => filter.id === 'status')?.value as Status[],
            organizationId: orgState?.currentOrganization?.id,
        },
        {
            skip: !orgState?.currentOrganization,
        },
    );

    useEffect(() => {
        if (data) {
            setPageCount(Math.ceil(data.totalElements / pagination.pageSize));
        }
    }, [data, pagination]);

    if (isLoading || !data) {
        return <CenteredSpinner />;
    }

    return (
        <>
            <h1 className="text-2xl font-semibold p-4 pb-0">Tickets</h1>
            <DataTable
                columns={columns}
                data={data}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                pageCount={pageCount}
                rowActions={(ticket: TicketSmall) => <TicketActions ticket={ticket} />}
            />
        </>
    );
}
