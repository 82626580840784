import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/apiHooks';
import { useGetCartByUserIdQuery } from '@/services/cartApi';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

export default function OrderComplete(): JSX.Element {
    const [params] = useSearchParams();
    const { user } = useAuth();
    const [fetchCart] = useGetCartByUserIdQuery();

    useEffect(() => {
        fetchCart(user?.id ?? '');
    }, [user, params, fetchCart]);

    return (
        <div className="flex justify-center text-center mt-4 md:mt-8 mx-4">
            <div className="w-full lg:max-w-5xl">
                {params.get('redirect_status') === 'succeeded' && (
                    <div className="space-y-2">
                        <h1 className="text-4xl font-semibold">Order Complete</h1>
                        <p className="text-xl">Thank you for your purchase! Your order is now complete.</p>
                        <p className="text-lg">You can check your email for the receipt.</p>
                        <div>
                            <Link to="/tickets">
                                <Button size="lg">Go to tickets</Button>
                            </Link>
                        </div>
                    </div>
                )}
                {params.get('redirect_status') === 'failed' && (
                    <div className="space-y-2">
                        <h1 className="text-4xl font-semibold">Order failed during payment</h1>
                        <p className="text-lg">Please retry the purchase.</p>
                        <div>
                            <Link to="/cart">
                                <Button size="lg">Go to cart</Button>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
