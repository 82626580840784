import { useAuth, useIsAdmin, useIsRepresentative } from '@/hooks/apiHooks';

import { ModeToggle } from './ModeToggle';
import UserMenu from './UserMenu';
import { NavLink } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { useState } from 'react';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from './ui/sheet';
import { Button } from './ui/button';
import { MenuIcon } from 'lucide-react';
import CartMenu from './CartMenu';
export const Header = (): JSX.Element => {
    return (
        <header className="w-full bg-background z-10 h-12 border-b flex justify-between items-center px-4">
            <NavLink to="/" className="hover:underline text-lg font-semibold sm:mr-8">
                LABB
            </NavLink>
            <div className="sm:hidden">
                <MobileMenu />
            </div>
            <div className="items-center gap-4 h-full hidden sm:flex">
                <WebMenu />
            </div>
        </header>
    );
};

const WebMenu = (): JSX.Element => {
    const { isAuthenticated } = useAuth();
    const isRepresentative = useIsRepresentative();
    const isAdmin = useIsAdmin();
    return (
        <>
            <NavLink to="/about" end className={({ isActive }) => cn('hover:underline', isActive && 'underline')}>
                About
            </NavLink>
            <NavLink to="/events" end className={({ isActive }) => cn('hover:underline', isActive && 'underline')}>
                Events
            </NavLink>
            {!isAuthenticated && (
                <>
                    <NavLink to="/sign-in" className={({ isActive }) => cn('hover:underline', isActive && 'underline')}>
                        Sign In
                    </NavLink>
                    <NavLink to="/sign-up" className={({ isActive }) => cn('hover:underline', isActive && 'underline')}>
                        Sign Up
                    </NavLink>
                </>
            )}
            <ModeToggle />
            {isAuthenticated && (isRepresentative || isAdmin) && (
                <NavLink to="/admin" className={({ isActive }) => cn('hover:underline', isActive && 'underline')}>
                    Admin
                </NavLink>
            )}
            {isAuthenticated && (
                <>
                    <CartMenu />
                    <UserMenu />
                </>
            )}
        </>
    );
};

const MobileMenu = (): JSX.Element => {
    const { isAuthenticated } = useAuth();
    const isRepresentative = useIsRepresentative();
    const [open, setOpen] = useState(false);
    return (
        <div className="flex">
            {isAuthenticated ? (
                <>
                    <CartMenu />
                    <UserMenu />
                </>
            ) : (
                <div className="flex items-center gap-4 mr-4">
                    <NavLink to="/sign-in" className="hover:underline">
                        Sign In
                    </NavLink>
                    <NavLink to="/sign-up" className="hover:underline">
                        Sign Up
                    </NavLink>
                </div>
            )}
            <Sheet open={open} onOpenChange={setOpen}>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <SheetTrigger asChild>
                        <Button variant="ghost" size="icon">
                            <MenuIcon size={24} />
                        </Button>
                    </SheetTrigger>
                </div>
                <SheetContent className="flex flex-col gap-4">
                    <SheetHeader>
                        <SheetTitle>
                            <NavLink to="/" className="hover:underline text-lg font-semibold">
                                Home
                            </NavLink>
                        </SheetTitle>
                    </SheetHeader>
                    <NavLink
                        to="/about"
                        end
                        className={({ isActive }) => cn('hover:underline p-2', isActive && 'underline')}
                        onClick={() => setOpen(false)}
                    >
                        About
                    </NavLink>
                    <NavLink
                        to="/events"
                        end
                        className={({ isActive }) => cn('hover:underline p-2', isActive && 'underline')}
                        onClick={() => setOpen(false)}
                    >
                        Events
                    </NavLink>
                    {isRepresentative && (
                        <NavLink
                            to="/admin"
                            className={({ isActive }) => cn('hover:underline p-2', isActive && 'underline')}
                            onClick={() => setOpen(false)}
                        >
                            Admin
                        </NavLink>
                    )}
                    <NavLink
                        to="/organizations"
                        end
                        className={({ isActive }) =>
                            cn('hover:underline p-1 whitespace-nowrap', isActive && 'underline')
                        }
                        onClick={() => setOpen(false)}
                    >
                        Organizations
                    </NavLink>
                    <NavLink
                        to="/privacy-policy"
                        className={({ isActive }) =>
                            cn('hover:underline p-1 whitespace-nowrap', isActive && 'underline')
                        }
                        onClick={() => setOpen(false)}
                    >
                        Privacy Policy
                    </NavLink>
                    <NavLink
                        to="/contact"
                        className={({ isActive }) =>
                            cn('hover:underline p-1 whitespace-nowrap', isActive && 'underline')
                        }
                        onClick={() => setOpen(false)}
                    >
                        Contact Us
                    </NavLink>
                    <NavLink
                        to="/work-with-us"
                        className={({ isActive }) =>
                            cn('hover:underline p-1 whitespace-nowrap', isActive && 'underline')
                        }
                        onClick={() => setOpen(false)}
                    >
                        Work With Us
                    </NavLink>
                </SheetContent>
            </Sheet>
        </div>
    );
};
