import {
    type BaseQueryFn,
    type FetchArgs,
    fetchBaseQuery,
    type FetchBaseQueryError,
    type BaseQueryApi,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import type { RootState } from '@/app/store';
import { isAuthResponse } from '@/types/User';
import { setAccessToken, signOut } from '@/features/user/userSlice';
import type { LabbError } from '@/types/Error';

const baseUrl = `${import.meta.env.VITE_BACKEND_URL}/`;

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState;
        const token = state.auth.accessToken;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const updatingTokens = new Mutex();

export const fetchBase: BaseQueryFn<string | FetchArgs, unknown, LabbError | FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    // If someone is in the process of updating the tokens, wait for them to finish
    await updatingTokens.waitForUnlock();
    const result = await baseQuery(args, api, extraOptions);
    if (result.error?.status !== 401) {
        // Our token is still valid, so we can return the result
        return result;
    }

    await updateTokens(api, extraOptions);
    const newResult = await baseQuery(args, api, extraOptions);
    return newResult;
};

/**
 * @throws {FetchBaseQueryError} if the there is an error with the refresh
 */
const updateTokens = async (api: BaseQueryApi, extraOptions: RequestInit) => {
    if (updatingTokens.isLocked()) {
        // Someone else is already updating the tokens, wait for them to finish
        await updatingTokens.waitForUnlock();
        return;
    }
    const release = await updatingTokens.acquire();
    try {
        const refreshResult = await baseQuery(
            {
                method: 'POST',
                url: '/auth/refresh',
                credentials: 'include',
            },
            api,
            extraOptions,
        );

        if (isAuthResponse(refreshResult.data) && !refreshResult.error) {
            api.dispatch(setAccessToken(refreshResult.data));
        } else {
            api.dispatch(signOut());
            if (refreshResult.error) {
                throw refreshResult.error;
            }
            throw refreshResult.data;
        }
    } finally {
        release();
    }
};
