import type { PlaceFormInput } from '@/types/Place';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const MAP_URL = 'https://nominatim.openstreetmap.org/';

export interface Cordinates {
    lon: number;
    lat: number;
}

export const mapApi = createApi({
    reducerPath: 'mapApi',
    baseQuery: fetchBaseQuery({ baseUrl: MAP_URL }),
    endpoints: (builder) => ({
        getCoordinates: builder.query<Cordinates[], PlaceFormInput>({
            query: (mapInput) =>
                `search.php?street=${encodeURIComponent(mapInput.address)}&city=${encodeURIComponent(
                    mapInput.city,
                )}&format=json`,
        }),
    }),
});

export const { useLazyGetCoordinatesQuery } = mapApi;
