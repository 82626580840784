import { PlaceSheet } from './PlaceSheet';
import { useCreatePlaceMutation } from '@/services/placesApi';
import { useCurrentOrganization } from '@/hooks/apiHooks';
import { errorToast } from '../ui/use-toast';
import type { CreatePlaceType, PlaceInput } from '@/types/Place';
import type { PropsWithChildren } from 'react';

export const CreatePlace: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
    const [postPlace, postEventPlaceState] = useCreatePlaceMutation();
    const organization = useCurrentOrganization();

    const onSubmit = (data: PlaceInput) => {
        if (!organization?.id) {
            errorToast('No organization id found');
            return Promise.reject();
        }
        const filteredData: CreatePlaceType = {
            ...data,
            organizationId: organization?.id,
        };
        return postPlace(filteredData).unwrap();
    };
    return (
        <PlaceSheet onSubmit={onSubmit} isLoading={postEventPlaceState.isLoading}>
            {children}
        </PlaceSheet>
    );
};
