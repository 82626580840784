import type { Ticket } from '@/types/Ticket';
import { Card } from '@/components/ui/card';
import { formatDate, formatPrice } from '@/lib/utils';
import { Drawer, DrawerContent, DrawerTrigger } from '@/components/ui/drawer';
import QRCode from 'qrcode.react';
import { CheckIcon } from 'lucide-react';
import useScreenSize from '@/hooks/useScreenSize';

interface TicketCardProps {
    ticket: Ticket;
}

export default function TicketCard({ ticket }: TicketCardProps): JSX.Element {
    const { isMobile } = useScreenSize();

    return (
        <Drawer>
            <DrawerTrigger className="w-full text-left">
                <Card className="flex flex-col justify-between shadow-none p-4 h-32">
                    <div className="flex justify-between">
                        <h2 className="text-xl font-semibold">{ticket.event.title}</h2>
                        {ticket.status === 'CONSUMED' && <CheckIcon />}
                    </div>
                    <div>
                        <p>{ticket.organization.name}</p>
                        <div className="flex space-x-2 text-sm">
                            <p>{formatDate(ticket.event.startDate)}</p>
                            <p>-</p>
                            <p>{formatDate(ticket.event.endDate)}</p>
                        </div>
                    </div>
                </Card>
            </DrawerTrigger>
            <DrawerContent>
                <div className="flex flex-col text-center h-full justify-center items-center space-y-8">
                    <div className="gap-2">
                        <h2 className="text-4xl font-semibold">{ticket.event.title}</h2>
                        <p className="text-lg">{ticket.eventItem.name}</p>
                        <p>{formatPrice(ticket.actualPrice)} SEK</p>
                    </div>
                    {ticket.status === 'CONSUMED' && <p className="text-2xl">Consumed</p>}
                    <QRCode size={isMobile ? 140 : 256} value={ticket.id} />
                    <div className="">
                        {ticket.seat.row && ticket.seat.seatNumber && ticket.seat.category && (
                            <div className="mb-4 text-left">
                                <p className="font-medium text-xl">
                                    Seat: {ticket.seat.seatNumber} row {ticket.seat.row}
                                </p>
                                <p className="text-lg">{ticket.seat.category.name}</p>
                            </div>
                        )}
                        <p className="font-medium">{ticket.organization.name}</p>
                        <div className="flex space-x-2">
                            <p>{formatDate(ticket.event.startDate)}</p>
                            <p>-</p>
                            <p>{formatDate(ticket.event.endDate)}</p>
                        </div>
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
    );
}
