import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

export const Home = (): JSX.Element => {
    return (
        <div className="flex flex-col items-center justify-center w-full h-full text-center px-2 sm:px-4">
            <h1 className="text-9xl font-bold">LABB</h1>
            <p className="my-1">Tickets for students, by students.</p>
            <Link to="/events">
                <Button className="text-xl" size="xl">
                    See events
                </Button>
            </Link>
        </div>
    );
};
