import {
    type ReferralCampaign,
    type ReferralCampaignEdit,
    type ReferralCampaignFilter,
    type ReferralCampaignInput,
    type ReferralCampaignSalesDataOverTime,
    type ReferralCampaignStats,
    type ReferralLink,
    type ReferralLinkInput,
    type SmallReferralCampaign,
} from '@/types/Referral';
import { api } from './rootApi';

export const referralApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllReferrals: builder.query<SmallReferralCampaign[], ReferralCampaignFilter>({
            query: ({ eventId, organizationId }) =>
                `/referral?${eventId ? `?eventId=${eventId}` : ''}${
                    organizationId ? `&organizationId=${organizationId}` : ''
                }`,
            providesTags: ['Referral'],
        }),
        getReferralById: builder.query<ReferralCampaign, string>({
            query: (id) => `/referral/${id}`,
            providesTags: ['Referral'],
        }),
        createReferralCampaign: builder.mutation<ReferralCampaign, ReferralCampaignInput>({
            query: (campaign) => ({
                url: '/referral',
                method: 'POST',
                body: campaign,
            }),
            invalidatesTags: ['Referral'],
        }),
        deleteReferralCampaign: builder.mutation<void, string>({
            query: (id) => ({
                url: `/referral/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Referral'],
        }),
        editReferralCampaign: builder.mutation<ReferralCampaign, ReferralCampaignEdit>({
            query: ({ id, ...body }) => ({
                url: `/referral/${id}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['Referral'],
        }),
        getReferralStats: builder.query<ReferralCampaignStats[], string>({
            query: (id) => `/referral/${id}/stats`,
            providesTags: ['Referral'],
        }),
        getSalesOverTime: builder.query<ReferralCampaignSalesDataOverTime[], string>({
            query: (id) => `/referral/${id}/sales-over-time`,
            providesTags: ['Referral'],
        }),
        createReferralLink: builder.mutation<ReferralLink, ReferralLinkInput>({
            query: (input) => ({
                url: '/referral/create-link',
                method: 'POST',
                body: input,
            }),
            invalidatesTags: ['Referral'],
        }),
    }),
});

export const {
    useGetAllReferralsQuery,
    useGetReferralByIdQuery,
    useCreateReferralCampaignMutation,
    useDeleteReferralCampaignMutation,
    useEditReferralCampaignMutation,
    useGetReferralStatsQuery,
    useGetSalesOverTimeQuery,
    useCreateReferralLinkMutation,
} = referralApi;
