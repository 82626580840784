import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';
import { useEditEventMutation, useGetEventByIdQuery, useUploadBannerMutation } from '@/services/eventsApi';
import { Stepper, Step } from '@/components/ui/Stepper';
import { EventDetailsForm } from '@/components/Events/EventDetailsForm';
import { eventToEventInput, type EventInput, type Event, type EditEventType } from '@/types/Event';
import { useForm } from 'react-hook-form';
import {
    EVENT_BANNER_ASPECT_RATIO,
    EVENT_BANNER_PLACEHOLDER_BLURHASH,
    EVENT_BANNER_PLACEHOLDER_SRC,
    formatDateTimeForForm,
    getEventBannerSrc,
    toISO,
} from '@/lib/utils';
import { Button } from '@/components/ui/button';
import Dropzone from '@/components/ui/Dropzone';
import CropperDialog from '@/components/ui/CropperDialog';
import { EventPreview } from '@/components/Events/EventPreview';
import { errorToast, errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { PreviewImage } from '@/components/PreviewImage';
import type { OptionType } from '@/components/ui/multi-select';

interface Props {
    event: Event;
}
const EditEventWithData = ({ event }: Props): JSX.Element => {
    const [currentStep, setCurrentStep] = useState(0);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [image, setImage] = useState<string | null>(null);
    const [eventInput, setEventInput] = useState(eventToEventInput(event));
    const [editEvent, editEventState] = useEditEventMutation();
    const [uploadBanner, uploadBannerState] = useUploadBannerMutation();
    const [hasPublishDate, setHasPublishDate] = useState(!!eventInput?.publishDate);
    const [isDraft, setIsDraft] = useState(eventInput?.publishDate === null);
    const [hasSeatMap, setHasSeatMap] = useState(eventInput?.seatMapId !== undefined);
    const responsibleUsersState = useState<OptionType[]>(
        event.responsibleUsers.map((user) => ({ label: user.firstName + ' ' + user.lastName, value: user.id })),
    );
    const [responsibleUsers] = responsibleUsersState;
    const navigate = useNavigate();

    const defaultValues = {
        description: eventInput?.description,
        endDate: formatDateTimeForForm(eventInput?.endDate),
        placeId: eventInput?.placeId,
        publishDate: formatDateTimeForForm(eventInput?.publishDate),
        seatCount: eventInput?.seatCount,
        startDate: formatDateTimeForForm(eventInput?.startDate),
        title: eventInput?.title,
        responsibleUserIds: eventInput?.responsibleUserIds,
    };

    const handleEventDetailsSubmit = (data: EventInput) => {
        const filteredData = {
            ...data,
            endDate: toISO(data.endDate),
            publishDate: isDraft ? null : hasPublishDate ? toISO(data.publishDate) : new Date().toISOString(),
            startDate: toISO(data.startDate),
            responsibleUserIds: responsibleUsers.map((u) => u.value),
        };
        if (isDraft) {
            setHasPublishDate(false);
        }
        setEventInput(filteredData);
    };

    const submitEventDetails = async () => {
        await form.trigger();
        if (form.formState.isValid) {
            form.handleSubmit(handleEventDetailsSubmit)();
        }
    };

    function onCropped(imagePromise: Promise<string>) {
        imagePromise.then((img) => {
            setCroppedImage(img);
            setImage(null);
        });
    }

    const form = useForm<EventInput>({
        defaultValues: defaultValues,
    });

    const onPublishChanges = async () => {
        await submitEventDetails();
        if (!eventInput || !form.formState.isValid) {
            errorToast('All fields must be filled out');
            setCurrentStep(0);
            return;
        }
        const editEventData: EditEventType = {
            id: event.id,
            description: eventInput.description,
            endDate: eventInput.endDate,
            publishDate: eventInput.publishDate,
            startDate: eventInput.startDate,
            title: eventInput.title,
            responsibleUserIds: eventInput.responsibleUserIds,
        };
        const bannerPromise = croppedImage
            ? uploadBanner({ id: event.id, banner: croppedImage }).unwrap()
            : Promise.resolve();
        Promise.all([editEvent(editEventData).unwrap(), bannerPromise])
            .then(() => {
                successToast('Event edited');
                navigate(`/admin/events/${event.id}`);
            })
            .catch(errorToastFromCatch);
    };

    return (
        <div className="w-full relative bg-primary-foreground h-full">
            <Stepper currentStep={currentStep} setCurrentStep={setCurrentStep} onClick={submitEventDetails}>
                <Step
                    name="Edit event details"
                    headerItems={
                        <Button
                            onClick={() => {
                                setCurrentStep(1);
                                submitEventDetails();
                            }}
                        >
                            Next
                        </Button>
                    }
                >
                    <EventDetailsForm
                        form={form}
                        setIsDraft={setIsDraft}
                        isDraft={isDraft}
                        setHasPublishDate={setHasPublishDate}
                        hasPublishDate={hasPublishDate}
                        selectedUsersState={responsibleUsersState}
                        isEdit
                        hasSeatMap={hasSeatMap}
                        setHasSeatMap={setHasSeatMap}
                    />
                </Step>
                <Step name="Edit banner" headerItems={<Button onClick={() => setCurrentStep(2)}>Next</Button>}>
                    <div className="w-full flex justify-center p-6">
                        <div className="w-full max-w-[820px] flex flex-col space-y-4">
                            {croppedImage ? (
                                <img src={croppedImage} className="w-full h-auto rounded-lg" />
                            ) : (
                                <PreviewImage
                                    url={
                                        event.bannerBlurhash
                                            ? getEventBannerSrc(event.id)
                                            : EVENT_BANNER_PLACEHOLDER_SRC
                                    }
                                    blurHash={event.bannerBlurhash ?? EVENT_BANNER_PLACEHOLDER_BLURHASH}
                                    className="w-full rounded-lg"
                                    aspectRatio={EVENT_BANNER_ASPECT_RATIO}
                                />
                            )}
                            <Dropzone
                                text="Drag 'n' drop the banner, or click to edit banner"
                                setFile={(f) => setImage(f.data)}
                            />
                        </div>
                        <CropperDialog onCropped={onCropped} image={image} setImage={setImage} />
                    </div>
                </Step>
                <Step
                    name="Publish changes"
                    headerItems={
                        <Button
                            onClick={onPublishChanges}
                            isLoading={editEventState.isLoading || uploadBannerState.isLoading}
                        >
                            Publish changes
                        </Button>
                    }
                >
                    <div className="w-full flex justify-center p-6">
                        <EventPreview eventInput={eventInput} croppedImage={croppedImage} event={event} />
                    </div>
                </Step>
            </Stepper>
        </div>
    );
};

// This is here to make sure that the event is loaded before the component is rendered
// This is needed because useForm hook cannot be called conditionally and need the default values
export const EditEvent = (): JSX.Element => {
    const { eventId } = useParams() as { eventId: string };
    const { data, isLoading } = useGetEventByIdQuery(eventId);
    if (isLoading || !data) {
        return (
            <div className="flex justify-center items-center h-full w-full">
                <Spinner size="lg" />
            </div>
        );
    }
    return <EditEventWithData event={data} />;
};
