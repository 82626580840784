import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { SearchXIcon, ZoomInIcon, ZoomOutIcon } from 'lucide-react';
import { isColorObject, isSeat, type SeatCategory, type SeatMap, type SeatMapSeat } from '@/types/SeatMap';
import { Seat } from './Seat';
import useScreenSize from '@/hooks/useScreenSize';

interface SeatMapCommonProps {
    seatMap: SeatMap;
    admin?: boolean;
}

type SeatMapConditionalProps =
    | {
          selectedSeats: SeatMapSeat[];
          toggleSelection: (seat: SeatMapSeat) => void;
          readOnly?: false;
      }
    | {
          selectedSeats?: never;
          toggleSelection?: never;
          readOnly: true;
      };

type SeatMapProps = SeatMapCommonProps & SeatMapConditionalProps;

function Controls(): JSX.Element {
    const { zoomIn, zoomOut, resetTransform } = useControls();

    return (
        <div className="absolute z-10 bottom-0 left-0 flex flex-col p-4 gap-2">
            <Button size="icon" onClick={() => zoomIn()}>
                <ZoomInIcon className="h-5 w-5" />
            </Button>
            <Button size="icon" onClick={() => zoomOut()}>
                <ZoomOutIcon className="h-5 w-5" />
            </Button>
            <Button size="icon" onClick={() => resetTransform()}>
                <SearchXIcon className="h-5 w-5" />
            </Button>
        </div>
    );
}

interface CategoriesProps {
    categories: SeatCategory[];
}

function Categories({ categories }: CategoriesProps): JSX.Element {
    return (
        <div className="absolute z-10 top-2 right-2 sm:top-4 sm:right-4 flex flex-col">
            <div className="flex flex-row gap-4">
                {categories.map((category) => (
                    <div key={category.name} className="flex items-center gap-1">
                        <div
                            style={{
                                backgroundColor: category.color,
                            }}
                            className="h-2 w-2 sm:h-4 sm:w-4 rounded-full"
                        />
                        <p className="text-sm sm:text-base">{category.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default function SeatMap({
    seatMap,
    readOnly,
    selectedSeats,
    toggleSelection,
    admin,
}: SeatMapProps): JSX.Element {
    const { isMobile } = useScreenSize();

    return (
        <Card className="relative w-full lg:h-full h-[60vh] ">
            <TransformWrapper centerOnInit initialScale={isMobile ? 0.6 : 0.8} minScale={0.2} maxScale={2.5}>
                <Categories categories={seatMap.categories} />
                {!isMobile && <Controls />}
                <TransformComponent
                    // Settting style in css to override the default style
                    wrapperStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    contentClass="justify-center items-center flex flex-col gap-1"
                >
                    {seatMap.grid.map((row, index) => (
                        <div key={index} className="flex flex-row items-center gap-1">
                            {row.map((seat, i) => {
                                if (isColorObject(seat)) {
                                    return (
                                        <div
                                            key={`${seat.color}-${i}`}
                                            className="h-2 w-2"
                                            style={{
                                                backgroundColor: seat.color,
                                            }}
                                        />
                                    );
                                } else if (isSeat(seat)) {
                                    if (readOnly) {
                                        return <Seat key={`${seat.seatId}-${i}`} seat={seat} readOnly />;
                                    } else {
                                        return (
                                            <Seat
                                                key={`${seat.seatId}-${i}`}
                                                seat={seat}
                                                admin={admin}
                                                selected={selectedSeats.some((s) => s.seatId === seat.seatId)}
                                                toggleSelection={toggleSelection}
                                            />
                                        );
                                    }
                                } else {
                                    return <div key={`${seat.blank}-${i}-${index}`} className="h-2 w-2" />;
                                }
                            })}
                        </div>
                    ))}
                </TransformComponent>
            </TransformWrapper>
        </Card>
    );
}
