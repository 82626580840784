import { MeiliSearch } from 'meilisearch';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

import { Check, ChevronsUpDown } from 'lucide-react';
import { useEffect, useState } from 'react';
import type { UserResponse } from '@/types/User';
import { Skeleton } from './ui/skeleton';
import { CreateUser } from './Users/CreateUser';

const client = new MeiliSearch({
    host: import.meta.env.VITE_MEILI_HOST as string,
    apiKey: import.meta.env.VITE_MEILI_API_KEY as string,
});

const searchIndex = client.index('users');

interface UserSelectProps {
    user?: UserResponse;
    setUser: (user?: UserResponse) => void;
    className?: string;
    isLoading?: boolean;
}

export function UserSelect({ user, setUser, className, isLoading }: UserSelectProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [selectUserId, setSelectUserId] = useState('');
    const [users, setUsers] = useState<UserResponse[]>([]);

    useEffect(() => {
        if (query) {
            searchIndex.search<UserResponse>(query, { limit: 10 }).then((res) => {
                setUsers(res.hits);
            });
        }
    }, [query]);

    if (isLoading) {
        return <Skeleton className={cn(className, 'h-10 border')} />;
    }

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={cn(className, 'justify-between')}
                >
                    {user ? `${user.firstName} ${user.lastName} (${user.email})` : 'Select a user...'}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-full p-0 popover-content-width-same-as-its-trigger">
                <Command shouldFilter={false}>
                    <CommandInput placeholder="Search user..." value={query} onValueChange={setQuery} />
                    <CommandEmpty>
                        <CreateUser>
                            <Button variant="ghost">Create new user</Button>
                        </CreateUser>
                    </CommandEmpty>
                    {users.length > 0 && (
                        <CommandGroup>
                            {users.map((u, index) => (
                                <CommandItem
                                    key={u.id + index}
                                    value={u.id}
                                    onSelect={(currentValue) => {
                                        setSelectUserId(currentValue === selectUserId ? '' : currentValue);
                                        setUser(currentValue === selectUserId ? undefined : u);
                                        setOpen(false);
                                    }}
                                >
                                    <Check
                                        className={cn(
                                            'mr-2 h-4 w-4',
                                            selectUserId === u.id ? 'opacity-100' : 'opacity-0',
                                        )}
                                    />
                                    {u.firstName} {u.lastName} ({u.email})
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    )}
                </Command>
            </PopoverContent>
        </Popover>
    );
}
