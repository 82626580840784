import { useState } from 'react';
import { useAddUserToRoleMutation } from '@/services/rolesApi';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger, DialogTitle, DialogHeader } from '@/components/ui/dialog';
import { errorToast, errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { UserSelect } from '../UserSelect';
import type { UserResponse } from '@/types/User';

interface AddUserRoleProps {
    roleId: string;
}

export function AddUserRole({ roleId }: AddUserRoleProps): JSX.Element {
    const [user, setUser] = useState<UserResponse | undefined>(undefined);
    const [addUserToRole, { isLoading }] = useAddUserToRoleMutation();

    const handleSubmit = async () => {
        if (!user) {
            errorToast('You need to select a user');
            return;
        }
        addUserToRole({ roleId, userId: user.id })
            .unwrap()
            .then(() => successToast(`User ${user.firstName} ${user.lastName} added to role successfully`))
            .catch(errorToastFromCatch);
    };

    return (
        <div>
            <Dialog>
                <DialogTrigger asChild>
                    <Button>Add User</Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Add User to Role</DialogTitle>
                    </DialogHeader>
                    <div className="flex flex-col space-y-2">
                        <UserSelect user={user} setUser={setUser} className="w-full" />
                        <Button onClick={handleSubmit} disabled={isLoading || !user} className="w-fit self-end">
                            Add to Role
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
