import { useMyOrganizations } from '@/hooks/apiHooks';

export const NotRepresentative = (): React.ReactNode => {
    const organizations = useMyOrganizations();
    return (
        <div className="flex flex-col justify-center items-center h-full">
            <div className="flex flex-col justify-center items-center w-[80%] h-full gap-16">
                <h1 className="text-9xl font-bold text-center">Sorry :(</h1>
                <h1 className="text-4xl font-bold text-center">
                    You have to be representative of an organization to use this site.
                </h1>

                {organizations.length === 0 ? (
                    <h2 className="text-2xl font-bold text-center">
                        You are not a member of any organization. Ask an administrator of the organization you want to
                        use this site for to add you as a member and give you the representative role.
                    </h2>
                ) : (
                    <>
                        <div>
                            <h2 className="text-2xl font-bold text-center max-w-3xl">
                                To be able to use this site, ask an admin of your organization to give you the
                                representative role.
                            </h2>
                            <h2 className="text-2xl font-bold text-center">
                                You are a member but not representative of the following organizations:
                            </h2>
                        </div>
                        <div className="flex flex-col justify-center items-start gap-4">
                            {organizations.map((organization) => (
                                <div key={organization.id} className="flex flex-row justify-center items-start gap-4">
                                    <h3 className="text-xl font-bold">{organization.shortName}</h3>
                                    <h3 className="text-xl">{organization.name}</h3>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
