export type Status = 'AVAILABLE' | 'UNAVAILABLE' | 'TAKEN' | 'RESERVED' | 'CONSUMED';

export const statusOptions = [
    {
        label: 'Available',
        value: 'AVAILABLE',
    },
    {
        label: 'Unavailable',
        value: 'UNAVAILABLE',
    },
    {
        label: 'Taken',
        value: 'TAKEN',
    },
    {
        label: 'Reserved',
        value: 'RESERVED',
    },
    {
        label: 'Consumed',
        value: 'CONSUMED',
    },
];

export const seatStatusOptions = [
    {
        label: 'Available',
        value: 'AVAILABLE',
    },
    {
        label: 'Unavailable',
        value: 'UNAVAILABLE',
    },
];
