import { useForm } from 'react-hook-form';
import { Button } from '../../components/ui/button';
import { FormInput, FormTextArea } from '../../components/ui/FormHelpers';
import { Form } from '../../components/ui/form';
import { errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { useNavigate } from 'react-router-dom';
import type { SeatMapInput } from '@/types/SeatMap';
import { useCreateSeatMapMutation } from '@/services/seatMapApi';

export const CreateSeatmap = (): JSX.Element => {
    const form = useForm<SeatMapInput>();
    const handleSubmit = form.handleSubmit;
    const errors = form.formState.errors;
    const control = form.control;
    const [createSeatMap, createSeatMapState] = useCreateSeatMapMutation();
    const navigate = useNavigate();

    return (
        <div className="flex w-full flex-col items-center">
            <div className="max-w-5xl w-full">
                <h1 className="text-2xl font-semibold py-4 w-full">Create Seat Map</h1>
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit((data) => {
                            createSeatMap(data)
                                .unwrap()
                                .then((map) => {
                                    successToast(`Seat map ${map.name} created successfully`);
                                    navigate('/admin/seat-maps');
                                })
                                .catch(errorToastFromCatch);
                        })}
                        className=" w-full bg-background rounded-lg flex flex-col"
                    >
                        <div className="space-y-4">
                            <FormInput
                                control={control}
                                id="name"
                                label="Name"
                                error={errors.name?.type === 'required' ? 'Name is required' : undefined}
                            />
                            <FormTextArea
                                control={control}
                                id="description"
                                label="Description"
                                error={errors.description?.type === 'required' ? 'Description is required' : undefined}
                            />
                            <FormTextArea
                                control={control}
                                id="categories"
                                label="Categories"
                                error={errors.categories?.type === 'required' ? 'Categories is required' : undefined}
                            />
                            <FormTextArea
                                control={control}
                                id="grid"
                                label="Grid"
                                error={errors.grid?.type === 'required' ? 'Grid is required' : undefined}
                            />
                            <Button className="w-full" isLoading={createSeatMapState.isLoading}>
                                Create
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    );
};
