import type { Table } from '@tanstack/react-table';
import { XIcon } from 'lucide-react';

import { DataTableFacetedFilter } from '@/components/dataTable/DataTableFacetedFilter';
import DataTableViewOptions from '@/components/dataTable/DataTableViewOptions';
import { Button } from '@/components/ui/button';
import { statusOptions } from '@/types/Status';

interface DataTableToolbarProps<TData> {
    table: Table<TData>;
}

export function DataTableToolbar<TData>({ table }: DataTableToolbarProps<TData>): JSX.Element {
    const isFiltered = table.getState().columnFilters.length > 0;
    const statusExists = table.getAllColumns().some((column) => column.id === 'status');

    return (
        <div className="flex items-center justify-between p-4">
            <div className="flex flex-1 items-center space-x-2">
                {statusExists && (
                    <DataTableFacetedFilter column={table.getColumn('status')} title="Status" options={statusOptions} />
                )}
                {isFiltered && (
                    <Button variant="ghost" onClick={() => table.resetColumnFilters()} className="h-8 px-2 lg:px-3">
                        Reset
                        <XIcon className="ml-2 h-4 w-4" />
                    </Button>
                )}
            </div>
            <DataTableViewOptions table={table} />
        </div>
    );
}
