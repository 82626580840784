import { useState, useEffect, useMemo } from 'react';

interface ScreenSize {
    screen: { width: number; height: number };
    isMobile: boolean;
}

const useScreenSize = (): ScreenSize => {
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const isMobile = useMemo(() => {
        return screenSize.width <= 640;
    }, [screenSize.width]);

    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { screen: screenSize, isMobile };
};

export default useScreenSize;
