import { Button } from '../ui/button';
import { useForm } from 'react-hook-form';
import { Form } from '../ui/form';
import { FormInput, FormMultiSelect, FormSelect, FormTextArea } from '../ui/FormHelpers';

import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { errorToastFromCatch, successToast } from '../ui/use-toast';
import { useState } from 'react';
import { useCurrentOrganization, useCurrentOrganizationRoles } from '@/hooks/apiHooks';
import { useGetEventsQuery } from '@/services/eventsApi';
import { pageSizes } from '@/types/Pagination';
import { CenteredSpinner } from '../ui/spinner';
import type { ReferralCampaignFormInput } from '@/types/Referral';
import type { OptionType } from '../ui/multi-select';

interface ReferralSheetProps {
    onSubmit: (data: ReferralCampaignFormInput) => Promise<unknown>;
    isLoading: boolean;
    children: React.ReactNode;
}

interface FormEvent {
    id: string;
    title: string;
    associatedEvents: string[];
}

interface FormInputProps {
    title: string;
    description: string;
    event: string;
    roleOptions: OptionType[];
}

export const ReferralSheet = (props: ReferralSheetProps): JSX.Element => {
    const { onSubmit, isLoading } = props;
    const [open, setOpen] = useState(false);
    const currentOrg = useCurrentOrganization();
    const { roles, isLoading: rolesLoading } = useCurrentOrganizationRoles();
    const { data: eventsResponse, isLoading: eventsLoading } = useGetEventsQuery({
        pageNumber: 0,
        pageSize: pageSizes.enormous,
        organizationId: currentOrg?.id,
    });

    const form = useForm<FormInputProps>({
        defaultValues: {
            title: '',
            description: '',
            event: '',
            roleOptions: [],
        },
    });
    const handleSubmit = form.handleSubmit;
    const errors = form.formState.errors;
    const control = form.control;

    let manipulatedEvents: FormEvent[] = [];
    if (eventsResponse?.data) {
        const events: FormEvent[] = [];
        for (const event of eventsResponse.data) {
            const eventId = event.groupId || event.id;
            const existingEvent = events.find((e) => e.id === eventId);
            if (existingEvent) {
                existingEvent.associatedEvents.push(event.id);
            } else {
                events.push({
                    id: eventId,
                    title: event.title,
                    associatedEvents: [event.id],
                });
            }
        }
        manipulatedEvents = events;
    }

    const handleFormSubmit = (data: FormInputProps) => {
        const { roleOptions: _, ...rest } = data;

        if (!manipulatedEvents) {
            return;
        }
        const selectedEventId = data.event;
        const selectedEvent = manipulatedEvents.find((e) => e.id === selectedEventId);
        const associatedEventIds = selectedEvent ? selectedEvent.associatedEvents : [];

        const filteredData = {
            ...rest,
            events: associatedEventIds,
            roles: data.roleOptions.map((role) => role.value),
            event: undefined,
        };

        onSubmit(filteredData)
            .then(() => {
                successToast('Role saved successfully');
                form.reset();
                setOpen(false);
            })
            .catch(errorToastFromCatch);
    };

    if (rolesLoading || eventsLoading) {
        return <CenteredSpinner />;
    }
    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <SheetTrigger asChild>{props.children}</SheetTrigger>
            </div>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Referral Campaign Information</SheetTitle>
                    <div className="grid gap-4 py-4">
                        <Form {...form}>
                            <form onSubmit={handleSubmit(handleFormSubmit)}>
                                <FormInput
                                    control={control}
                                    id="title"
                                    label="Title"
                                    error={errors.title?.type === 'required' ? 'Title is required' : undefined}
                                />
                                <FormTextArea
                                    control={control}
                                    id="description"
                                    label="Description"
                                    error={
                                        errors.description?.type === 'required' ? 'Description is required' : undefined
                                    }
                                />
                                <FormSelect
                                    control={control}
                                    id="event"
                                    label="Event"
                                    error={errors.event?.type === 'required' ? 'Event is required' : undefined}
                                    valuesAndNames={manipulatedEvents.map((event) => [event.id, event.title])}
                                />
                                <FormMultiSelect
                                    control={control}
                                    id="roleOptions"
                                    label="Roles"
                                    placeholder="Select roles"
                                    notRequired
                                    hide={rolesLoading}
                                    fadeIn={rolesLoading}
                                    valuesAndNames={roles.map((role) => {
                                        const option: OptionType = { value: role.id, label: role.title };
                                        return option;
                                    })}
                                />
                                <div className="mt-4">
                                    <Button variant="default" type="submit" isLoading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </Form>
                    </div>
                </SheetHeader>
            </SheetContent>
        </Sheet>
    );
};
