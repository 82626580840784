import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

interface Props {
    coordinates: [number, number];
}

export default function MapLocator({ coordinates }: Props): null {
    const map = useMap();

    useEffect(() => {
        map.flyTo(coordinates, map.getZoom());
    }, [coordinates]);
    return null;
}
