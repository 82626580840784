import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

export const NotFound = (): JSX.Element => {
    return (
        <div className="flex flex-col h-full">
            <div className="flex flex-col justify-center items-center w-full h-full text-center px-4">
                <img src={'/images/Ooops.png'} alt="Oops!" className="max-w-xs w-full rounded-xl" />
                <h1 className="text-4xl font-bold mt-6">Oops! We can't find that page.</h1>
                <p className="mt-2">But don't worry, it's probably just playing hide-and-seek.</p>
                <p className="mt-4">Let's get you back home and on track!</p>
                <Link to={'/'}>
                    <Button className="mt-6">Take Me Home</Button>
                </Link>
            </div>
        </div>
    );
};
