import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { useSignInUserMutation, useVerifyOtpMutation } from '@/services/authApi';
import { isLabbError } from '@/types/Error';
import type { SignInRequest } from '@/types/User';
import VerifyOtpForm from '@/components/VerifyOtpForm';
import { Form } from '@/components/ui/form';
import { FormInput } from '@/components/ui/FormHelpers';

export default function SignIn(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const [signIn, signInResponse] = useSignInUserMutation();
    const [verify, verifyResponse] = useVerifyOtpMutation();

    useEffect(() => {
        if (verifyResponse.isSuccess) {
            const from = location.state?.from ?? '/';
            navigate(from, { replace: true });
        }
    }, [verifyResponse.isSuccess]);

    return (
        <div className="flex justify-center items-center h-full">
            <div className="w-80">
                {signInResponse.isSuccess ? (
                    <VerifyOtpForm
                        verify={verify}
                        isLoading={verifyResponse.isLoading}
                        error={isLabbError(verifyResponse.error) ? verifyResponse.error.data.message : undefined}
                    />
                ) : (
                    <>
                        <SignInForm
                            signIn={signIn}
                            isLoading={signInResponse.isLoading}
                            error={isLabbError(signInResponse.error) ? signInResponse.error.data.message : undefined}
                        />
                        <p className="text-center mt-4">
                            Don't have an account?{' '}
                            <Link to="/sign-up" className="underline">
                                Sign up
                            </Link>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
}
interface SignInFormProps {
    signIn: (data: SignInRequest) => void;
    error?: string;
    isLoading?: boolean;
}

function SignInForm({ signIn, error, isLoading }: SignInFormProps) {
    const form = useForm<SignInRequest>();
    const errors = form.formState.errors;
    const loading = form.formState.isLoading || isLoading;

    useEffect(() => {
        if (error) {
            form.setError('email', {
                type: 'server',
                message: error,
            });
        }
    }, [error]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(signIn)} className="space-y-2 w-full">
                <FormInput
                    control={form.control}
                    id="email"
                    label="Email"
                    type="email"
                    error={errors.email?.type === 'required' ? 'Email is required' : undefined}
                />
                <Button isLoading={loading} type="submit" className="w-full">
                    Sign in
                </Button>
            </form>
        </Form>
    );
}
