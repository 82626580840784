import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@/components/ui/card';
import { Table, TableRow, TableCell, TableBody } from '@/components/ui/table';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    type TooltipItem,
    type ChartOptions,
} from 'chart.js';
import { useGetReferralByIdQuery, useGetReferralStatsQuery, useGetSalesOverTimeQuery } from '@/services/referralApi';
import ReferralLinkButton from '@/components/Referral/ReferralLinkButton';
import { CenteredSpinner } from '@/components/ui/spinner';
import type { ReferralCampaignSalesDataOverTime } from '@/types/Referral';
import type { LabbError } from '@/types/Error';
import { formatPrice } from '@/lib/utils';
import { PageHeader } from '@/components/PageHeader';

interface ChartDataSets {
    label: string;
    data: { x: string; y: number }[];
    borderColor: string;
    backgroundColor: string;
    fill: boolean;
    tension: number;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function ReferralDetailPage(): JSX.Element {
    const { id } = useParams<{ id: string }>();

    const { data, isLoading, error: referralError } = useGetReferralByIdQuery(id as string);

    const { data: statsData, error: statsError } = useGetReferralStatsQuery(id as string);

    const { data: salesData, error: salesError } = useGetSalesOverTimeQuery(id as string);

    const isUnauthorized = [referralError, statsError, salesError].some(
        (error) => (error as LabbError)?.data.status === 401,
    );

    const salesOptions: ChartOptions<'line'> = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Cumulative Ticket Sales Over Time',
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    label: function (context: TooltipItem<'line'>) {
                        return `${context.dataset.label}: ${context.parsed.y}`;
                    },
                },
            },
        },
        scales: {
            y: {
                type: 'linear',
                title: {
                    display: true,
                    text: 'Tickets Sold',
                },
            },
        },
    };
    const processSalesData = (unProcessedData: ReferralCampaignSalesDataOverTime[]): ChartDataSets[] => {
        if (!unProcessedData) {
            return [];
        }
        let earliestDate = new Date();
        unProcessedData.forEach((dataUser) => {
            dataUser.salesData.forEach((sale) => {
                const tempDate = new Date(sale.date);
                if (tempDate < earliestDate) {
                    earliestDate = tempDate;
                }
            });
        });

        return unProcessedData.map((dataUser) => {
            let cumulativeTickets = 0;
            let earliestDateExist = false;
            const dataPoints = dataUser.salesData.map((sale) => {
                cumulativeTickets += sale.ticketsSold;
                if (sale.date === earliestDate.toISOString().split('T')[0]) {
                    earliestDateExist = true;
                }
                return {
                    x: sale.date,
                    y: cumulativeTickets,
                };
            });

            if (!earliestDateExist) {
                dataPoints.unshift({
                    x: earliestDate.toISOString().split('T')[0] ?? '',
                    y: 0,
                });
            }
            return {
                label: dataUser.userName,
                data: dataPoints,
                borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                fill: false,
                borderDash: [5, 5],
                tension: 0.1,
            };
        });
    };

    const salesChartData = {
        datasets: processSalesData(salesData || []),
    };

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!id) {
        return <div>Invalid referral id</div>;
    }
    if (isUnauthorized) {
        return <div>You are not allowed to visit this page.</div>;
    }

    if (!data) {
        return <div>Referral not found</div>;
    }

    return (
        <div>
            <PageHeader>
                <h2 className="text-lg">Referral Details</h2>
                <ReferralLinkButton campaign={data} />
            </PageHeader>
            <div className="p-4">
                <Card className="p-4">
                    <CardContent className="flex flex-col md:flex-row gap-2 pt-2">
                        <div className="basis-full md:basis-2/3">
                            <Line data={salesChartData} options={salesOptions} />
                        </div>
                        <div className="basis-full">
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Total tickets sold</TableCell>
                                        <TableCell>Total price for tickets</TableCell>
                                    </TableRow>
                                    {statsData &&
                                        statsData.map((stat) => (
                                            <TableRow key={stat.userId}>
                                                <TableCell>{stat.userName}</TableCell>
                                                <TableCell>{stat.totalTickets}</TableCell>
                                                <TableCell>{formatPrice(stat.totalRevenue)}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
