import { useEffect } from 'react';

export const DEFAULT_TITLE = 'LABB';
export const DEFAULT_DESCRIPTION = 'Lunds Akademiska Biljettbyrå';
export const DEFAULT_IMAGE = '/images/logo-banner.png';

interface Props {
    title?: string;
    description?: string;
    image?: string;
}

/**
 * ### Hook to set meta tags for the page
 * This hook should only be used in the top level component of a page to set the title, description and image of the page.
 * When the component is unmounted ie. you leave the page, the meta tags are reset to the default values.
 */
export const useMetaTags = (props?: Props): void => {
    const { title, description, image } = props ?? {};
    const newTitle = title ? title + ' | LABB' : DEFAULT_TITLE;
    useEffect(() => {
        // set title tags
        document.title = newTitle;

        const titleTag = getMetaTag('og:title', 'property');
        titleTag.content = newTitle;

        const twitterTitleTag = getMetaTag('twitter:title', 'name');
        twitterTitleTag.content = newTitle;

        // set description tags
        const descriptionTag = getMetaTag('description', 'name');
        descriptionTag.content = description ?? DEFAULT_DESCRIPTION;

        const ogDescriptionTag = getMetaTag('og:description', 'property');
        ogDescriptionTag.content = description ?? DEFAULT_DESCRIPTION;

        const twitterDescriptionTag = getMetaTag('twitter:description', 'name');
        twitterDescriptionTag.content = description ?? DEFAULT_DESCRIPTION;

        // set image tags
        const imageTag = getMetaTag('og:image', 'property');
        imageTag.content = image ?? DEFAULT_IMAGE;

        const twitterImageTag = getMetaTag('twitter:image', 'name');
        twitterImageTag.content = image ?? DEFAULT_IMAGE;

        const twitterCardTag = getMetaTag('twitter:card', 'name');
        twitterCardTag.content = image ?? DEFAULT_IMAGE;

        // reset all meta tags to default
        return () => {
            document.title = DEFAULT_TITLE;
            titleTag.content = DEFAULT_TITLE;
            twitterTitleTag.content = DEFAULT_TITLE;
            descriptionTag.content = DEFAULT_DESCRIPTION;
            ogDescriptionTag.content = DEFAULT_DESCRIPTION;
            twitterDescriptionTag.content = DEFAULT_DESCRIPTION;
            imageTag.content = DEFAULT_IMAGE;
            twitterImageTag.content = DEFAULT_IMAGE;
            twitterCardTag.content = DEFAULT_IMAGE;
        };
    }, [title, description, image]);
};

const getMetaTag = (name: string, attribute: string): HTMLMetaElement => {
    let metaTag = document.querySelector(`meta[${attribute}="${name}"]`) as HTMLMetaElement | null;
    if (!metaTag) {
        metaTag = document.createElement('meta') as HTMLMetaElement;
        metaTag.setAttribute(attribute, name);
        document.head.appendChild(metaTag);
    }
    return metaTag;
};
