import type { AuthResponse, CreateUser, OTPRequest, SignInRequest, User } from '@/types/User';

import { api } from './rootApi';
import { setAccessToken, setUser, signOut } from '@/features/user/userSlice';

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        signInUser: builder.mutation<User, SignInRequest>({
            query(data) {
                return {
                    url: 'auth/sign-in',
                    method: 'POST',
                    body: data,
                };
            },
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(setUser(data));
            },
        }),
        signUpUser: builder.mutation<User, CreateUser>({
            query(data) {
                return {
                    url: 'auth/sign-up',
                    method: 'POST',
                    body: data,
                };
            },
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(setUser(data));
            },
        }),
        signOutUser: builder.mutation<User, void>({
            query() {
                return {
                    url: 'auth/sign-out',
                    method: 'POST',
                    credentials: 'include',
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(signOut());
            },
        }),
        verifyOtp: builder.mutation<AuthResponse, OTPRequest>({
            query(data) {
                return {
                    url: 'auth/otp',
                    method: 'POST',
                    body: data,
                    credentials: 'include',
                };
            },
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(setAccessToken(data));
            },
        }),
    }),
});

export const { useSignInUserMutation, useSignUpUserMutation, useVerifyOtpMutation, useSignOutUserMutation } = authApi;
