import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger, DialogTitle, DialogHeader } from '@/components/ui/dialog';
import { errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { FolderSyncIcon } from 'lucide-react';
import { useSyncEventsMutation, useSyncUsersMutation } from '@/services/meilisearchApi';

interface AddUserRoleProps {
    children: React.ReactNode;
}

export function MeiliTasksDialog({ children }: AddUserRoleProps): JSX.Element {
    const [syncEvents, syncEventsState] = useSyncEventsMutation();
    const [syncUsers, syncUsersState] = useSyncUsersMutation();

    return (
        <div>
            <Dialog>
                <DialogTrigger className="w-full">{children}</DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Meilisearch Tasks</DialogTitle>
                    </DialogHeader>
                    <div className="flex flex-col space-y-2">
                        <Button
                            className="gap-2"
                            onClick={() =>
                                syncEvents()
                                    .unwrap()
                                    .then((res) => {
                                        successToast(res.message);
                                    })
                                    .catch(errorToastFromCatch)
                            }
                            isLoading={syncEventsState.isLoading}
                        >
                            <FolderSyncIcon className="w-5 h-5" />
                            <p>Sync events</p>
                        </Button>
                        <Button
                            className="gap-2"
                            onClick={() =>
                                syncUsers()
                                    .unwrap()
                                    .then((res) => {
                                        successToast(res.message);
                                    })
                                    .catch(errorToastFromCatch)
                            }
                            isLoading={syncUsersState.isLoading}
                        >
                            <FolderSyncIcon className="w-5 h-5" />
                            <p>Sync users</p>
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
