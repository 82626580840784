import { cva, type VariantProps } from 'class-variance-authority';
import { Loader2Icon } from 'lucide-react';
import { forwardRef, type HTMLAttributes } from 'react';

import { cn } from '@/lib/utils';

const spinnerVariants = cva('animate-spin', {
    variants: {
        size: {
            default: 'h-6 w-6',
            sm: 'h-h-4 w-4',
            md: 'h-8 w-8',
            lg: 'h-10 w-10',
        },
    },
    defaultVariants: {
        size: 'default',
    },
});

export interface SpinnerProps extends HTMLAttributes<SVGSVGElement>, VariantProps<typeof spinnerVariants> {}

const Spinner = forwardRef<SVGSVGElement, SpinnerProps>(({ className, size, ...props }, ref) => {
    return <Loader2Icon className={cn(spinnerVariants({ size, className }))} ref={ref} {...props} />;
});
Spinner.displayName = 'Spinner';

export const CenteredSpinner = forwardRef<SVGSVGElement, SpinnerProps>(({ className, size = 'lg', ...props }, ref) => {
    return (
        <div className="flex justify-center items-center h-full w-full">
            <Spinner className={cn(spinnerVariants({ size, className }))} ref={ref} {...props} />
        </div>
    );
});

export { Spinner, spinnerVariants };
