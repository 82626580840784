import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import type { OtherRec } from '@/types/Event';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateWithoutTime } from '@/lib/utils';

interface RelatedEventsScrollProps {
    relatedEvents: OtherRec[];
}

export const RelatedEventsScroll = ({ relatedEvents }: RelatedEventsScrollProps): JSX.Element => {
    const navigate = useNavigate();
    const { eventId } = useParams<{ eventId: string }>();

    return (
        <ScrollArea className="whitespace-nowrap rounded-md border">
            <div className="flex lg:space-x-4 space-x-2 p-4">
                {relatedEvents.map((event) => (
                    <Card
                        className={`flex hover:bg-accent cursor-pointer marked ${
                            event.id === eventId ? 'bg-accent border' : ''
                        }`}
                        onClick={() => {
                            navigate(`/events/${event.id}`);
                        }}
                    >
                        <CardContent>
                            <CardHeader>
                                <CardTitle className="text-sm sm:text-sm md:text-base lg:text-xl">
                                    {new Date(event.startDate).toLocaleDateString('en-GB', {
                                        weekday: 'long',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </CardTitle>
                                {formatDateWithoutTime(event.startDate)}
                            </CardHeader>
                        </CardContent>
                    </Card>
                ))}
            </div>
            <ScrollBar orientation="horizontal" />
        </ScrollArea>
    );
};
