import { cn } from '@/lib/utils';
import { decode } from 'blurhash';
import { useEffect, useRef, useState } from 'react';

interface Props {
    url: string;
    blurHash: string;
    aspectRatio: number;
    className?: string;
}

export const PreviewImage: React.FC<Props> = ({ url, blurHash, className, aspectRatio }) => {
    const [loaded, setLoaded] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        // make sure the image src is added after the onload handler
        if (imgRef.current) {
            imgRef.current.src = url;
        }
    }, [url, imgRef, blurHash]);

    return (
        <>
            <PreviewCanvas loaded={loaded} blurHash={blurHash} className={className} aspectRatio={aspectRatio} />
            <img
                ref={imgRef}
                onLoad={() => setLoaded(true)}
                src={blurHash ? url : '/images/banner-placeholder.svg'}
                className={cn(!loaded && 'hidden', className)}
            />
        </>
    );
};

interface CanvasPreviewProps {
    blurHash: string;
    loaded: boolean;
    aspectRatio: number;
    className?: string;
}

const PreviewCanvas: React.FC<CanvasPreviewProps> = ({ blurHash, loaded, className, aspectRatio }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const ctx = canvasRef?.current?.getContext('2d');
        if (!ctx) {
            return;
        }
        const canvas = canvasRef.current;
        if (!canvas) {
            return;
        }
        const w = canvas.width;
        const h = canvas.height;
        const pixels = decode(blurHash, w, h);
        const imageData = ctx.createImageData(w, h);
        imageData.data.set(pixels);
        ctx.putImageData(imageData, 0, 0);
    }, [blurHash]);

    if (loaded) {
        return null;
    }

    return (
        <canvas
            ref={canvasRef}
            className={className}
            style={{
                // The tailwindcss aspect-ratio class is not working...
                aspectRatio: aspectRatio,
            }}
        />
    );
};
