import type { CreateUser, EditUser, UserFilter, UserResponse } from '@/types/User';

import { api } from './rootApi';
import { pageSizes, type Pagination } from '@/types/Pagination';

export const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserById: builder.query<UserResponse, string>({
            query: (id) => `users/${id}`,
            providesTags: ['User'],
        }),
        getUsers: builder.query<Pagination<UserResponse>, UserFilter>({
            query: ({ pageNumber = 0, pageSize = pageSizes.small }) =>
                `users?pageNumber=${pageNumber}&pageSize=${pageSize}`,
            providesTags: ['User'],
        }),
        createUser: builder.mutation<UserResponse, CreateUser>({
            query: (body) => ({
                url: `users`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['User'],
        }),
        editUser: builder.mutation<void, EditUser>({
            query: ({ id, ...body }) => ({
                url: `users/${id}/edit`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['User'],
        }),
    }),
});

export const { useGetUserByIdQuery, useGetUsersQuery, useCreateUserMutation, useEditUserMutation } = userApi;
