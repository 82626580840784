import { api } from './rootApi';

export const meilisearchApi = api.injectEndpoints({
    endpoints: (builder) => ({
        syncEvents: builder.mutation<{ status: number; message: string }, void>({
            query: () => ({
                url: 'meilisearch/sync-events',
                method: 'PUT',
            }),
        }),
        syncUsers: builder.mutation<{ status: number; message: string }, void>({
            query: () => ({
                url: 'meilisearch/sync-users',
                method: 'PUT',
            }),
        }),
    }),
});

export const { useSyncEventsMutation, useSyncUsersMutation } = meilisearchApi;
