import { cn } from '@/lib/utils';

interface Props {
    children: React.ReactNode;
    className?: string;
}

export const PageHeader: React.FC<Props> = ({ children, className }) => {
    return (
        <div className={cn('w-full border-b px-4 h-14 flex flex-shrink-0 items-center justify-between', className)}>
            {children}
        </div>
    );
};
