import { useNavigate } from 'react-router-dom';

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import type { EventSmall } from '@/types/Event';
import { PreviewImage } from '../PreviewImage';
import {
    EVENT_BANNER_ASPECT_RATIO,
    EVENT_BANNER_PLACEHOLDER_BLURHASH,
    EVENT_BANNER_PLACEHOLDER_SRC,
    formatDate,
    getEventBannerSrc,
} from '@/lib/utils';
import { MapPinIcon } from 'lucide-react';

interface Props {
    event: EventSmall;
}

export default function EventCard({ event }: Props): JSX.Element {
    const navigate = useNavigate();

    return (
        <Card
            className="flex h-full flex-col hover:bg-accent hover:cursor-pointer"
            onClick={() => navigate(`/events/${event.id}`)}
        >
            <PreviewImage
                url={event.bannerBlurhash ? getEventBannerSrc(event.id) : EVENT_BANNER_PLACEHOLDER_SRC}
                blurHash={event.bannerBlurhash ?? EVENT_BANNER_PLACEHOLDER_BLURHASH}
                className="rounded-t-lg w-full"
                aspectRatio={EVENT_BANNER_ASPECT_RATIO}
            />
            <CardHeader>
                <CardTitle>{event.title}</CardTitle>
                <CardDescription className="flex gap-2">
                    <p>{formatDate(event.startDate)}</p>
                    <p>-</p>
                    <p>{formatDate(event.endDate)}</p>
                </CardDescription>
                <CardDescription className="flex gap-1 items-center">
                    <MapPinIcon className="h-3 w-3" />
                    {event.placeName}
                </CardDescription>
            </CardHeader>
            <CardContent className="pb-4">
                <p className="line-clamp-3">{event.description}</p>
            </CardContent>
        </Card>
    );
}
