import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { setCurrentOrganization } from '@/features/currentOrganization/currentOrganizationSlice';
import { useAppDispatch } from '@/hooks/store';
import type { OrganizationSmall } from '@/types/Organization';
import { useNavigate } from 'react-router-dom';
import { DeleteDialog } from '../ui/DeleteDialog';
import { useDeleteOrganizationMutation } from '@/services/organizationApi';
import { Button } from '../ui/button';
import { PreviewImage } from '@/components/PreviewImage';
import {
    ORGANIZATION_BANNER_ASPECT_RATIO,
    ORGANIZATION_BANNER_PLACEHOLDER_BLURHASH,
    ORGANIZATION_BANNER_PLACEHOLDER_SRC,
    getOrganizationBannerSrc,
} from '@/lib/utils';

interface Props {
    organization: OrganizationSmall;
}

export default function AdminOrganizationCard({ organization }: Props): JSX.Element {
    const [deleteOrganization, { isLoading }] = useDeleteOrganizationMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return (
        <Card
            className="flex flex-col hover:bg-accent hover:cursor-pointer"
            onClick={() => {
                dispatch(setCurrentOrganization(organization));
                navigate('/admin/organization');
            }}
        >
            <PreviewImage
                url={
                    organization.bannerBlurhash
                        ? getOrganizationBannerSrc(organization.id)
                        : ORGANIZATION_BANNER_PLACEHOLDER_SRC
                }
                blurHash={organization.bannerBlurhash ?? ORGANIZATION_BANNER_PLACEHOLDER_BLURHASH}
                className="rounded-t-lg w-full"
                aspectRatio={ORGANIZATION_BANNER_ASPECT_RATIO}
            />
            <CardHeader>
                <CardTitle>{organization.name}</CardTitle>
                <CardDescription>{organization.shortName}</CardDescription>
            </CardHeader>
            <CardFooter className="justify-end">
                <DeleteDialog isLoading={isLoading} deleteItem={() => deleteOrganization(organization.id).unwrap()}>
                    <Button variant="destructive">Delete</Button>
                </DeleteDialog>
            </CardFooter>
        </Card>
    );
}
