import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { PopoverContent } from '../ui/popover';
import { Form } from '../ui/form';
import { checkExhaustiveSwitch, cn, getEnumKeyValuePairs } from '@/lib/utils';
import { FormInput, FormSelect } from '../ui/FormHelpers';
import { Switch } from '../ui/Switch';
import { Button } from '../ui/button';

export enum RepeatsEvery {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year',
}

export enum RepeatsOn {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export interface RecurringFormData {
    repeatsEveryNum: number;
    repeatsEvery: RepeatsEvery;
    repeatsOn: RepeatsOn[];
    occurrences: number;
}

interface RecurringFormProps {
    formData: RecurringFormData | undefined;
    setFormData: (formData: RecurringFormData) => void;
    isRecurring: boolean;
    setIsRecurring: (isRecurring: boolean) => void;
    closePopover: () => void;
    dayOfWeek: number;
}

export const RecurringForm = ({
    formData,
    setFormData,
    isRecurring,
    setIsRecurring,
    closePopover,
    dayOfWeek,
}: RecurringFormProps): JSX.Element => {
    const form = useForm<RecurringFormData>({
        defaultValues: {
            repeatsEveryNum: formData?.repeatsEveryNum ?? 1,
            repeatsEvery: formData?.repeatsEvery ?? RepeatsEvery.Week,
            repeatsOn: formData?.repeatsOn ?? [dayOfWeek],
            occurrences: formData?.occurrences ?? 1,
        },
    });
    const [localRepeatsOn, setLocalRepeatsOn] = useState<RepeatsOn[]>(formData?.repeatsOn ?? [dayOfWeek]);

    const control = form.control;

    return (
        <PopoverContent align="end" className="w-fit px-6" onPointerDownOutside={closePopover}>
            <Form {...form}>
                <form
                    id="recurring-form"
                    onSubmit={form.handleSubmit((data) => {
                        data.repeatsOn.push(dayOfWeek);
                        setFormData({ ...data, repeatsOn: new Array(...new Set(data.repeatsOn)) });
                        closePopover();
                    })}
                >
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between gap-2">
                            <div
                                className={cn(
                                    'flex flex-col gap-2 transition-all',
                                    !isRecurring && 'blur-sm pointer-events-none opacity-30',
                                )}
                            >
                                Repeats every
                                <div className="flex gap-2">
                                    <div className="w-24">
                                        <FormInput
                                            control={control}
                                            id="repeatsEveryNum"
                                            type="number"
                                            minValue={1}
                                            step="1"
                                        />
                                    </div>
                                    <FormSelect
                                        control={control}
                                        id="repeatsEvery"
                                        valuesAndNames={[
                                            [RepeatsEvery.Day, 'Day'],
                                            [RepeatsEvery.Week, 'Week'],
                                            [RepeatsEvery.Month, 'Month'],
                                            [RepeatsEvery.Year, 'Year'],
                                        ]}
                                        defaultValueAndName={[RepeatsEvery.Week, 'Week']}
                                        fadeIn
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col gap-2">
                                Should repeat
                                <Switch
                                    className="flex justify-end"
                                    checked={isRecurring}
                                    onClick={() => setIsRecurring(!isRecurring)}
                                />
                            </div>
                        </div>

                        <div
                            className={cn(
                                'flex flex-col gap-2 transition-all',
                                !(isRecurring && form.getValues('repeatsEvery') === RepeatsEvery.Week) &&
                                    'blur-sm pointer-events-none opacity-30',
                            )}
                        >
                            Repeats on
                            <div className="flex gap-2">
                                {getEnumKeyValuePairs(RepeatsOn)
                                    .sort(([_, v1], [__, v2]) => (v1 === 0 ? 1 : v2 === 0 ? -1 : v1 - v2))
                                    .map(([key, value]) => (
                                        <div
                                            key={key}
                                            className={cn(
                                                'flex justify-center items-center rounded-full p-2 border w-12 h-12 cursor-pointer',
                                                localRepeatsOn.includes(value) || value === dayOfWeek
                                                    ? 'bg-secondary-foreground text-primary-foreground'
                                                    : 'bg-primary-foreground text-primary-background',
                                            )}
                                            onClick={() => {
                                                let repeatsOn = form.getValues('repeatsOn') as RepeatsOn[] | undefined;
                                                if (repeatsOn?.includes(value) && value !== dayOfWeek) {
                                                    const index = repeatsOn.indexOf(value);
                                                    repeatsOn.splice(index, 1);
                                                } else if (repeatsOn) {
                                                    repeatsOn.push(value);
                                                } else {
                                                    repeatsOn = [value];
                                                }
                                                form.setValue('repeatsOn', repeatsOn);
                                                setLocalRepeatsOn(repeatsOn);
                                            }}
                                        >
                                            {key.charAt(0).toUpperCase()}
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <div
                            className={cn(
                                'flex justify-between items-end transition-all',
                                !isRecurring && 'blur-sm pointer-events-none opacity-30',
                            )}
                        >
                            <div className="flex flex-col gap-2">
                                Occurrences
                                <FormInput
                                    control={control}
                                    id="occurrences"
                                    type="number"
                                    minValue={1}
                                    step="1"
                                    maxValue={20}
                                />
                            </div>
                            <Button type="submit">Apply</Button>
                        </div>
                    </div>
                </form>
            </Form>
        </PopoverContent>
    );
};

/**
 * From a set of rules and a start date, calculate the dates the the events should occur on
 */
export const calculateDates = (recurringFormData: RecurringFormData, startDate: string): string[] => {
    const { repeatsEvery, repeatsEveryNum, repeatsOn, occurrences } = recurringFormData;
    const dates: Date[] = [];
    let i = 0;
    while (i < occurrences) {
        const date = new Date(startDate);
        switch (repeatsEvery) {
            case RepeatsEvery.Day:
                date.setDate(date.getDate() + repeatsEveryNum * i);
                dates.push(date);
                i++;
                break;
            case RepeatsEvery.Week:
                const day = date.getDay();
                const week = (repeatsEveryNum * 7 * i) / repeatsOn.length;
                repeatsOn.forEach((dayToAdd) => {
                    if (i < occurrences) {
                        const newDate = new Date(date);
                        newDate.setDate(date.getDate() + week + dayToAdd - day);
                        dates.push(newDate);
                        i++;
                    }
                });
                break;
            case RepeatsEvery.Month:
                date.setMonth(date.getMonth() + repeatsEveryNum * i);
                dates.push(date);
                i++;
                break;
            case RepeatsEvery.Year:
                date.setFullYear(date.getFullYear() + repeatsEveryNum * i);
                dates.push(date);
                i++;
                break;
            default:
                checkExhaustiveSwitch(repeatsEvery);
                break;
        }
    }
    return dates.map((date) => date.toISOString().split('T')[0] ?? '');
};
