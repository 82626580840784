import { api } from './rootApi';
import type { CreateEventItem, PostEventItemResponse, PutEventItem } from '@/types/EventItem';

export const eventItemApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createEventItem: builder.mutation<PostEventItemResponse, CreateEventItem>({
            query: (body) => ({
                url: `event-items`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['EventItem'],
        }),
        editEventItem: builder.mutation<PostEventItemResponse, PutEventItem>({
            query: ({ id, ...body }) => ({
                url: `event-items/${id}/edit`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['EventItem'],
        }),
    }),
});
export const { useCreateEventItemMutation, useEditEventItemMutation } = eventItemApi;
