import { useCurrentOrganizationPlaces } from '@/hooks/apiHooks';
import { ExpandableText } from '../ui/ExpandableText';
import type { EventInput, Event } from '@/types/Event';
import {
    EVENT_BANNER_ASPECT_RATIO,
    EVENT_BANNER_PLACEHOLDER_BLURHASH,
    EVENT_BANNER_PLACEHOLDER_SRC,
    formatDate,
    getEventBannerSrc,
} from '@/lib/utils';
import { PreviewImage } from '../PreviewImage';

interface Props {
    eventInput: EventInput | undefined;
    croppedImage: string | null;
    event?: Event;
}

export const EventPreview = (props: Props): JSX.Element => {
    const { eventInput, croppedImage, event } = props;
    const { places } = useCurrentOrganizationPlaces();
    return (
        <div className="w-full max-w-[820px] space-y-4">
            {croppedImage ? (
                <img src={croppedImage} className="w-full h-auto rounded-lg" />
            ) : (
                <PreviewImage
                    url={event?.bannerBlurhash ? getEventBannerSrc(event.id) : EVENT_BANNER_PLACEHOLDER_SRC}
                    blurHash={event?.bannerBlurhash ?? EVENT_BANNER_PLACEHOLDER_BLURHASH}
                    className="w-full rounded-lg"
                    aspectRatio={EVENT_BANNER_ASPECT_RATIO}
                />
            )}
            {eventInput && (
                <div className="flex justify-between items-center">
                    <div>
                        <h1 className="text-3xl">{eventInput.title}</h1>
                        <p>{places.find((place) => place.id === eventInput.placeId)?.name}</p>
                    </div>
                    <div className="flex space-x-2">
                        <p>{formatDate(eventInput.startDate)}</p>
                        <p>-</p>
                        <p>{formatDate(eventInput.endDate)}</p>
                    </div>
                </div>
            )}
            <ExpandableText text={eventInput?.description} lines={5} />
        </div>
    );
};
