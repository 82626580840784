import { PreviewImage } from '@/components/PreviewImage';
import { Button } from '@/components/ui/button';
import { CenteredSpinner } from '@/components/ui/spinner';
import { errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import {
    ORGANIZATION_BANNER_ASPECT_RATIO,
    ORGANIZATION_PICTURE_PLACEHOLDER_BLURHASH,
    ORGANIZATION_PICTURE_PLACEHOLDER_SRC,
    getOrganizationPictureSrc,
} from '@/lib/utils';
import { useAcceptInviteLinkMutation, useGetRoleFromInviteQuery } from '@/services/rolesApi';
import { useNavigate, useParams } from 'react-router-dom';

export const InviteLink = (): JSX.Element => {
    const { inviteId } = useParams<{ inviteId: string }>();
    const { data, isLoading } = useGetRoleFromInviteQuery(inviteId ?? '');
    const navigate = useNavigate();
    const [acceptInviteLink] = useAcceptInviteLinkMutation();

    const handleAcceptInvite = async () => {
        acceptInviteLink(inviteId ?? '')
            .unwrap()
            .then((response) => {
                successToast(response.message);
                navigate('/');
            })
            .catch((error) => {
                errorToastFromCatch(error);
                navigate('/');
            });
    };

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!data) {
        return <div>Error fetching role</div>;
    }

    return (
        <div className="flex flex-col items-center justify-center text-center min-h-screen">
            <PreviewImage
                url={
                    data.organization.pictureBlurhash
                        ? getOrganizationPictureSrc(data.organization.id)
                        : ORGANIZATION_PICTURE_PLACEHOLDER_SRC
                }
                blurHash={data.organization.pictureBlurhash ?? ORGANIZATION_PICTURE_PLACEHOLDER_BLURHASH}
                className="w-48 h-48 rounded-lg"
                aspectRatio={ORGANIZATION_BANNER_ASPECT_RATIO}
            />
            <h2 className="text-2xl">{data.organization.name}</h2>
            <h2 className="text-lg">has invited you to their organization</h2>
            <h2 className="text-lg">as a {data.title}</h2>
            <Button size="lg" className="self-center mt-4" onClick={handleAcceptInvite}>
                Accept
            </Button>
        </div>
    );
};
