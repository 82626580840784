export interface CreatePaymentIntent {
    currency: string;
    stripeVersion: string;
    referralCode?: string;
    confirmationTokenId?: string;
    data: {
        foodPreferences: string[];
        ticketId: string;
    }[];
}

export interface CreatePaymentIntentAdmin extends CreatePaymentIntent {
    userId: string;
}

export interface PaidPaymentIntentResponse {
    clientSecret: string;
    ephemeralKey: string;
    type: 'paid';
}

export interface FreePaymentIntentResponse {
    message: string;
    isFree: true;
    type: 'free';
}

export type PaymentIntentResponse = PaidPaymentIntentResponse | FreePaymentIntentResponse;

export function isPaidPaymentIntentResponse(response: PaymentIntentResponse): response is PaidPaymentIntentResponse {
    return response.type === 'paid';
}
