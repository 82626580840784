import type {
    EditOrganizationInput,
    Organization,
    OrganizationFilter,
    OrganizationInput,
    OrganizationSmall,
} from '@/types/Organization';
import { api } from './rootApi';
import { pageSizes, type Pagination } from '@/types/Pagination';
import type { RoleResponse } from '@/types/Role';
import type { PlaceResponse } from '@/types/Place';
import type { UploadBanner } from '@/types/Event';
import { imageToJPEG } from '@/lib/cropUtils';

export const organizationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getOrganizationById: builder.query<Organization, string>({
            query: (id) => `organizations/${id}`,
            providesTags: ['Organization'],
        }),
        editOrganization: builder.mutation<void, EditOrganizationInput>({
            query: ({ id, ...body }) => ({
                url: `organizations/${id}/edit`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Organization'],
        }),
        uploadOrganizationPicture: builder.mutation<void, UploadBanner>({
            query: (body) => {
                const formData = new FormData();
                formData.append('id', body.id);
                const bannerFile = imageToJPEG(body.banner, 'banner.jpeg');
                formData.append('banner', bannerFile);
                return {
                    url: `upload/organization/picture`,
                    method: 'POST',
                    body: formData,
                    prepareHeaders: (headers: { delete: (arg0: string) => void }) => {
                        headers.delete('Content-Type');
                        return headers;
                    },
                };
            },
        }),
        uploadOrganizationBanner: builder.mutation<void, UploadBanner>({
            query: (body) => {
                const formData = new FormData();
                formData.append('id', body.id);
                const bannerFile = imageToJPEG(body.banner, 'banner.jpeg');
                formData.append('banner', bannerFile);
                return {
                    url: `upload/organization/banner`,
                    method: 'POST',
                    body: formData,
                    prepareHeaders: (headers: { delete: (arg0: string) => void }) => {
                        headers.delete('Content-Type');
                        return headers;
                    },
                };
            },
        }),
        getOrganizations: builder.query<Pagination<OrganizationSmall>, OrganizationFilter>({
            query: ({ pageNumber = 0, pageSize = pageSizes.enormous }) =>
                `organizations?pageNumber=${pageNumber}&pageSize=${pageSize}`,
            providesTags: ['Organization'],
        }),
        getRolesByOrganizationId: builder.query<Pagination<RoleResponse>, string>({
            query: (id) => `organizations/${id}/roles?pageSize=${pageSizes.enormous}&pageNumber=0`,
            providesTags: ['Role'],
        }),
        getPlacesByOrganizationId: builder.query<PlaceResponse[], string>({
            query: (id) => `organizations/${id}/places`,
            providesTags: ['Place'],
        }),
        createOrganization: builder.mutation<Organization, OrganizationInput>({
            query: (body) => ({
                url: 'organizations',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Organization'],
        }),
        deleteOrganization: builder.mutation<void, string>({
            query: (organizationId) => ({
                url: `organizations/${organizationId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Organization'],
        }),
        followOrganization: builder.mutation<void, string>({
            query: (organizationId) => ({
                url: `organizations/${organizationId}/follow`,
                method: 'POST',
            }),
            invalidatesTags: ['Organization'],
        }),
        unfollowOrganization: builder.mutation<void, string>({
            query: (organizationId) => ({
                url: `organizations/${organizationId}/unfollow`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Organization'],
        }),
    }),
});

export const {
    useGetOrganizationByIdQuery,
    useEditOrganizationMutation,
    useUploadOrganizationPictureMutation,
    useUploadOrganizationBannerMutation,
    useGetOrganizationsQuery,
    useGetRolesByOrganizationIdQuery,
    useGetPlacesByOrganizationIdQuery,
    useCreateOrganizationMutation,
    useDeleteOrganizationMutation,
    useFollowOrganizationMutation,
    useUnfollowOrganizationMutation,
} = organizationApi;
