import { useAppSelector } from '@/hooks/store';
import type { OTPRequest } from '@/types/User';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from './ui/button';
import { Form } from './ui/form';
import { FormInput } from './ui/FormHelpers';

interface VerifyOtpFormProps {
    verify: (data: OTPRequest) => void;
    error?: string | undefined;
    isLoading?: boolean;
}

export default function VerifyOtpForm({ verify, error, isLoading }: VerifyOtpFormProps): JSX.Element {
    const user = useAppSelector((state) => state.auth.user);
    const form = useForm<{ otp: string }>();
    const errors = form.formState.errors;
    const loading = form.formState.isLoading || isLoading;

    useEffect(() => {
        if (error) {
            form.setError('otp', {
                type: 'server',
                message: error,
            });
        }
    }, [error]);

    if (!user) {
        return <div>Could not find user</div>;
    }

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit((data) => verify({ email: user.email, otp: data.otp }))}
                className="space-y-2 w-full"
            >
                <FormInput
                    control={form.control}
                    id="otp"
                    label="Enter your code"
                    placeholder="e.g. 420691"
                    error={errors.otp?.type === 'required' ? 'Sign in code is required' : undefined}
                />
                <Button isLoading={loading} type="submit" className="w-full">
                    Verify
                </Button>
                <p className="text-center text-lg pt-4">We have sent an email to you with a sign in code</p>
            </form>
        </Form>
    );
}
