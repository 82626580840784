import type { EditSeatInput } from '@/types/Seat';
import { api } from './rootApi';

export const seatsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        updateSeats: builder.mutation<{ status: number; message: string }, EditSeatInput>({
            query: (body) => ({
                url: `seats/edit`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['SeatMap'],
        }),
    }),
});

export const { useUpdateSeatsMutation } = seatsApi;
