import { useAppDispatch } from '@/hooks/store';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { setCurrentOrganization } from '@/features/currentOrganization/currentOrganizationSlice';
import { useAllOrganizations, useCurrentOrganization, useIsAdmin, useMyOrganizations } from '@/hooks/apiHooks';

export default function OrganizationSelector(): JSX.Element {
    const dispatch = useAppDispatch();
    const allOrganizations = useAllOrganizations();
    const myOrganizations = useMyOrganizations();
    const currentOrganization = useCurrentOrganization();
    const isAdmin = useIsAdmin();
    const organizations = isAdmin ? allOrganizations : myOrganizations;
    const fistOrganization = organizations[0];

    if (!currentOrganization) {
        return <p className="text-sm">No organization</p>;
    }

    return (
        <div>
            {organizations.length > 1 && fistOrganization ? (
                <Select
                    value={currentOrganization?.id}
                    onValueChange={(value) =>
                        dispatch(setCurrentOrganization(organizations.find((o) => o.id === value) ?? fistOrganization))
                    }
                >
                    <SelectTrigger className="border-0 hover:bg-accent">
                        <SelectValue placeholder="Select organization" />
                    </SelectTrigger>
                    <SelectContent>
                        {organizations.map((organization) => (
                            <SelectItem key={organization.id} value={organization.id}>
                                {organization.name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            ) : (
                <p className="text-sm">{currentOrganization?.name}</p>
            )}
        </div>
    );
}
