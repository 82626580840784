import EventCard from '@/components/Events/EventCard';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useGetEventsQuery } from '@/services/eventsApi';
import { PaginationSelect } from '@/components/Events/PaginationSelect';
import { useEffect, useMemo, useState } from 'react';
import { pageSizes } from '@/types/Pagination';
import { useSearchParams } from 'react-router-dom';
import { PageHeader } from '@/components/PageHeader';
import { Button } from '@/components/ui/button';
import { useMetaTags } from '@/hooks/metaDataHooks';

export const Events = (): JSX.Element => {
    const [search, setSearch] = useSearchParams();
    const [showOldEvents, setShowOldEvents] = useState(false);
    const [pagination, setPagination] = useState({
        pageNumber: search.get('pageNumber') ? Number(search.get('pageNumber')) : 1,
        pageSize: search.get('pageSize') ? Number(search.get('pageSize')) : pageSizes.large,
    });
    useMetaTags({ title: 'Events' });
    const now = useMemo(() => new Date(), []);
    const { data, isLoading } = useGetEventsQuery({
        pageNumber: pagination.pageNumber - 1,
        pageSize: pagination.pageSize,
        hideDrafts: true,
        highBoundaryEndDate: showOldEvents ? now.toISOString() : undefined,
        lowBoundaryEndDate: showOldEvents ? undefined : now.toISOString(),
    });

    useEffect(() => {
        setSearch(
            new URLSearchParams({
                pageNumber: pagination.pageNumber.toString(),
                pageSize: pagination.pageSize.toString(),
            }),
        );
    }, [pagination]);

    const handlePageChange = (newPage: number) => {
        setPagination({ pageNumber: newPage, pageSize: pagination.pageSize });
    };

    if (isLoading) {
        return <CenteredSpinner />;
    }

    if (!data) {
        return <div>Error fetching events</div>;
    }

    return (
        <div className="flex flex-col h-full w-full items-center">
            <PageHeader>
                <Button
                    onClick={() => setShowOldEvents(!showOldEvents)}
                    variant={showOldEvents ? 'default' : 'secondary'}
                    className="w-48 transition-none"
                >
                    {showOldEvents ? 'Show upcoming events' : 'Show old events'}
                </Button>
            </PageHeader>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 lg:max-w-7xl">
                {data.data.map((event) => (
                    <EventCard key={event.id} event={event} />
                ))}
            </div>
            <div className="m-4 mt-auto">
                <PaginationSelect
                    totalPageCount={data.totalElements / pagination.pageSize}
                    currentPage={pagination.pageNumber}
                    onPageChange={handlePageChange}
                    pageSize={pagination.pageSize}
                    setPageSize={(newPageSize) => setPagination({ pageNumber: 1, pageSize: newPageSize })}
                />
            </div>
        </div>
    );
};
