import { isKey, isNumber, isString } from '@/lib/typeNarrowing';

export interface LabbError {
    data: {
        status: number;
        message: string;
    };
}

export function isLabbError(err: unknown): err is LabbError {
    return (
        isKey(err, 'data') &&
        isKey(err.data, 'status') &&
        isKey(err.data, 'message') &&
        isString(err.data.message) &&
        isNumber(err.data.status)
    );
}
