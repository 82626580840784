import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import settingsReducer from '@/features/settings/settingsSlice';
import userReducer from '@/features/user/userSlice';
import currentOrganizationReducer from '@/features/currentOrganization/currentOrganizationSlice';
import { api } from '@/services/rootApi';
import { mapApi } from '@/services/mapApi';

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    [mapApi.reducerPath]: mapApi.reducer,
    auth: userReducer,
    settings: settingsReducer,
    currentOrganization: currentOrganizationReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(api.middleware)
            .concat(mapApi.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
