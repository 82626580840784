import type { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useIsAdmin, useIsRepresentative } from '@/hooks/apiHooks';
import { CenteredSpinner } from '@/components/ui/spinner';

export const RequireRepresentative = ({ children }: PropsWithChildren): React.ReactNode => {
    const isAdmin = useIsAdmin();
    const isRepresentative = useIsRepresentative();
    const location = useLocation();

    if (isAdmin === undefined || isRepresentative === undefined) {
        return (
            <div className="w-screen h-screen">
                <CenteredSpinner />
            </div>
        );
    }

    if (!isAdmin && !isRepresentative) {
        return <Navigate to="/not-representative" state={{ from: location }} replace />;
    }

    return children;
};
