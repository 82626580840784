import type { OrganizationInput } from '@/types/Organization';
import { type UseFormReturn } from 'react-hook-form';
import { FormInput, FormTextArea } from '../ui/FormHelpers';
import { Form } from '../ui/form';

interface Props {
    form: UseFormReturn<OrganizationInput, unknown, undefined>;
}

export const OrganizationForm = ({ form }: Props): JSX.Element => {
    const errors = form.formState.errors;
    const control = form.control;

    return (
        <div className="flex w-full flex-col items-center">
            <div className="max-w-5xl w-full p-8">
                <Form {...form}>
                    <form id="organization-form" className=" w-full bg-background rounded-lg p-8 flex flex-col">
                        <div className="space-y-4">
                            <div className="flex w-full gap-2">
                                <FormInput
                                    control={control}
                                    id="name"
                                    label="Name"
                                    error={errors.name?.type === 'required' ? 'Name is required' : undefined}
                                />
                                <FormInput
                                    control={control}
                                    id="shortName"
                                    label="Short name"
                                    error={errors.name?.type === 'required' ? 'ShortName is required' : undefined}
                                    maxLength={8}
                                />
                            </div>
                            <FormTextArea
                                control={control}
                                id="description"
                                label="Description"
                                error={errors.description?.type === 'required' ? 'Description is required' : undefined}
                            />
                            <div className="flex w-full gap-2">
                                <FormInput
                                    control={control}
                                    id="address"
                                    label="Address"
                                    error={errors.address?.type === 'required' ? 'Title is required' : undefined}
                                />
                                <FormInput
                                    control={control}
                                    id="zipCode"
                                    label="Zip code"
                                    error={errors.name?.type === 'required' ? 'Zip code is required' : undefined}
                                />
                            </div>
                            <div className="flex w-full gap-2">
                                <FormInput
                                    control={control}
                                    id="city"
                                    label="City"
                                    error={errors.name?.type === 'required' ? 'City is required' : undefined}
                                />

                                <FormInput
                                    control={control}
                                    id="country"
                                    label="Country"
                                    error={errors.name?.type === 'required' ? 'Country is required' : undefined}
                                />
                            </div>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    );
};
