import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { RootState } from '@/app/store';
import type { PersistPartial } from 'redux-persist/es/persistReducer';

interface SettingsState {
    isNavigationExpanded: boolean;
}

const persistConfig = {
    key: 'settings',
    storage,
};

const initialState: SettingsState = {
    isNavigationExpanded: true,
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setIsNavigationExpanded: (state, payloadAction: PayloadAction<boolean>) => {
            state.isNavigationExpanded = payloadAction.payload;
        },
        toogleIsNavigationExpanded: (state) => {
            state.isNavigationExpanded = !state.isNavigationExpanded;
        },
    },
});

export const { setIsNavigationExpanded, toogleIsNavigationExpanded } = settingsSlice.actions;
export const selectSettings = (state: RootState): SettingsState & PersistPartial => state.settings;
export default persistReducer(persistConfig, settingsSlice.reducer);
