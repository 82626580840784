import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { RootState } from '@/app/store';
import type { PersistPartial } from 'redux-persist/es/persistReducer';
import type { OrganizationSmall } from '@/types/Organization';

interface CurrentOrganizationState {
    currentOrganization: OrganizationSmall | undefined;
}

const persistConfig = {
    key: 'currentOrganization',
    storage,
};

const initialState: CurrentOrganizationState = {
    currentOrganization: undefined,
};

const currentOrganizationSlice = createSlice({
    name: 'currentOrganization',
    initialState,
    reducers: {
        setCurrentOrganization(state, action: PayloadAction<OrganizationSmall>) {
            state.currentOrganization = action.payload;
        },
    },
});

export const { setCurrentOrganization } = currentOrganizationSlice.actions;
export const selectCurrentOrganization = (state: RootState): CurrentOrganizationState & PersistPartial =>
    state.currentOrganization;
export default persistReducer(persistConfig, currentOrganizationSlice.reducer);
