import { Step, Stepper } from '@/components/ui/Stepper';
import { useState } from 'react';
import Dropzone from '@/components/ui/Dropzone';
import { Button } from '@/components/ui/button';
import CropperDialog from '@/components/ui/CropperDialog';
import type { EventItemInput } from '@/types/EventItem';
import { EventItems } from '@/components/Events/EventItems';
import type { EventInput, CreateEventInput } from '@/types/Event';
import { EventDetailsForm } from '@/components/Events/EventDetailsForm';
import { formatDateTimeForForm, toISO } from '@/lib/utils';
import { useCreateEventMutation, useUploadBannerMutation } from '@/services/eventsApi';
import { useCurrentOrganization, useCurrentOrganizationPlaces } from '@/hooks/apiHooks';
import { useNavigate } from 'react-router-dom';
import { useCreateEventItemMutation } from '@/services/eventItemApi';
import { errorToast, errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { useForm } from 'react-hook-form';
import { EventPreview } from '@/components/Events/EventPreview';
import { Popover, PopoverTrigger } from '@/components/ui/popover';
import { RotateCwIcon } from 'lucide-react';
import { RecurringForm, type RecurringFormData, calculateDates } from '@/components/Events/RecurringFrom';
import type { OptionType } from '@/components/ui/multi-select';

export const CreateEvent = (): JSX.Element => {
    const [image, setImage] = useState<string | null>(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [eventInput, setEventInput] = useState<EventInput | undefined>(undefined);
    const [eventItems, setEventItems] = useState<EventItemInput[]>([]);
    const [postEvent, postEventState] = useCreateEventMutation();
    const [postEventItem, postEventItemState] = useCreateEventItemMutation();
    const [uploadBanner, uploadBannerState] = useUploadBannerMutation();
    const [isRecurring, setIsRecurring] = useState(false);
    const [recurringFormData, setRecurringFormData] = useState<RecurringFormData>();
    const { places } = useCurrentOrganizationPlaces();
    const organizationId = useCurrentOrganization()?.id;
    const navigate = useNavigate();
    const defaultPlace = places.find((place) => place.id === eventInput?.placeId);
    const [recurringPopoverOpen, setRecurringPopoverOpen] = useState(false);
    const [hasPublishDate, setHasPublishDate] = useState(!!eventInput?.publishDate);
    const [isDraft, setIsDraft] = useState(eventInput?.publishDate === null);
    const [hasSeatMap, setHasSeatMap] = useState(eventInput?.seatMapId !== undefined);
    const responsibleUsersState = useState<OptionType[]>([]);
    const [responsibleUsers] = responsibleUsersState;

    const handleEventDetailsSubmit = (data: EventInput) => {
        const filteredData: EventInput = {
            ...data,
            endDate: toISO(data.endDate),
            publishDate: isDraft ? null : hasPublishDate ? toISO(data.publishDate) : new Date().toISOString(),
            startDate: toISO(data.startDate),
            responsibleUserIds: responsibleUsers.map((u) => u.value),
        };
        if (isDraft) {
            setHasPublishDate(false);
        }
        setEventInput(filteredData);
    };

    const submitEventDetails = async () => {
        await form.trigger();
        if (form.formState.isValid) {
            form.handleSubmit(handleEventDetailsSubmit)();
        }
    };

    const defaultValues = {
        description: eventInput?.description,
        endDate: formatDateTimeForForm(eventInput?.endDate),
        placeId: eventInput?.placeId ?? defaultPlace?.id,
        publishDate: formatDateTimeForForm(eventInput?.publishDate),
        seatCount: eventInput?.seatCount,
        startDate: formatDateTimeForForm(eventInput?.startDate),
        responsibleUserIds: eventInput?.responsibleUserIds,
        title: eventInput?.title,
        seatMapId: eventInput?.seatMapId,
    };
    const form = useForm<EventInput>({
        defaultValues: defaultValues,
    });

    function onCropped(imagePromise: Promise<string>) {
        imagePromise.then((img) => {
            setCroppedImage(img);
            setImage(null);
        });
    }

    const onSubmit = async () => {
        await submitEventDetails();
        if (!eventInput || !form.formState.isValid) {
            errorToast('All fields must be filled out');
            setCurrentStep(0);
            return;
        }
        if (!organizationId) {
            errorToast('No organization ID');
            return;
        }
        const recurringDates =
            isRecurring && recurringFormData ? calculateDates(recurringFormData, eventInput.startDate) : undefined;
        const createEvent: CreateEventInput = {
            ...eventInput,
            organizationId: organizationId,
            recurringDates: recurringDates,
        };

        postEvent(createEvent)
            .unwrap()
            .then((response) => {
                return Promise.all(
                    response.flatMap((event) => {
                        const eventItemPromises = eventItems.map((item) => postEventItem({ ...item, event: event.id }));
                        const bannerPromise = croppedImage
                            ? uploadBanner({ id: event.id, banner: croppedImage })
                            : Promise.resolve();
                        return [...eventItemPromises, bannerPromise];
                    }),
                );
            })
            .then(() => {
                successToast('Event created successfully');
                navigate('/admin/events');
            })
            .catch(errorToastFromCatch);
    };

    const setNextStep = () => {
        setCurrentStep((prev) => prev + 1);
    };

    const setEventItem = (eventItem: EventItemInput, index: number | undefined) => {
        if (index === undefined) {
            setEventItems([...eventItems, eventItem]);
        } else {
            const newEventItems = [...eventItems];
            newEventItems[index] = eventItem;
            setEventItems(newEventItems);
        }
    };
    const removeEventItem = (index: number) => {
        const newEventItems = [...eventItems];
        newEventItems.splice(index, 1);
        setEventItems(newEventItems);
    };

    const recurringOnClick = async () => {
        const isOpen = recurringPopoverOpen;
        await submitEventDetails();
        if (!eventInput || !form.formState.isValid) {
            errorToast('All fields must be filled out');
            setCurrentStep(0);
            return;
        } else if (!isOpen) {
            setRecurringPopoverOpen(true);
        }
    };

    return (
        <>
            <div className="w-full relative bg-primary-foreground min-h-full hidden md:flex">
                <Stepper currentStep={currentStep} setCurrentStep={setCurrentStep} onClick={submitEventDetails}>
                    <Step
                        name="Information"
                        headerItems={
                            <Button
                                onClick={() => {
                                    setNextStep();
                                    submitEventDetails();
                                }}
                            >
                                Next
                            </Button>
                        }
                    >
                        <EventDetailsForm
                            form={form}
                            setIsDraft={setIsDraft}
                            isDraft={isDraft}
                            setHasPublishDate={setHasPublishDate}
                            hasPublishDate={hasPublishDate}
                            hasSeatMap={hasSeatMap}
                            setHasSeatMap={setHasSeatMap}
                            selectedUsersState={responsibleUsersState}
                        />
                    </Step>
                    <Step name="Prices" headerItems={<Button onClick={setNextStep}>Next</Button>}>
                        <div className="p-6">
                            <EventItems
                                eventItems={eventItems}
                                setEventItem={setEventItem}
                                removeEventItem={removeEventItem}
                                publishDate={eventInput?.publishDate ?? undefined}
                                endDate={eventInput?.endDate}
                            />
                        </div>
                    </Step>
                    <Step name="Upload Image" headerItems={<Button onClick={setNextStep}>Next</Button>}>
                        <div className="p-4">
                            {croppedImage ? (
                                <div className="w-full flex flex-col gap-4">
                                    <img src={croppedImage} alt="event banner" />
                                    <Button onClick={() => setCroppedImage(null)}>Upload another one</Button>
                                </div>
                            ) : (
                                <Dropzone
                                    text="Click to upload or drag and drop the banner here"
                                    setFile={(file) => setImage(file.data)}
                                />
                            )}

                            <CropperDialog onCropped={onCropped} image={image} setImage={setImage} />
                        </div>
                    </Step>
                    <Step
                        name="Preview"
                        headerItems={
                            <div className="flex gap-2">
                                <Popover
                                    onOpenChange={(isOpen) => !isOpen && !recurringFormData && setIsRecurring(false)}
                                    open={recurringPopoverOpen}
                                >
                                    <PopoverTrigger asChild>
                                        <Button
                                            variant="outline"
                                            className="disabled:cursor-not-allowed"
                                            onClick={recurringOnClick}
                                        >
                                            <RotateCwIcon className="w-5 h-5 mr-2" />
                                            {recurringFormData && isRecurring
                                                ? `${recurringFormData.occurrences} occurrences`
                                                : 'Does not repeat'}
                                        </Button>
                                    </PopoverTrigger>
                                    {eventInput?.startDate && (
                                        <RecurringForm
                                            formData={recurringFormData}
                                            setFormData={setRecurringFormData}
                                            isRecurring={isRecurring}
                                            setIsRecurring={setIsRecurring}
                                            closePopover={() => setRecurringPopoverOpen(false)}
                                            dayOfWeek={new Date(eventInput?.startDate ?? '').getDay()}
                                        />
                                    )}
                                </Popover>

                                <Button
                                    onClick={onSubmit}
                                    isLoading={
                                        postEventState.isLoading ||
                                        postEventItemState.isLoading ||
                                        uploadBannerState.isLoading ||
                                        form.formState.isSubmitting
                                    }
                                    className="disabled:cursor-not-allowed"
                                >
                                    Publish
                                </Button>
                            </div>
                        }
                    >
                        <div className="w-full flex justify-center p-6">
                            <div className="space-y-2">
                                <EventPreview eventInput={eventInput} croppedImage={croppedImage} />
                                <p className="text-lg font-semibold">Ticket types</p>
                                <EventItems
                                    eventItems={eventItems}
                                    setEventItem={setEventItem}
                                    removeEventItem={removeEventItem}
                                    publishDate={eventInput?.publishDate ?? undefined}
                                    endDate={eventInput?.endDate}
                                />
                            </div>
                        </div>
                    </Step>
                </Stepper>
            </div>
            <div className="w-full h-full md:hidden flex items-center justify-center flex-col p-4 gap-4">
                <div className="text-l font-semibold text-center">Sorry, you need a bigger screen</div>
                <img src="/images/too-thin-screen.webp" className="w-72 rounded-lg" alt="too thin screen" />
                <Button onClick={() => navigate('/admin/events')}>Go back</Button>
            </div>
        </>
    );
};
