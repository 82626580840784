import type { ColumnDef } from '@tanstack/react-table';

import { DataTableColumnHeader } from '@/components/dataTable/DataTableColumnHeader';
import type { UserResponse } from '@/types/User';

export const columns: ColumnDef<UserResponse>[] = [
    {
        accessorKey: 'id',
        header: ({ column }) => <DataTableColumnHeader column={column} title="ID" />,
        enableSorting: false,
    },
    {
        accessorKey: 'email',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
        enableSorting: false,
    },
    {
        accessorKey: 'firstName',
        header: ({ column }) => <DataTableColumnHeader column={column} title="First Name" />,
        enableSorting: false,
    },
    {
        accessorKey: 'lastName',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Last Name" />,
        enableSorting: false,
    },
];
