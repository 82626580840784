import { api } from './rootApi';
import type { CreatePaymentIntent, CreatePaymentIntentAdmin, PaymentIntentResponse } from '@/types/Payment';

export const paymentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createPaymentIntent: builder.mutation<PaymentIntentResponse, CreatePaymentIntent>({
            query: (body) => ({
                url: `payments/intent`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['UserTicket'],
        }),
        createAdminPaymentIntent: builder.mutation<PaymentIntentResponse, CreatePaymentIntentAdmin>({
            query: (body) => ({
                url: `payments/intent/admin`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useCreatePaymentIntentMutation, useCreateAdminPaymentIntentMutation } = paymentApi;
