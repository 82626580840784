import {
    AlertDialog,
    AlertDialogTrigger,
    AlertDialogContent,
    AlertDialogTitle,
    AlertDialogDescription,
    AlertDialogCancel,
    AlertDialogHeader,
    AlertDialogFooter,
} from './alert-dialog';
import { Button } from './button';
import { errorToastFromCatch, successToast } from './use-toast';
import { useState } from 'react';
import { BasicTooltip } from './tooltip';

interface Props {
    isLoading: boolean;
    deleteItem: () => Promise<unknown>;
    disabled?: boolean;
    disabledMessage?: string;
    successMessage?: string;
    children: JSX.Element;
}

export const DeleteDialog = ({
    isLoading,
    deleteItem,
    disabled,
    successMessage,
    disabledMessage,
    children,
}: Props): JSX.Element => {
    const [open, setOpen] = useState(false);

    const handleDelete = () =>
        deleteItem()
            .then(() => {
                successToast(successMessage ?? 'Item deleted successfully');
                setOpen(false);
            })
            .catch(errorToastFromCatch);

    if (disabled) {
        return (
            <div onClick={(e) => e.stopPropagation()}>
                <BasicTooltip tooltip={disabledMessage ?? 'This item cannot be deleted'} side="bottom">
                    <Button variant="destructive" disabled className="cursor-not-allowed">
                        Delete
                    </Button>
                </BasicTooltip>
            </div>
        );
    }

    return (
        <div
            // This needs to be here to prevent the click event from propagating and link in the parent component being clicked
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <AlertDialog open={open} onOpenChange={setOpen}>
                <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone. This will permanently delete this item.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <Button variant="destructive" onClick={handleDelete} isLoading={isLoading}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
};
