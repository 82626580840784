import { Button } from '../ui/button';
import { useForm } from 'react-hook-form';
import { Form } from '../ui/form';
import { FormInput, FormTextArea } from '../ui/FormHelpers';

import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import type { RoleFormInput } from '@/types/Role';
import { errorToastFromCatch, successToast } from '../ui/use-toast';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface RoleSheetProps {
    onSubmit: (data: RoleFormInput) => Promise<unknown>;
    isLoading: boolean;
    role?: RoleFormInput;
    children: React.ReactNode;
}

export const RoleSheet = (props: RoleSheetProps): JSX.Element => {
    const { onSubmit, role, isLoading } = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const form = useForm<RoleFormInput>({
        defaultValues: {
            title: role?.title,
            description: role?.description,
        },
    });
    const handleSubmit = form.handleSubmit;
    const errors = form.formState.errors;
    const control = form.control;

    const handleFormSubmit = (data: RoleFormInput) => {
        onSubmit(data)
            .then(() => {
                successToast('Role saved successfully');
                form.reset();
                setOpen(false);
                navigate('/admin/roles');
            })
            .catch(errorToastFromCatch);
    };

    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <SheetTrigger asChild>{props.children}</SheetTrigger>
            </div>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Role Information</SheetTitle>
                    <div className="grid gap-4 py-4">
                        <Form {...form}>
                            <form onSubmit={handleSubmit(handleFormSubmit)}>
                                <div className="space-y-4">
                                    <FormInput
                                        control={control}
                                        id="title"
                                        label="Title"
                                        error={errors.title?.type === 'required' ? 'Title is required' : undefined}
                                    />
                                </div>
                                <div className="space-y-4">
                                    <FormTextArea
                                        control={control}
                                        id="description"
                                        label="Description"
                                        error={
                                            errors.description?.type === 'required'
                                                ? 'Description is required'
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className="mt-4">
                                    <Button variant="default" type="submit" isLoading={isLoading}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </Form>
                    </div>
                </SheetHeader>
            </SheetContent>
        </Sheet>
    );
};
