import { useParams } from 'react-router-dom';
import { CenteredSpinner } from '@/components/ui/spinner';
import { DataTable } from '@/components/dataTable/DataTable';
import { useGetRoleUsersQuery } from '@/services/rolesApi';
import { AddUserRole } from '@/components/Roles/AddUserRole';
import { CreateInviteLink } from '@/components/Roles/CreateInviteLink';

import type { User } from '@/types/User';
export default function Role(): JSX.Element {
    const { id: roleId } = useParams<{ id: string }>();
    const { data, isLoading } = useGetRoleUsersQuery(roleId);

    const userColumns = [
        {
            id: 'firstName',
            header: 'First Name',
            cell: (context: { row: { original: User } }) => context.row.original.firstName,
        },
        {
            id: 'lastName',
            header: 'Last Name',
            cell: (context: { row: { original: User } }) => context.row.original.lastName,
        },
        {
            id: 'email',
            header: 'Email',
            cell: (context: { row: { original: User } }) => context.row.original.email,
        },
    ];

    return (
        <div className="w-full">
            {isLoading ? (
                <CenteredSpinner />
            ) : (
                <>
                    <div className="flex justify-between p-4 pb-0">
                        <h1 className="text-2xl font-semibold">{data?.role.title}</h1>
                        <div className="flex flex-row gap-2">
                            {data?.role.roleType === 'CUSTOM' && (
                                <CreateInviteLink roleId={roleId || ''} roleName={data?.role.title} />
                            )}
                            <AddUserRole roleId={roleId || ''} />
                        </div>
                    </div>
                    {data && (
                        <DataTable
                            columns={userColumns}
                            data={{
                                data: data.users,
                                totalElements: data.users.length,
                                pageNumber: 0,
                                pageSize: data.users.length,
                            }}
                            pagination={{ pageIndex: 0, pageSize: data.users.length }}
                            setPagination={() => {}}
                            columnFilters={[]}
                            setColumnFilters={() => {}}
                            pageCount={1}
                        />
                    )}
                </>
            )}
        </div>
    );
}
