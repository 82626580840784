import type { OrganizationInput } from '@/types/Organization';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/ui/button';
import { FormInput, FormTextArea } from '../../components/ui/FormHelpers';
import { Form } from '../../components/ui/form';
import { errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { useCreateOrganizationMutation } from '@/services/organizationApi';
import { useNavigate } from 'react-router-dom';

export const CreateOrganization = (): JSX.Element => {
    const defaultValues: OrganizationInput = {
        address: '',
        city: 'Lund',
        country: 'Sweden',
        description: '',
        name: '',
        shortName: '',
        zipCode: '',
    };
    const form = useForm<OrganizationInput>({
        defaultValues: defaultValues,
    });
    const handleSubmit = form.handleSubmit;
    const errors = form.formState.errors;
    const control = form.control;
    const [createOrganization, createOrganizationState] = useCreateOrganizationMutation();
    const navigate = useNavigate();

    return (
        <div className="flex w-full flex-col items-center">
            <div className="max-w-5xl w-full">
                <h1 className="text-2xl font-semibold p-4 pb-0 w-full">Create Organization</h1>
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit((data) => {
                            createOrganization(data)
                                .unwrap()
                                .then((org) => {
                                    successToast(`Organization ${org.name} created successfully`);
                                    navigate('/admin/organizations');
                                })
                                .catch(errorToastFromCatch);
                        })}
                        className=" w-full bg-background rounded-lg p-8 flex flex-col"
                    >
                        <div className="space-y-4">
                            <div className="flex w-full gap-2">
                                <FormInput
                                    control={control}
                                    id="name"
                                    label="Name"
                                    error={errors.name?.type === 'required' ? 'Name is required' : undefined}
                                />
                                <FormInput
                                    control={control}
                                    id="shortName"
                                    label="Short name"
                                    error={errors.name?.type === 'required' ? 'ShortName is required' : undefined}
                                />
                            </div>
                            <FormTextArea
                                control={control}
                                id="description"
                                label="Description"
                                error={errors.description?.type === 'required' ? 'Description is required' : undefined}
                            />
                            <div className="flex w-full gap-2">
                                <FormInput
                                    control={control}
                                    id="address"
                                    label="Address"
                                    error={errors.address?.type === 'required' ? 'Title is required' : undefined}
                                />
                                <FormInput
                                    control={control}
                                    id="zipCode"
                                    label="Zip code"
                                    error={errors.name?.type === 'required' ? 'Zip code is required' : undefined}
                                />
                            </div>
                            <div className="flex w-full gap-2">
                                <FormInput
                                    control={control}
                                    id="city"
                                    label="City"
                                    error={errors.name?.type === 'required' ? 'City is required' : undefined}
                                />

                                <FormInput
                                    control={control}
                                    id="country"
                                    label="Country"
                                    error={errors.name?.type === 'required' ? 'Country is required' : undefined}
                                />
                            </div>
                        </div>
                        <Button className="my-3 w-96 self-center" isLoading={createOrganizationState.isLoading}>
                            Create
                        </Button>
                    </form>
                </Form>
            </div>
        </div>
    );
};
