import { CenteredSpinner } from '@/components/ui/spinner';
import { useNavigate, useParams } from 'react-router-dom';
import SeatMap from '@/components/Seats/SeatMap';
import { useDeleteSeatMapMutation, useGetSeatMapByIdQuery } from '@/services/seatMapApi';
import { PageHeader } from '@/components/PageHeader';
import { Button } from '@/components/ui/button';
import { ArrowLeftIcon } from 'lucide-react';
import { DeleteDialog } from '@/components/ui/DeleteDialog';

export default function SeatMapAdminRoute(): JSX.Element {
    const navigate = useNavigate();
    const { seatMapId } = useParams() as { seatMapId: string };
    const { data, isLoading } = useGetSeatMapByIdQuery(seatMapId);
    const [deleteSeatMap, { isLoading: deleteLoading }] = useDeleteSeatMapMutation();

    if (isLoading || !data) {
        return <CenteredSpinner />;
    }

    return (
        <>
            <PageHeader>
                <Button variant="ghost" className="gap-2" onClick={() => navigate('/admin/seat-maps')}>
                    <ArrowLeftIcon className="h-5 w-5" /> <p>Back to seat maps</p>
                </Button>
                <DeleteDialog
                    isLoading={deleteLoading}
                    deleteItem={() =>
                        deleteSeatMap(seatMapId)
                            .unwrap()
                            .then(() => {
                                navigate('/admin/seat-maps');
                            })
                    }
                >
                    <Button variant="destructive">Delete</Button>
                </DeleteDialog>
            </PageHeader>
            <div className="h-[calc(100%-56px)] w-full p-4">
                <SeatMap seatMap={data} readOnly />
            </div>
        </>
    );
}
