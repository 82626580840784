import type { EventSmall } from './Event';
import type { Role } from './Role';

export interface EventItemSmall {
    id: string;
    name: string;
    eventId: string;
    eventName: string;
    price: number;
    limit: number | null;
    releaseDate: string;
    expirationDate: string;
    lastSwitchDate: string | null;
    ticketsLeftUser: number | null;
    purchasePermittedRoles: Role[];
    checkPurchasePermissions: boolean;
    hasMinimumPrice: boolean;
}

export const eventItemToEventItemInput = (eventItem: EventItemSmall): EventItemInput => {
    return {
        expirationDate: eventItem.expirationDate,
        lastSwitchDate: eventItem.lastSwitchDate,
        limit: eventItem.limit,
        name: eventItem.name,
        price: eventItem.price,
        releaseDate: eventItem.releaseDate,
        purchasePermittedRoleIds: eventItem.purchasePermittedRoles.map((role) => role.id),
        checkPurchasePermissions: eventItem.purchasePermittedRoles.length > 0,
        hasMinimumPrice: eventItem.hasMinimumPrice,
    };
};

export interface EventItemInput {
    expirationDate: string;
    lastSwitchDate: string | null;
    limit: number | null;
    name: string;
    price: number;
    releaseDate: string;
    purchasePermittedRoleIds: string[];
    checkPurchasePermissions: boolean;
    hasMinimumPrice: boolean;
}

export interface PutEventItem extends EventItemInput {
    id: string;
}

export interface CreateEventItem extends EventItemInput {
    event: string;
}

export interface PostEventItemResponse {
    checkPurchasePermissions: boolean;
    event: EventSmall;
    expirationDate: string;
    id: string;
    lastSwitchDate: string | null;
    limit: number;
    name: string;
    price: number;
    releaseDate: string;
    ticketsLeftUser: number;
    purchasePermittedRoles: Role[];
    hasMinimumPrice: boolean;
}

export const postResponseToEventItem = (response: PostEventItemResponse): EventItemSmall => ({
    id: response.id,
    name: response.name,
    eventId: response.event.id,
    eventName: response.event.title,
    price: response.price,
    limit: response.limit,
    releaseDate: response.releaseDate,
    expirationDate: response.expirationDate,
    lastSwitchDate: response.lastSwitchDate,
    purchasePermittedRoles: response.purchasePermittedRoles,
    checkPurchasePermissions: response.checkPurchasePermissions,
    ticketsLeftUser: response.ticketsLeftUser,
    hasMinimumPrice: response.hasMinimumPrice,
});
