import { api } from './rootApi';
import type { Stat, StatFilter } from '@/types/Stat';

export const statApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getStats: builder.query<Stat, StatFilter>({
            query: (filter) =>
                `/stats?${filter.eventId ? `?eventId=${filter.eventId}` : ''}${
                    filter.organizationId ? `&organizationId=${filter.organizationId}` : ''
                }${filter.fromDate ? `&fromDate=${filter.fromDate}` : ''}${
                    filter.endDate ? `&endDate=${filter.endDate}` : ''
                }${filter.timeGroup ? `&timeGroup=${filter.timeGroup.toString()}` : ''}`,
            providesTags: ['Stat'],
        }),
    }),
});

export const { useGetStatsQuery } = statApi;
