import { useGetAllReferralsQuery } from '@/services/referralApi';
import { Button } from '@/components/ui/button';
import { PageHeader } from '@/components/PageHeader';
import { Plus } from 'lucide-react';
import ReferralCard from '@/components/Referral/ReferralCard';
import { AddReferral } from '@/components/Referral/AddReferral';
import { useCurrentOrganization } from '@/hooks/apiHooks';

export default function Referrals(): JSX.Element {
    const organizationId = useCurrentOrganization()?.id;
    const { data } = useGetAllReferralsQuery({ organizationId: organizationId });

    return (
        <div className="flex flex-col items-center">
            <PageHeader>
                <AddReferral>
                    <Button>
                        <Plus className="h-5 w-5 mr-2" />
                        <p>Create New Referral Campaign</p>
                    </Button>
                </AddReferral>
            </PageHeader>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 lg:max-w-7xl mx-auto">
                {data?.map((referral) => <ReferralCard key={referral.id} campaign={referral} />)}
            </div>
        </div>
    );
}
