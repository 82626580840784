import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@/components/ui/spinner';
import { Step, Stepper } from '@/components/ui/Stepper';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import Dropzone from '@/components/ui/Dropzone';
import CropperDialog from '@/components/ui/CropperDialog';
import {
    organizationToOrganizationInput,
    type Organization,
    type OrganizationInput,
    type EditOrganizationInput,
} from '@/types/Organization';
import { useCurrentOrganization } from '@/hooks/apiHooks';
import {
    useEditOrganizationMutation,
    useGetOrganizationByIdQuery,
    useUploadOrganizationBannerMutation,
    useUploadOrganizationPictureMutation,
} from '@/services/organizationApi';
import { OrganizationForm } from '@/components/Organizations/OrganizationForm';
import { errorToast, errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { OrganizationInfo } from '@/components/Organizations/OrganizationInfo';

interface Props {
    organization: Organization;
}
const EventDetailsWithData = ({ organization }: Props): JSX.Element => {
    const [currentStep, setCurrentStep] = useState(0);
    const [croppedBanner, setCroppedBanner] = useState<string | null>(null);
    const [croppedPicture, setCroppedPicture] = useState<string | null>(null);
    const [banner, setBanner] = useState<string | null>(null);
    const [picture, setPicture] = useState<string | null>(null);
    const [bannerSrc, setBannerSrc] = useState(
        `${import.meta.env.VITE_IMAGE_STORAGE_URL}/organization-banners/${organization.id}/large`,
    );
    const [pictureSrc, setPictureSrc] = useState(
        `${import.meta.env.VITE_IMAGE_STORAGE_URL}/organization-pictures/${organization.id}/large`,
    );
    const [organizationInput, setOrganizationInput] = useState(organizationToOrganizationInput(organization));
    const [editOrganization, editOrganizationState] = useEditOrganizationMutation();
    const [uploadBanner, uploadBannerState] = useUploadOrganizationBannerMutation();
    const [uploadPicture, uploadPictureState] = useUploadOrganizationPictureMutation();
    const navigate = useNavigate();

    const defaultValues = {
        description: organizationInput?.description,
        name: organizationInput?.name,
        shortName: organizationInput?.shortName,
        address: organizationInput?.address,
        zipCode: organizationInput?.zipCode,
        city: organizationInput?.city,
        country: organizationInput?.country,
    };

    const onBannerCropped = (imagePromise: Promise<string>) => {
        imagePromise.then((img) => {
            setCroppedBanner(img);
            setBanner(null);
        });
    };

    const onPictureCropped = (imagePromise: Promise<string>) => {
        imagePromise.then((img) => {
            setCroppedPicture(img);
            setPicture(null);
        });
    };

    const form = useForm<OrganizationInput>({
        defaultValues: defaultValues,
    });

    const submitForm = async () => {
        await form.trigger();
        if (form.formState.isValid) {
            form.handleSubmit((orgData) => setOrganizationInput(orgData))();
        }
    };

    const onPublishChanges = async () => {
        await submitForm();
        if (!organizationInput || !form.formState.isValid) {
            errorToast('All fields must be filled out');
            setCurrentStep(0);
            return;
        }
        const editOrganizationData: EditOrganizationInput = {
            ...organizationInput,
            id: organization.id,
        };
        const bannerPromise = croppedBanner
            ? uploadBanner({ id: organization.id, banner: croppedBanner }).unwrap()
            : Promise.resolve();

        const picturePromise = croppedPicture
            ? uploadPicture({ id: organization.id, banner: croppedPicture }).unwrap()
            : Promise.resolve();
        Promise.all([editOrganization(editOrganizationData).unwrap(), bannerPromise, picturePromise])
            .then(() => {
                successToast('Organization updated');
                navigate(`/admin/organization`);
            })
            .catch(errorToastFromCatch);
    };

    return (
        <div className="w-full relative bg-primary-foreground h-full">
            <Stepper currentStep={currentStep} setCurrentStep={setCurrentStep} onClick={submitForm}>
                <Step
                    name="Edit organization details"
                    headerItems={
                        <Button
                            onClick={() => {
                                setCurrentStep(1);
                                submitForm();
                            }}
                        >
                            Next
                        </Button>
                    }
                >
                    <OrganizationForm form={form} />
                </Step>
                <Step name="Edit banner" headerItems={<Button onClick={() => setCurrentStep(2)}>Next</Button>}>
                    <div className="p-4 w-full flex flex-col items-center">
                        <div className="w-full flex flex-col gap-4 max-w-6xl">
                            <img
                                src={croppedBanner ?? bannerSrc}
                                onError={() => setBannerSrc('/images/banner-placeholder.svg')}
                            />
                            <Dropzone
                                text="Drag 'n' drop the banner, or click to edit banner"
                                setFile={(file) => setBanner(file.data)}
                            />
                        </div>
                        <CropperDialog onCropped={onBannerCropped} image={banner} setImage={setBanner} />
                    </div>
                </Step>
                <Step name="Edit picture" headerItems={<Button onClick={() => setCurrentStep(3)}>Next</Button>}>
                    <div className="p-4 w-full flex flex-col items-center">
                        <div className="w-full flex flex-col gap-4 max-w-md">
                            <img
                                src={croppedPicture ?? pictureSrc}
                                onError={() => setPictureSrc('/images/picture-placeholder.svg')}
                            />
                            <Dropzone
                                text="Drag 'n' drop the picture, or click to add picture"
                                setFile={(file) => setPicture(file.data)}
                            />
                        </div>
                        <CropperDialog onCropped={onPictureCropped} image={picture} setImage={setPicture} aspect={1} />
                    </div>
                </Step>
                <Step
                    name="Publish changes"
                    headerItems={
                        <Button
                            onClick={onPublishChanges}
                            isLoading={
                                editOrganizationState.isLoading ||
                                uploadBannerState.isLoading ||
                                uploadPictureState.isLoading
                            }
                        >
                            Publish changes
                        </Button>
                    }
                >
                    <div className="flex flex-col items-center w-full h-full">
                        <OrganizationInfo
                            organization={organizationInput}
                            picture={croppedPicture ?? pictureSrc}
                            banner={croppedBanner ?? bannerSrc}
                            setBannerSrc={setBannerSrc}
                            setPictureSrc={setPictureSrc}
                            isEditing
                        />
                    </div>
                </Step>
            </Stepper>
        </div>
    );
};

// This is here to make sure that the organization is loaded before the component is rendered
// This is needed because useForm hook cannot be called conditionally and need the default values
export const EditOrganization = (): JSX.Element => {
    const currentOrgId = useCurrentOrganization()?.id;
    const { data, isLoading } = useGetOrganizationByIdQuery(currentOrgId ?? '', {
        skip: !currentOrgId,
    });
    if (isLoading || !data) {
        return (
            <div className="flex justify-center items-center h-full w-full">
                <Spinner size="lg" />
            </div>
        );
    }
    return <EventDetailsWithData organization={data} />;
};
