import type { EventInput } from '@/types/Event';
import { type UseFormReturn } from 'react-hook-form';
import { useCurrentOrganizationPlaces } from '@/hooks/apiHooks';
import type { PlaceResponse } from '@/types/Place';
import { FormDatePicker, FormInput, FormSelect, FormTextArea } from '../ui/FormHelpers';
import { Form, FormLabel } from '../ui/form';
import { Switch } from '../ui/Switch';
import { cn } from '@/lib/utils';
import { useGetSeatMapsQuery } from '@/services/seatMapApi';
import type { SeatMapSmall } from '@/types/SeatMap';
import { UserMultiSelect } from '../UserMultiSelect';
import type { OptionType } from '../ui/multi-select';

interface Props {
    form: UseFormReturn<EventInput, unknown, undefined>;
    defaultPlace?: PlaceResponse;
    setHasPublishDate: (hasPublishDate: boolean) => void;
    hasPublishDate: boolean;
    setIsDraft: (isDraft: boolean) => void;
    isDraft: boolean;
    selectedUsersState: [OptionType[], (users: OptionType[]) => void];
    isEdit?: boolean;
    defaultSeatMap?: SeatMapSmall;
    hasSeatMap: boolean;
    setHasSeatMap?: (hasSeatMap: boolean) => void;
}

export const EventDetailsForm = ({
    form,
    defaultPlace,
    setHasPublishDate,
    hasPublishDate,
    isEdit,
    setIsDraft,
    isDraft,
    defaultSeatMap,
    hasSeatMap,
    setHasSeatMap,
    selectedUsersState,
}: Props): JSX.Element => {
    const { places } = useCurrentOrganizationPlaces();
    const { data: seatMaps } = useGetSeatMapsQuery();
    const [selectedUsers, setSelectedUsers] = selectedUsersState;
    const defaultSelectedPlace: [string, string] | undefined = defaultPlace
        ? [defaultPlace.id, defaultPlace.name]
        : undefined;

    const defaultSelectedSeatMap: [string, string] | undefined = defaultSeatMap
        ? [defaultSeatMap.id, defaultSeatMap.name]
        : undefined;

    const errors = form.formState.errors;
    const control = form.control;

    return (
        <Form {...form}>
            <form
                id="create-event-details-form"
                className="flex flex-col gap-4 max-w-5xl w-full bg-background rounded-lg p-4 m-4 border"
            >
                <FormInput
                    control={control}
                    id="title"
                    label="Title"
                    error={errors.title?.type === 'required' ? 'Title is required' : undefined}
                />
                <FormTextArea
                    control={control}
                    id="description"
                    label="Description"
                    error={errors.description?.type === 'required' ? 'Description is required' : undefined}
                />
                <div className="flex w-full gap-2">
                    <FormDatePicker
                        control={control}
                        id="startDate"
                        label="Start Time"
                        placeholder="Start Time"
                        error={errors.startDate?.type === 'required' ? 'Start Date is required' : undefined}
                    />
                    <FormDatePicker
                        control={control}
                        id="endDate"
                        label="End Time"
                        placeholder="End Time"
                        error={errors.endDate?.type === 'required' ? 'End Date is required' : undefined}
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <FormLabel>Responsible Users</FormLabel>
                    <UserMultiSelect selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
                </div>
                {!isEdit && (
                    <FormSelect
                        control={control}
                        id="placeId"
                        label="Location"
                        error={errors.placeId?.type === 'required' ? 'Location is required' : undefined}
                        valuesAndNames={places.map((place) => [place.id, place.name])}
                        defaultValueAndName={defaultSelectedPlace}
                    />
                )}
                <div className="flex space-x-6 w-full">
                    <div className="min-w-fit text-lg font-semibold flex flex-col items-left">
                        Set to draft
                        <Switch className="py-3" checked={isDraft} onClick={() => setIsDraft(!isDraft)} />
                    </div>
                    <div
                        className={cn(
                            'min-w-fit text-lg font-semibold flex flex-col items-left transition-opacity duration-500',
                            isDraft && cn('opacity-0', 'pointer-events-none'),
                        )}
                    >
                        Publish event directly
                        <Switch
                            className="py-3"
                            checked={!hasPublishDate}
                            onClick={() => setHasPublishDate(!hasPublishDate)}
                        />
                    </div>
                    <FormDatePicker
                        control={control}
                        id="publishDate"
                        label="Publish Date"
                        placeholder="Publish Date"
                        error={errors.publishDate?.type === 'required' ? 'Publish Date is required' : undefined}
                        hide={!hasPublishDate || isDraft}
                        notRequired={!hasPublishDate || isDraft}
                    />
                </div>

                {!isEdit && (
                    <>
                        <div
                            className={cn(
                                'min-w-fit text-lg font-semibold flex flex-col items-left transition-opacity duration-500',
                            )}
                        >
                            {setHasSeatMap && (
                                <>
                                    Does your event have a seat map
                                    <Switch
                                        className="py-3"
                                        checked={hasSeatMap}
                                        onClick={() => setHasSeatMap(!hasSeatMap)}
                                    />
                                </>
                            )}
                        </div>
                        {hasSeatMap && seatMaps ? (
                            <FormSelect
                                control={control}
                                id="seatMapId"
                                label="Seat Map"
                                error={errors.seatMapId?.type === 'required' ? 'Seat map is required' : undefined}
                                valuesAndNames={seatMaps.map((seatMap) => [seatMap.id, seatMap.name])}
                                defaultValueAndName={defaultSelectedSeatMap}
                            />
                        ) : (
                            <FormInput
                                control={control}
                                id="seatCount"
                                label="Total amount of tickets"
                                type="number"
                                error={errors.seatCount?.type === 'required' ? 'Ticket amount is required' : undefined}
                                minValue={1}
                            />
                        )}
                    </>
                )}
            </form>
        </Form>
    );
};
