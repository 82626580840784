import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import type { UserResponse } from '@/types/User';
import { Input } from '../ui/input';
import { useEditUserMutation } from '@/services/userApi';
import FoodPreferences from '../ui/food-preferences';

interface EditUserFormProps {
    onConfirm: () => void;
    onCancel: () => void;
    user: UserResponse;
}

export function EditUserForm({ onConfirm, onCancel, user }: EditUserFormProps): JSX.Element {
    const [editUser, { isLoading }] = useEditUserMutation();
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [foodPreferences, setFoodPreferences] = useState<string[]>(user.foodPreferences || []);

    const onSubmit = () => {
        editUser({
            id: user.id,
            firstName,
            lastName,
            foodPreferences,
        })
            .unwrap()
            .then(() => {
                successToast('User updated');
                onConfirm();
            })
            .catch(errorToastFromCatch);
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-background/80 backdrop-blur-sm bg-opacity-40 flex justify-center items-center z-50">
            <div
                className="w-[460px] p-4 rounded-lg space-y-4 bg-primary-foreground shadow-lg border"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex flex-col space-y-2">
                    <span className="text-lg font-bold">First Name</span>
                    <Input placeholder="First name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <span className="text-lg font-bold">Last Name</span>
                    <Input placeholder="Last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <div className="w-full max-w-md mx-auto">
                        <div className="space-y-2">
                            <FoodPreferences
                                foodPreferences={foodPreferences}
                                setFoodPreferences={setFoodPreferences}
                            />
                            <div className="flex justify-end space-x-2">
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onCancel();
                                    }}
                                    variant="secondary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="default"
                                    isLoading={isLoading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onSubmit();
                                    }}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditUserForm;
