import type { ColumnFiltersState, PaginationState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { columns } from '@/components/dataTable/columns/UsersColumns';
import { DataTable } from '@/components/dataTable/DataTable';
import { CenteredSpinner } from '@/components/ui/spinner';
import { pageSizes } from '@/types/Pagination';
import UserActions from '@/components/dataTable/columns/UserActions';
import { useGetUsersQuery } from '@/services/userApi';
import { CreateUser } from '@/components/Users/CreateUser';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { PageHeader } from '@/components/PageHeader';

export default function Users(): JSX.Element {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: pageSizes.small,
    });
    const [pageCount, setPageCount] = useState(1);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const { data, isLoading } = useGetUsersQuery({
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageIndex,
    });

    useEffect(() => {
        if (data) {
            setPageCount(Math.ceil(data.totalElements / pagination.pageSize));
        }
    }, [data, pagination]);

    if (isLoading || !data) {
        return <CenteredSpinner />;
    }

    return (
        <>
            <PageHeader>
                <CreateUser>
                    <Button>
                        <Plus className="h-5 w-5 mr-2" />
                        <p>Create New User</p>
                    </Button>
                </CreateUser>
            </PageHeader>
            <DataTable
                columns={columns}
                data={data}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                pageCount={pageCount}
                rowActions={(user) => <UserActions user={user} />}
            />
        </>
    );
}
